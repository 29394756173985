import {NgModule} from '@angular/core';
import {LmsCatalogComponent} from './catalog/component';
import {LmsPathService} from './paths/service';
import {LmsPathComponent} from './paths/component';
import {CourseComponent} from './courses/item/course.component';
import {CoursesComponent} from './courses/list/component';
import {ExternalCoursesComponent} from './external/courses/external-courses.component';
import {LayoutModule} from '../layout/module';
import {AppCommonModule} from '../common/module';
import {CommonModule} from '@angular/common';
import {PathCoursesComponent} from './paths/list/path-courses.component';
import {PathCourseComponent} from './paths/item/path-course.component';
import {CourseResourcesComponent} from './resources/list/course-resources.component';
import {FlowChartModule} from 'ngx-sep-graph-builder';
import {ScormModule} from './scorm/module';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {XApiModule} from '../xapi/module';
import {CatalogDefaultComponent} from './catalog/catalog-default/catalog-default.component';
import {CatalogByThemeAreaComponent} from './catalog/by-theme-area/catalog-by-theme-area.component';
import {FlatCoursesComponent} from './courses/list/flat/flat-courses.component';
import {CoursesByThemeAreaComponent} from './courses/list/by-them-area/component';
import {CourseService} from './courses/course.service';
import {ResourceService} from './resources/resource.service';
import {PathService} from './paths/path.service';
import {I18NModule} from '../i18n/module';
import {ExternalCourseService} from './external/courses/external-course.service';
import {ExternalCourseComponent} from './external/course/external-course.component';
import {CatalogCourseComponent} from './paths/details/catalog-course.component';
import {DynamicPathModalComponent} from './dynamic-path-modal/dynamic-path-modal.component';
import {LmsEpics} from './store/epics';
import {LmsPathsEpics} from './paths/store/epics';
import {LmsPathsActions} from './paths/store/actions';
import {LmsPathsService} from './paths/store/service';
import {LmsCoursesService} from './courses/store/service';
import {LmsCoursesActions} from './courses/store/actions';
import {LmsCoursesEpics} from './courses/store/epics';
import {LmsResourcesActions} from '@shared/app/lms/resources/store/actions';
import {LmsResourcesService} from '@shared/app/lms/resources/store/service';
import {LmsResourcesEpics} from '@shared/app/lms/resources/store/epics';
import {CourseSynchronousComponent, FilterLessonPipe, FilterSessionPipe} from './synchronous/course/course-synchronous.component';
import {JwBootstrapSwitchNg2Module} from 'jw-bootstrap-switch-ng2';
import {SynchronousService} from './synchronous/synchronous.service';
import {LessonsEpics} from '@shared/app/lms/synchronous/store/epics';
import {LmsLessonsActions} from '@shared/app/lms/synchronous/store/actions';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {PopoverWrapperComponent} from '@shared/app/layout/calendar/calendar/popover-calendar';
import {SessionsCalendarComponent} from '@shared/app/lms/synchronous/sessions-calendar/sessions-calendar.component';
import { RouterModule } from '@angular/router';
import {ModalVdcUserKeyComponent} from '@shared/app/lms/synchronous/modal-vdc-user-key/modal-vdc-user-key.component';
import {BrowserModule} from '@angular/platform-browser';
import {ClipboardModule} from 'ngx-clipboard';

@NgModule({
  declarations: [
    LmsCatalogComponent,
    LmsPathComponent,
    CourseComponent,
    CoursesComponent,
    PathCoursesComponent,
    PathCourseComponent,
    CatalogCourseComponent,
    ExternalCoursesComponent,
    CourseResourcesComponent,
    CatalogDefaultComponent,
    CatalogByThemeAreaComponent,
    FlatCoursesComponent,
    CoursesByThemeAreaComponent,
    ExternalCourseComponent,
    DynamicPathModalComponent,
    CourseSynchronousComponent,
    FilterLessonPipe,
    FilterSessionPipe,
    PopoverWrapperComponent,
    SessionsCalendarComponent,
    ModalVdcUserKeyComponent
  ],
  imports: [
    RouterModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    I18NModule,
    LayoutModule,
    CommonModule,
    BrowserModule,
    AppCommonModule,
    FlowChartModule,
    ScormModule,
    XApiModule,
    TabsModule,
    TooltipModule.forRoot(),
    TimepickerModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    PopoverModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonsModule,
    ClipboardModule
  ],
  providers: [
    LmsPathService,
    PathService,
    CourseService,
    ResourceService,
    ExternalCourseService,
    LmsPathsEpics,
    LmsEpics, LmsPathsActions,
    LmsPathsService,
    LmsCoursesService,
    LmsCoursesActions,
    LmsCoursesEpics,
    LmsResourcesActions,
    LmsResourcesService,
    LmsResourcesEpics,
    SynchronousService,
    LessonsEpics,
    LmsLessonsActions
  ],
  exports: [
    FilterLessonPipe,
    FilterSessionPipe
  ],
  entryComponents: [
    CatalogDefaultComponent,
    CatalogByThemeAreaComponent,
    FlatCoursesComponent,
    CoursesByThemeAreaComponent,
    PopoverWrapperComponent
  ]

})
export class LmsModule {
  id: number;
}
