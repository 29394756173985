<div *appUserExcludeAuths="['SEP_USER_COURSES_READ', 'SEP_USER_PATHS_READ']">
	<div *ngIf="nobis == true">
		<div class="page-err page-template-thin">
			<div class="text-center">
				<div class="err-status">
				<h1>
					<span class="err-status-parenthesis">&#123;</span>
					<span class="err-status-text"><i class="err-status-icon fa fa-frown-o text-danger"></i></span>
					<span class="err-status-parenthesis">&#125;</span>
				</h1>
				</div>
				<div class="err-message">
				<span>Gentile Utente, probabilmente stai utilizzando un profilo che non è abilitato alla formazione online.<br/>
					Fai clic sull'immagine del profilo in alto a destra, si aprirà un menu a tendina dove troverai il link "amministrazione" per accedere alla Gestione utenti.
				</span>
				</div>
			</div>
		</div>
	</div>
</div>
