import {Injectable} from "@angular/core";
import {AppEnvService} from "../../../config/env/service";
import {HttpClient} from "@angular/common/http";
import {Hierarchy} from "../model";

@Injectable()
export class HierarchyService {

  private readonly loadAllUrl: string;

  constructor(
    private appEnvService: AppEnvService,
    private httpClient: HttpClient
  ) {

    this.loadAllUrl = this.appEnvService.config().api.hierarchies.list

  }

  loadAll = () => this.httpClient.get<Hierarchy>(this.loadAllUrl)

}