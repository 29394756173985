import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import {Path} from '../../../paths/model';
import {PathService} from '../../../paths/path.service';
import {CourseService} from '../../course.service';
import {BreadCrumbFactory, BreadcrumbLevel, BreadcrumbService, SepBreadcrumb} from '../../../../common/_services/breadcrumb.service';
import {animate, query, stagger, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-flat-courses',
  templateUrl: './flat-courses.component.html',
  styleUrls: ['./flat-courses.component.scss'],
  animations: [
    trigger('pageAnimations', [
      transition(':enter', [
        query('.path-item', [
          style({opacity: 0, transform: 'translateY(-25px)'}),
          stagger(35, [
            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)',
              style({opacity: 1, transform: 'none'}))
          ])
        ])
      ])
    ]),
    trigger('mouseOver', [
      state('over', style({
        transform: 'scale(109%)',
        'z-index': 99999,
        position: 'relative'
      })),
      state('out', style({
        transform: 'scale(100%)',
        'z-index': 0,
      })),
      transition('* => over', [
        animate('120ms ease')
      ]),
      transition('* => out', [
        animate('80ms ease')
      ]),
    ]),
  ]
})
export class FlatCoursesComponent implements OnInit {

  paths: Path[];
  loadingError = false;
  loading = true;
  bannerTitle: string;
  bannerDescription: string;

  constructor(
    private pathService: PathService,
    private courseService: CourseService,
    private router: Router,
    private bcService: BreadcrumbService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    forkJoin(
      this.translateService.get('My courses'),
      // tslint:disable-next-line:max-line-length
      this.translateService.get('Browse the course you\'re enrolled in, and access both mandatory and elective learning materials.')
    ).subscribe((t: any[]) => {
      this.bannerTitle = t[0];
      this.bannerDescription = t[1];
    });

    this.courseService.getMyPaths().subscribe(
      p => {
        this.paths = p;
        this.loading = false;
      },
      e => {
        this.paths = [];
        this.loading = false;
        this.loadingError = true;
      }
    );
    this.translateService.get('My courses').subscribe(t => {
      const b: SepBreadcrumb = (new BreadCrumbFactory).createBreadCrumb(t, null, '/courses', BreadcrumbLevel.first);
      this.bcService.setBreadcrumb(b);
    });
  }

  openPath(p: Path) {
    this.router.navigate(['/path', p.id]);
  }

  mouseover(item) {
    item['mouse'] = 'over';
  }

  mouseout(item) {
    item['mouse'] = 'out';
  }
}
