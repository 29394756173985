import {Injectable} from "@angular/core";
import {AppEnvService} from "../env/service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class CustomFieldsService {

  url: string;

  constructor(
    private httpClient: HttpClient,
    private envService: AppEnvService
  ) {
    this.url = envService.config().api.fields.me;
  }

  fields = () => this.httpClient.get(this.url)

}