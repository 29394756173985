import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from "../authentication/service";
import {AppEnvService} from "../config/env/service";

@Directive({
  selector: '[appUserExcludeAuths]'
})
export class UserAuthsExcludeDirective {

    private hasView = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthenticationService,
        private envService: AppEnvService) { }

    @Input() set appUserExcludeAuths(requiredAuths: string[]) {
//TODO Fix this !!!

        // this.authService.authorities$.subscribe(userAuths => {
        //     this.control(requiredAuths, userAuths);
        // });
        //
        // this.envService.theme.subscribe(th => {
        //     const userAuths = this.authService.getAuthority();
        //     this.control(requiredAuths, userAuths);
        // });

    }

    control(requiredAuths, userAuths){
        let atLeastOneAuthMatching = false;
        if (requiredAuths) {
            for (const a of requiredAuths) {
                if (userAuths && userAuths.indexOf(a) !== -1) {
                    atLeastOneAuthMatching = true;
                }
            }
        }
        if (!atLeastOneAuthMatching) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }

}
