import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {PASSWORD_CHANGE_STATE, PasswordChangeState, UserDetails, UserPreferences} from '../model';
import {UserService} from '../service';
import {LocalStorageService} from '../../storage/local/service';
import {AppEnvService} from '../../config/env/service';
import {BreadCrumbFactory, BreadcrumbLevel, BreadcrumbService} from '../../common/_services/breadcrumb.service';
import {select} from '@angular-redux/store';
import {Observable, Subscription} from 'rxjs';
import {AuthenticationService} from '../../authentication/service';
import {UserActions} from '../store/actions';
import {AuthState} from '../../authentication/store/reducers';
import {flatMap, map} from 'rxjs/operators';
import {LanguageCode} from '../../i18n/model';
import {SafeUrl} from '@angular/platform-browser';
import * as mtz from 'moment-timezone';

@Component({
  selector: 'user-profile',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {

  profileImgUploadUrl: string;
  selectLang = false;
  selectTZ = false;
  form: FormGroup;
  subscriptions: Subscription[] = [];
  serverErrorMessage: string;
  infoMessage: string;
  changePasswordVisible = false;
  userPreferences: UserPreferences = {};

  languages: LanguageCode[];
  translations: any;

  @select(['user', 'passwordChangeState'])
  readonly passwordChangeState: Observable<PasswordChangeState>;

  @select(['user', 'details'])
  readonly user: Observable<UserDetails>;

  @select(['auth'])
  readonly authState: Observable<AuthState>;

  customStyle = {
    selectButton: {
      "background-color": "#17a2b8",
      "color": "#000"
    }
  }
  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private bcService: BreadcrumbService,
    private translateService: TranslateService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userActions: UserActions,
    private lsService: LocalStorageService,
    private envService: AppEnvService
  ) {
    this.languages = envService.languages();
    this.profileImgUploadUrl = envService.config().api.user.profileImage;
    this.form = new FormGroup(
      {
        currentPassword: new FormControl('', Validators.required),
        newPassword: new FormControl('', Validators.required),
        confirmPassword: new FormControl('', Validators.required)
      },
      this.matchPassword
    );
  }

  getLanguageName(langCode: string) {
    for (const l of this.languages) {
      if (l.code === langCode) {
        return l.name;
      }
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.translate().subscribe(),
      this.translateService.onLangChange.pipe(flatMap(this.translate)).subscribe(),
      this.passwordChangeState.subscribe(state => {
        switch (state.status) {
          case PASSWORD_CHANGE_STATE.changed:
            this.changePasswordCancel();
            break;
        }
      })
    );
  }

  ngOnDestroy = (): void => this.subscriptions.forEach(s => s.unsubscribe());

  onUploadFinished = (sender) => this.userActions.loadUserProfileImage();

  deletePicture = (sender) => this.userActions.deleteUserProfileImage();

  onLanguageChange = (sender) => this.userActions.updateUserPreferences({cultureCode: sender.target.value});

  onTimezoneChange = (timezone: string) => {
    const isoCode = mtz.tz.zone(timezone).name;
    const offsetMinutes = mtz(timezone).utcOffset();
    this.userActions.updateUserPreferences({
      countryCode: {
        iso: isoCode,
        zone: timezone,
        offsetMinutes: offsetMinutes,
        offsetHours: offsetMinutes / 60
      }
    });
  };

  matchPassword(control: AbstractControl) {
    const password = control.get('newPassword').value;
    const confirmPassword = control.get('confirmPassword').value;
    if (confirmPassword !== '' && password !== confirmPassword) {
      control.get('confirmPassword').setErrors({matchPassword: true});
    } else {
      return null;
    }
  }

  onChangePassword = (sender) => {
    this.userActions.changeUserPassword({
      oldPassword: this.form.get('currentPassword').value,
      newPassword: this.form.get('newPassword').value
    });
  };

  changePasswordCancel() {
    this.form.controls['currentPassword'].setValue('');
    this.form.controls['newPassword'].setValue('');
    this.form.controls['confirmPassword'].setValue('');
    this.changePasswordVisible = false;
    this.serverErrorMessage = null;
    this.infoMessage = null;
    this.userActions.changeUserPasswordReset();
  }

  closeChangePassword() {
    this.changePasswordCancel();
    this.changePasswordVisible = false;
  }

  onExternalCoursesButtonClick = sender => this.router.navigate(['/externalcourses']);

  onLangEditClick($event: MouseEvent, currentUserCultureCode) {
    this.selectLang = true;
    this.userPreferences.cultureCode = currentUserCultureCode;

  }

  isDefaultImageUrl = (imageUrl: SafeUrl) => {
    return UserService.defaultProfileImageUrl === imageUrl.toString();
  };

  private translate = () =>
    this.translateService.get([
      'User profile',
      'Select image',
      'or drop it here'
    ]).pipe(map(translations => {
      this.translations = {...translations};
      this.bcService.setBreadcrumb(
        new BreadCrumbFactory().createBreadCrumb(
          this.translations['User profile'],
          null,
          'profile',
          BreadcrumbLevel.first
        ));
    }));
}
