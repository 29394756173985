import { SafeUrl, SafeHtml } from '@angular/platform-browser';


export class ModalContent {
    title: string;
    type: ModalContentType;
    url: SafeUrl;
    body: SafeHtml;

    constructor(title: string, type: ModalContentType, html?: string, url?: SafeUrl) {
        this.title = title;
        this.type = type;
        this.url = url;
        this.body = html;
    }
}

export enum ModalContentType {
    HTML = 'html',
    PDF = 'pdf',
    VIDEO = 'video',
    CLOSE_WARNING = 'closeWarning',
    ALERT = 'alert'
}
