import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import {LmsPathService} from '../../paths/service';
import {UIMessage, UIMessageType} from '../../../model';
import {Path} from '../../paths/model';
import {BreadCrumbFactory, BreadcrumbLevel, BreadcrumbService, SepBreadcrumb} from '../../../common/_services/breadcrumb.service';
import {CourseService} from '../../courses/course.service';
import {NotificationsService} from 'angular2-notifications';
import {animate, query, stagger, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-catalog-default',
  templateUrl: './catalog-deafult.component.html',
  styleUrls: ['./catalog-default.component.scss'],
  animations: [
    trigger('pageAnimations', [
      transition(':enter', [
        query('.path-item', [
          style({opacity: 0, transform: 'translateX(-25px)'}),
          stagger(35, [
            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)',
              style({opacity: 1, transform: 'none'}))
          ])
        ])
      ])
    ]),
    trigger('mouseOver', [
      state('over', style({
        transform: 'scale(105%)',
        'z-index': 99999,
        position: 'relative'
      })),
      state('out', style({
        transform: 'scale(100%)',
        'z-index': 0,
      })),
      transition('* => over', [
        animate('120ms ease')
      ]),
      transition('* => out', [
        animate('80ms ease')
      ]),
    ]),
  ]
})
export class CatalogDefaultComponent implements OnInit {

  paths: Path[];
  bannerTitle: string;
  bannerDescription: string;
  loading: boolean;
  message: UIMessage;
  loadingEnroll: boolean;


  constructor(
    private lmsPathService: LmsPathService,
    private bcService: BreadcrumbService,
    private router: Router,
    private courseService: CourseService,
    private translateService: TranslateService,
    private notificationService: NotificationsService) {
    this.loadingEnroll = false;
  }

  ngOnInit() {
    this.loading = true;
    forkJoin(
      this.translateService.get('Catalog'),
      // tslint:disable-next-line:max-line-length
      this.translateService.get('Browse and choose additional courses to enroll in.')
    ).subscribe((t: any[]) => {
      this.bannerTitle = t[0];
      this.bannerDescription = t[1];
    });

    this.lmsPathService.paths().subscribe(
      data => {
        this.paths = data;
        this.loading = false;
        // tslint:disable-next-line:max-line-length
        const bP: SepBreadcrumb = (new BreadCrumbFactory).createBreadCrumb('Catalog', null, '/catalog', BreadcrumbLevel.first, true);
        this.bcService.setBreadcrumb(bP);

        if (this.paths && this.paths.length === 0) {
          this.translateService.get('No catalogs available').subscribe(
            t => this.message = new UIMessage(UIMessageType.INFO, t)
          );
        }
      },
      err => {
        this.loading = false;
        forkJoin(
          this.translateService.get('Warning'),
          this.translateService.get('An error occurred')
        ).subscribe(t => {
          this.notificationService.error(t[0], t[1], {timeOut: 5000});
        });
      }
    );
  }

  openPath(path) {
    this.router.navigate(['catalogcourse', path.id]);
  }

  mouseover(item) {
    item['mouse'] = 'over';
  }

  mouseout(item) {
    item['mouse'] = 'out';
  }

  enrollToPath(path: Path) {
    this.loadingEnroll = true;
    this.courseService.enrollPath(path).subscribe(
      data => {
        this.loadingEnroll = false;
        if (data === true) {
          path.enrolled = true;
        } else {
          forkJoin(
            this.translateService.get('Warning'),
            this.translateService.get('An error occurred')
          ).subscribe(t => {
            this.notificationService.error(t[0], t[1], {timeOut: 5000});
          });
        }
      },
      err => {
        this.loadingEnroll = false;
        forkJoin(
          this.translateService.get('Warning'),
          this.translateService.get('An error occurred')
        ).subscribe(t => {
          this.notificationService.error(t[0], t[1], {timeOut: 5000});
        });
      }
    );
  }
}
