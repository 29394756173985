import {Component, TemplateRef, ViewChild} from '@angular/core';
import {PopoverDirective} from 'ngx-bootstrap/popover';

@Component({
  template: `
    <div *ngIf="view !== 'listYear'" [popover]="templatePass" [popoverContext]="{data : values}" [popoverTitle]="title" placement="auto"
         outsideClick="true" container="body" placement="auto" containerClass="event-popover">
      <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
    </div>

    <td class="border-0 p-0 m-0" width="100" *ngIf="view === 'listYear'" [popover]="templatePass" [popoverContext]="{data : values}"
        [popoverTitle]="title" outsideClick="true" container="body" placement="auto" containerClass="event-popover">
      <table style="width: 100%">
        <tr>
          <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
        </tr>
      </table>
    </td>

    <ng-template #tempOutlet>
      <ng-content></ng-content>
    </ng-template>
  `
})
export class PopoverWrapperComponent {
  templatePass: TemplateRef<any>;
  values: any;
  title: string;
  view: string;
  @ViewChild(PopoverDirective, {static: false}) popover: PopoverDirective;
}
