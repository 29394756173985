import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
// import {CustomFieldService} from "./custom-field/custom-field.service";
// import {CustomField} from "./custom-field/custom-field";

@Injectable()
export class TranslationsService {

    readonly dummyDataUrl = '/assets/dummy/custom-labels-it.json';

    constructor(
        private translateService: TranslateService/*,
        private customFieldService: CustomFieldService*/) {
    }

    loadCustomLabels(lang: string) {
        // this.customFieldService.getAll().subscribe(fields => {
        //     /*
        //     for (const labelKey in fields) {
        //         const _customField = fields[labelKey] as CustomField;
        //         if (_customField.label && _customField.label[lang]) {
        //             console.info('adding custom translation for', labelKey, _customField.label[lang], lang);
        //             this.translateService.set(labelKey, _customField.label[lang], lang);
        //         }
        //     }
        //     */
        // });
    }
}
