import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppEnvService} from '../config/env/service';
import {LocalStorageService} from '../storage/local/service';
import {of, throwError} from 'rxjs';

@Injectable()
export class AuthenticationService {

  loginUrl: string;
  checkUrl: string;
  oAuthCfg: any;

  constructor(
    private appEnv: AppEnvService,
    private httpClient: HttpClient,
    private localStorage: LocalStorageService
  ) {

    this.loginUrl = this.appEnv.config().api.login;
    this.checkUrl = this.appEnv.config().api.checkToken;
    this.oAuthCfg = ({
      clientId: this.appEnv.config().authentication.oauth.clientId,
      scope: this.appEnv.config().authentication.oauth.scope
    });

  }

  checkToken = () => this.localStorage.get(LocalStorageService.OAuthTokenKeyName);

  authenticate = (username: string, password: string, hostname: string) => this.httpClient.post<any>(
    this.loginUrl, {
      username: username,
      password: password,
      clientId: this.oAuthCfg.clientId,
      scope: this.oAuthCfg.scope,
      domain: hostname
    })

  impersonate = (userId: number, source: string) => this.httpClient.post<any>(this.appEnv.config().api.impersonate.login, {
    userId: userId,
    domain: window.location.hostname,
    origin: source === 'admin' ? source : 'ui'
  })

  impersonateLogout = () => this.httpClient.post<any>(this.appEnv.config().api.impersonate.logout, {});

  getToken = (refreshToken: string) => {
    return this.httpClient.post<any>(this.appEnv.config().api.refreshTokenUrl, {
      clientId: 'sep-lite',
      refreshToken: refreshToken
    });
  }
}
