import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CasProvider} from "./model";
import {AppEnvService} from "../../config/env/service";

@Injectable()
export class CasService {

  private readonly providersUrl: string;

  constructor(private http: HttpClient, private envService: AppEnvService) {
    this.providersUrl = this.envService.config().api.cas.providers;
  }

  providers = () => this.http.get<CasProvider[]>(this.providersUrl);

  login(provider: CasProvider) {
    window.location.href = provider.url;
  }

}
