import {AppEnvConfig} from "../app/config/env/service";


export const defaultEnvironment: AppEnvConfig = {
  production: false,
  authentication: {
    oauth: {
      clientId: 'sep-lite',
      scope: 'simulware/sep/me.readonly simulware/sep/learning simulware/sep/resource simulware/sep/hierarchy',
    }
  },
  env: {
    showLanguageSelection: false,
    ivassEnable: false,
    resourcesPath: '/data/sep-lite-resources',
    adminImpersonateExternalUrl: '/admin/impersonate',
    dignostic: '/iam/api/v1/support/diagnostic',

},
  i18n: {
    defaultLanguageCode: 'it-IT',
    langCodeSelection: ['athena']
  },
  theme: {
    defaultTheme: 'dev-cf-lite',
    mappedThemes: {},
    views: {
      catalog: {
        byThemeArea: ['nobis', 'athena']
      },
      courses: {
        byThemeArea: ['nobis', 'athena']
      }
    }
  },
  api: {
    checkToken: 'iam/oauth/check_token',
    login: '/iam/api/v1/login',
    impersonate: {
      login: '/iam/api/v1/impersonate/login',
      logout: '/iam/api/v1/impersonate/logout',
    },
    refreshTokenUrl: '/iam/api/v1/login/refresh',
    hierarchies: {
      list: '/hierarchy/gerarchie/list',
    },
    themeAreas: '/sep/learning/v1/areas',
    lms: {
      admin: '/sep/lms/admin/hierarchies',
      paths: '/sep/learning/v1/paths'
    },
    user: {
      recoverPassword: '/iam/api/v1/login/recover',
      acceptPrivacy: '/api/v3/me/privacy/accept',
      search: '/hierarchy/users',
      details: '/api/v3/me/details',
      stats: '/sep/learning/v1/stats/course',
      profileImage: '/api/v3/me/picture',
      preferences: '/api/v3/me/preferences',
      changePassword: '/iam/api/v2/password',
      report: '/sep/learning/v1/report/course'
    },
    fields: {
      me: '/sep/learning/services/customFields/me'
    },
    tracking: '/sep/lms/tracking/unit',
    cas: {
      providers: '/iam/login/cas/providers'
    },
    theme: {
      custom: '/sep/resources/customTheme'
    },
    paths: '/sep/learning/v1/paths',
    courses: '/sep/learning/v1/courses',
    resources: '/sep/learning/v1/resources',
    certificates: '/sep/learning/v1/certificates',
    used: '/api/v3/me/resources/used',
    courseCover: '/api/v3/me/courses/{id}/picture',
    assets: '/sep/resources/v1/resources/',
    external: {
      courses: '/sep/learning/services/trainings/external'
    },
    vdcLink: '/sep/resources/services/vdc/getVdcLink',
    activeSessionsLessonsUser: '/sep/learning/v1/sessionsLessons/me',
    allSessionsSessionsLessonsUser: '/sep/learning/v1/sessionsLessons/all',
    enrollSession: '/sep/resources/services/sessionsUsers/me'
  },
  storage: {
    local: {
      namespace: 'sep.ui',
    }
  },
  scorm: {
    viewerUrl: '/cfc/lms/viewer/viewerWs.cfm',
    quizViewerUrl: '/sep4/assets/quiz-viewer/',
  },
  lrs: {
    url: 'https://lrs-https.ad.simulware.net/data/xAPI/',
    auth: 'Basic NGVhMWY1ZmY4NjUwM2M4ZGQzNDJjZGVhNjliOWM1OTQ4ZmRjYjg3ZDo1ZDZlMzU4MDQ4ZjhhNzRlNDA0MzI3MDc2YzZjYTFmZjJmZmRjODg1'
  }
};
