import {Injectable} from '@angular/core';
import {dispatch} from '@angular-redux/store';
import {FluxStandardAction} from 'flux-standard-action';
import {LmsResource} from './model';
import {CoursesAction} from "@shared/app/lms/courses/store/actions";

export type ResourcesPayload = Array<LmsResource> | LmsResource | string | number | any;
export type ResourcesAction<T extends ResourcesPayload = undefined> = FluxStandardAction<string, T, any>;


@Injectable()
export class LmsResourcesActions {

  static readonly RESOURCE_OPEN = 'RESOURCE_OPEN';
  static readonly RESOURCE_OPENING = 'RESOURCE_OPENING';
  static readonly RESOURCE_OPEN_FAILED = 'RESOURCE_OPEN_FAILED';
  static readonly RESOURCE_OPENED = 'RESOURCE_OPENED';

  @dispatch()
  openResource = (id: number): ResourcesAction<number> => ({
    type: LmsResourcesActions.RESOURCE_OPEN,
    payload: id
  })

  resourceOpening = (): CoursesAction => ({
    type: LmsResourcesActions.RESOURCE_OPENING,
  })


  resourceOpenError = (error: any): ResourcesAction<any> => ({
    type: LmsResourcesActions.RESOURCE_OPEN_FAILED,
    payload: error
  })

  resourceOpened = (resource: LmsResource): ResourcesAction<LmsResource> => ({
    type: LmsResourcesActions.RESOURCE_OPENED,
    meta: {},
    payload: resource
  })

}
