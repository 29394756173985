<section id="header" class="top-header border-main">
  <header class="clearfix">

    <a [routerLink]="['/home']">
      <div class="logo"
            [style.background-image]="sanitize((themeConfig | async).logoImageUrl)" >
      </div>
    </a>

    <div *ngIf="user | async" id="nav-container" class="nav-container">
      <ul id="nav">
        <li class="">
          <a [routerLink]="['/home']" [routerLinkActive]="'is-active'"> <i class="fa fa-home button-nav"><span
            class="icon-bg bg-main"></span></i><span
            translate>Home</span> </a>
        </li>
        <li class="" *appUserAuths="navMenuRequiredAuthorizations">
          <a [routerLink]="['/courses']" [routerLinkActive]="'is-active'"> <i class="fa fa-user button-nav"></i><span
            translate>My courses</span></a>
        </li>
        <li class="" *appUserAuths="navMenuRequiredAuthorizations">
          <a [routerLink]="['/catalog']" [routerLinkActive]="'is-active'"> <i class="fa fa-book button-nav"></i><span
            translate>Catalog</span></a>
        </li>
        <li class="" *appUserAuths="navMenuRequiredAuthorizations">
          <a [routerLink]="['/calendar']" [routerLinkActive]="'is-active'"> <i class="fa fa-calendar button-nav"></i><span
            translate>Calendar</span></a>
        </li>
        <li class="" *appUserAuths="navMenuRequiredAuthorizations">
          <a [routerLink]="['/report/user']" [routerLinkActive]="'is-active'"> <i
            class="fas fa-chart-area button-nav"></i><span
            translate>My dashboard</span></a>
        </li>
        <li *ngIf="helpOnLine === true" class="">
          <i class="fa fa-question-circle fa-lg clickable" (click)="openHelpOnLine()" tooltip="{{'User manual' | translate}}" placement="top" containerClass="headerTooltip"></i>
        </li>
      </ul>
    </div>

    <div dropdown class="top-nav pull-right user-info" *ngIf="(user | async) as userDetails">
      <a href dropdownToggle (click)="false">
        <span class="user-info-avatar" [style.backgroundImage]="sanitize(userDetails.imageUrl)"></span>
        <span>{{userDetails.firstName}}</span>
      </a>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
        <li [routerLink]="['profile']">
          <i class="fas fa-user"></i>&nbsp;<span translate class="text-capitalize">user profile</span>
        </li>
        <li [routerLink]="['profile']">
        <li *appUserAuths="['SEP_LOGIN_USER_IMPERSONATION']" (click)="onLoginAsClick($event)"
            [hidden]="userIsImpersonating">
          <i class="fas fa-user-circle"></i>&nbsp;<span translate class="text-capitalize">login as</span>
        </li>
        <li *appUserAuths="['SEP_ADMIN_ACCESS']" [hidden]="userIsImpersonating">
          <a (click)="impersonate()" target="_blank">
            <i class="fas fa-external-link-alt"></i>&nbsp;<span translate class="text-capitalize">administration</span>
          </a>
        </li>
        <li class="">
          <a (click)="detectInfo()">
            <i class="fas fa-life-ring clickable"></i>&nbsp;<span translate class="text-capitalize">help</span>
          </a>
        </li>
        <li *ngIf="showLogoutMenuItem()" (click)="onLogoutAsClick($event)">
          <i class="fas fa-sign-out-alt"></i>&nbsp;<span translate class="text-capitalize">logout</span>
        </li>
        <li>
          <app-version-info [showLabel]="true"></app-version-info>
        </li>
      </ul>
    </div>

  </header>
</section>

<ng-template #sendDiagnosticWarningTemplate>
  <div class="modal-header">
    <h5 class="modal-title pull-left" translate>Confirm</h5>
    <button type="button" class="close pull-right" (click)="warningModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="padding: 15px">
      <p translate>Continuing will you send diagnostic information to the support</p>
      <p translate>What would you like to do?</p>
    </div>
  </div>
  <div class="modal-footer">
    <span class="btn btn-primary clickable" (click)="confirmWarningOpenedResource()" translate>Continue</span>
    <span class="btn btn-default clickable" (click)="cancelWarningOpenedResource()" translate>Cancel</span>
  </div>
</ng-template>
