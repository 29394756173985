import {environment} from '../../../src/environments/environment';
import {enableProdMode} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {SharedAppModule} from "./app/module";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(SharedAppModule, {preserveWhitespaces: true})
  .catch(err => {
      console.log(err)
    }
  );
