import {Pipe, PipeTransform} from '@angular/core';


@Pipe({name: 'isLink'})
export class IsLinkPipe implements PipeTransform {
  transform(link: string): boolean {
    return link ? this.linkify(link) : false;
  }

  private linkify(plainText): boolean {
    var httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    return !!httpRegex.test(plainText);
  }
}
