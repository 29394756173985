<div class = "privacy-layout">

  <ng-template #privacyTemplate>
    <div class="modal-header">
      <h5 class="modal-title pull-left" translate>Privacy terms</h5>
    </div>
    <div class="modal-body">
      <app-privacy></app-privacy>
    </div>
    <div class="modal-footer">
      <a (click)="acceptPrivacy()" class="btn btn-success pull-right" translate>Accept</a>
      <a (click)="declinePrivacy()" class="btn btn-danger pull-right" translate>Decline</a>
    </div>
  </ng-template>
</div>

