import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomFieldDirective} from './custom-field.directive';
import {CustomFieldService} from './custom-field.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CustomFieldDirective
  ],
  exports: [
    CustomFieldDirective
  ],
  providers: [
    CustomFieldService
  ]
})
export class CustomFieldModule {

  static forRoot(): ModuleWithProviders<CustomFieldModule> {
    return {
      ngModule: CustomFieldModule,
      providers: [
        CustomFieldService
      ]
    };
  }
}
