<div>
  <div class="modal-header">
    <h5 class="modal-title pull-left">{{configuration && configuration.title}}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" 
  		*ngIf="configuration" 
  		[ngClass]="configuration.type == 'video'? 'modal-body-nopadding' : ''">
	
  	<div class="modal-body-wrapper" *ngIf="configuration.type == 'html'">
	    <div [innerHTML]="configuration.body"></div>
  	</div>
  	
  	<div class="modal-body-wrapper" *ngIf="configuration.type == 'video'">
	    <video controls>
	      <source [src]="configuration.url" type="video/mp4">
	      Your browser does not support the video tag.
	    </video>
  	</div>
  	
  	<div class="modal-body-wrapper" *ngIf="configuration.type == 'pdf'">
	    <iframe [src]="configuration.url" width="100%" height="100%"></iframe>
  	</div>
  	
  	<div class="modal-body-wrapper" *ngIf="configuration.type == 'closeWarning'">
	    <div style="padding: 15px">
	      <p class="alert alert-danger" translate>Another content is currently opened and the one you were on has now been closed</p>
	    </div>
  	</div>

  	<div class="modal-body-wrapper" *ngIf="configuration.type == 'alert'">
	    <div style="padding: 15px">
	      <p [innerHtml]="configuration.body"></p>
	    </div>
  	</div>

  </div>

</div>
