<div *ngIf="courseScorm">
<!-- 	<div class="course-title text-1">{{courseScorm.title}}</div> -->

	<div class="module-container" *ngFor="let module of courseScorm.modules; let m = index">
    <div class="row">
      <div class="col col-sm-7 module-title">{{m+1}}. {{module.title}}</div>
      <div class="col col-sm-5"></div>
    </div>
		<div class="row units-container" *ngFor="let unit of module.units; let u = index">
			<div class="col col-sm-1 text-right course-row-interactive">
				<span [statusIcon]="unit.lessonStatus" [onlyIcon]="true"></span>
			</div>
			<div class="col col-sm-11 unit-title-td course-row-interactive" (click)="openUnit(courseScorm.locked, unit)">
				<span class="fa fa-caret-right"></span> <span class="unit-title clickable">{{u+1}}.  {{unit.title}}</span>
			</div>
		</div>

	</div>

<!-- 	<table class="table units-table size-4"> -->
<!-- 		<tbody class="module-item col-sm-12" *ngFor="let module of courseScorm.modules; let m = index"> -->
<!-- 			<tr class="module-title size-3"> -->
<!-- 				<td colspan="2">{{m+1}}. {{module.title}}</td> -->
<!-- 			</tr> -->
<!-- 			<tr *ngFor="let unit of module.units; let u = index" (click)="openUnit(unit)" class="course-row-interactive"> -->
<!-- 				<td class="width-50 unit-title-td"> -->
<!-- 					<span> -->
<!-- 						<span class="fa fa-chevron-right"></span> <span class="clickable">{{u+1}}.  {{unit.title}}</span> -->
<!-- 					</span> -->
<!-- 				</td> -->
<!-- 				<td class="width-50 unit-status-td"> -->
<!-- 					<span [statusIcon]="unit.status"></span> -->
<!-- 				</td> -->
<!-- 			</tr> -->
<!-- 		</tbody> -->
<!-- 	</table> -->
</div>
