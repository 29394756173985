<div class="course-container">
	<div *ngIf="_course.theme" class="course-theme">
		<span class="course-theme-text">{{_course.theme}}</span>
	</div>

	<app-course-resources
		[course]="_course"
		(unitSelected)="uSelected($event)"
		(resourceSelected)="rSelected($event)"
    (lessonSelected)="lSelected($event)">
	</app-course-resources>

</div>
