import {Injectable} from '@angular/core';
import {dispatch} from '@angular-redux/store';
import {FluxStandardAction} from 'flux-standard-action';
import {LmsCourse} from './model';
import {CoursesState} from './reducers';

export type CoursesPayload = CoursesState | Array<LmsCourse> | LmsCourse | string | any;
export type CoursesAction<T extends CoursesPayload = undefined> = FluxStandardAction<string, T, any>;


@Injectable()
export class LmsCoursesActions {

  static readonly COURSE_OPEN = 'COURSE_OPEN';
  static readonly COURSE_OPENING = 'COURSE_OPENING';
  static readonly COURSE_OPEN_FAILED = 'COURSE_OPEN_FAILED';
  static readonly COURSE_OPENED = 'COURSE_OPENED';
  static readonly COURSE_LOCK = 'COURSE_LOCK';
  static readonly COURSE_UNLOCK = 'COURSE_UNLOCK';
  static readonly COURSE_LOCKED = 'COURSE_LOCKED';
  static readonly COURSE_UNLOCKED = 'COURSE_UNLOCKED';

  static readonly COURSES_LOAD = 'COURSES_LOAD';
  static readonly COURSES_LOADING = 'COURSES_LOADING';
  static readonly COURSES_LOAD_FAILED = 'COURSES_LOAD_FAILED';
  static readonly COURSES_LOADED = 'COURSES_LOADED';


  @dispatch()
  loadCourses = (): CoursesAction => ({
    type: LmsCoursesActions.COURSES_LOAD,
    meta: {}
  })

  coursesLoading = (): CoursesAction => ({
    type: LmsCoursesActions.COURSES_LOADING,
    meta: {}
  })

  coursesLoadFailed = (error: any): CoursesAction<any> => ({
    type: LmsCoursesActions.COURSES_LOAD_FAILED,
    meta: {},
    payload: error
  })

  coursesLoaded = (courses: Array<LmsCourse>): CoursesAction<Array<LmsCourse>> => ({
    type: LmsCoursesActions.COURSES_LOADED,
    meta: {},
    payload: courses
  })


  @dispatch()
  openCourse = (id: number): CoursesAction => ({
    type: LmsCoursesActions.COURSE_OPEN,
    meta: id
  })

  @dispatch()
  lockCourse = (): CoursesAction => ({
    type: LmsCoursesActions.COURSE_LOCK,
    meta: {}
  })

  @dispatch()
  unlockCourse = (): CoursesAction => ({
    type: LmsCoursesActions.COURSE_UNLOCK,
    meta: {}
  })

  courseOpening = (): CoursesAction => ({
    type: LmsCoursesActions.COURSE_OPENING,
    meta: {}
  })

  courseOpenError = (error: any): CoursesAction<any> => ({
    type: LmsCoursesActions.COURSE_OPEN_FAILED,
    meta: {},
    payload: error
  })

  courseOpened = (course: LmsCourse): CoursesAction<LmsCourse> => ({
    type: LmsCoursesActions.COURSE_OPENED,
    meta: {},
    payload: course
  })

  courseLocked = (uuid: string): CoursesAction<string> => ({
    type: LmsCoursesActions.COURSE_LOCKED,
    meta: {},
    payload: uuid
  })

  courseUnlocked = (): CoursesAction => ({
    type: LmsCoursesActions.COURSE_UNLOCKED,
    meta: {},
  })
}
