import {Injectable} from "@angular/core";
import {dispatch} from "@angular-redux/store";
import {FluxStandardAction} from "flux-standard-action";
import {ICustomThemeConfig} from "../model";


export type ThemePayload = any | ICustomThemeConfig;
export type ThemeAction<T extends ThemePayload = undefined> = FluxStandardAction<string, T, any>;


@Injectable()
export class ThemeActions {
  static readonly LOAD_CUSTOM_THEME = 'LOAD_CUSTOM_THEME';
  static readonly LOAD_CUSTOM_THEME_STARTED = 'LOAD_CUSTOM_THEME_STARTED';
  static readonly LOAD_CUSTOM_THEME_FAILED = 'LOAD_CUSTOM_THEME_FAILED';
  static readonly LOAD_CUSTOM_THEME_SUCCEEDED = 'LOAD_CUSTOM_THEME_SUCCEEDED';


  @dispatch()
  loadCustomTheme = (themeName: String): ThemeAction => ({
    type: ThemeActions.LOAD_CUSTOM_THEME,
    meta: {themeName},
  });

  loadCustomThemeStarted = (): ThemeAction => ({
    type: ThemeActions.LOAD_CUSTOM_THEME_STARTED,
    meta: {},
  });

  loadCustomThemeSucceeded = (config: ICustomThemeConfig): ThemeAction<ICustomThemeConfig> => ({
    type: ThemeActions.LOAD_CUSTOM_THEME_SUCCEEDED,
    meta: {},
    payload: {...config}
  });


  loadCustomThemeFailed = (err: any): ThemeAction => ({
    type: ThemeActions.LOAD_CUSTOM_THEME_FAILED,
    meta: {err},
  });


}
