import {Injectable} from '@angular/core';
import {dispatch} from '@angular-redux/store';
import {FluxStandardAction} from 'flux-standard-action';
import {ScormError} from '../../model';
import {ScormUnit} from '../model';


export type ScormUnitPayload = ScormUnit | ScormError | void | boolean;

export interface ScormUnitMeta {
  unitId?: number;
}

export type ScormUnitAction<T extends ScormUnitPayload> = FluxStandardAction<string, T, ScormUnitMeta>;

@Injectable()
export class ScormUnitActions {
  static readonly OPEN_SCORM_UNIT = 'OPEN_SCORM_UNIT';
  static readonly NEXT_SCORM_UNIT = 'NEXT_SCORM_UNIT';
  static readonly PREV_SCORM_UNIT = 'PREV_SCORM_UNIT';
  static readonly SCORM_UNIT_OPENING = 'SCORM_UNIT_OPENING';
  static readonly SCORM_UNIT_ERROR = 'SCORM_UNIT_ERROR';
  static readonly SCORM_UNIT_OPENED = 'SCORM_UNIT_OPENED';
  static readonly SCORM_UNIT_CLOSED = 'SCORM_UNIT_CLOSED';

  static readonly SCORM_UNIT_HAS_NEXT = 'SCORM_UNIT_HAS_NEXT';
  static readonly SCORM_UNIT_HAS_PREV = 'SCORM_UNIT_HAS_PREV';

  static readonly SCORM_UNIT_LOCKED = 'SCORM_UNIT_LOCKED';


  @dispatch()
  openScormUnit = (unit: ScormUnit): ScormUnitAction<ScormUnit> => ({
    type: ScormUnitActions.OPEN_SCORM_UNIT,
    payload: {...unit}
  });

  @dispatch()
  nextScormUnit = (): ScormUnitAction<void> => ({
    type: ScormUnitActions.NEXT_SCORM_UNIT,
  });

  @dispatch()
  prevScormUnit = (): ScormUnitAction<void> => ({
    type: ScormUnitActions.PREV_SCORM_UNIT,
  });

  scormUnitOpening = (unit: ScormUnit): ScormUnitAction<ScormUnit> => ({
    type: ScormUnitActions.SCORM_UNIT_OPENING,
    payload: {...unit}
  });

  scormUnitError = (error: ScormError): ScormUnitAction<ScormError> => ({
    type: ScormUnitActions.SCORM_UNIT_ERROR,
    payload: {...error}
  });

  scormUnitOpened = (unit: ScormUnit): ScormUnitAction<ScormUnit> => ({
    type: ScormUnitActions.SCORM_UNIT_OPENED,
    payload: unit
  });

  @dispatch()
  scormUnitClosed = (unit: ScormUnit): ScormUnitAction<ScormUnit> => ({
    type: ScormUnitActions.SCORM_UNIT_CLOSED,
    payload: unit
  });

  scormHasNext = (hasNext: boolean): ScormUnitAction<boolean> => ({
    type: ScormUnitActions.SCORM_UNIT_HAS_NEXT,
    payload: hasNext
  });

  scormHasPrev = (hasPrev: boolean): ScormUnitAction<boolean> => ({
    type: ScormUnitActions.SCORM_UNIT_HAS_PREV,
    payload: hasPrev
  })
}
