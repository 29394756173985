import {ElementRef, Injectable} from "@angular/core";
import {dispatch} from "@angular-redux/store";
import {FluxStandardAction} from "flux-standard-action";
import {ScormError} from "../../model";
import {ResourceMetadata} from "../../../resources/model";
import {ScormCourse} from "../model";


export type ScormCoursePayload = ScormCourse | ScormError | number | boolean | void;

export interface ScormCourseMeta {
  resourceId?: number;
  unitView?: ElementRef;
}

export type ScormCourseAction<T extends ScormCoursePayload> = FluxStandardAction<string, T, ScormCourseMeta>;


@Injectable()
export class ScormCourseActions {
  static readonly LOAD_SCORM_COURSE = 'LOAD_SCORM_COURSE';
  static readonly LOAD_SCORM_STARTED = 'LOAD_SCORM_STARTED';
  static readonly LOAD_SCORM_SUCCEEDED = 'LOAD_SCORM_SUCCEEDED';
  static readonly LOAD_SCORM_FAILED = 'LOAD_SCORM_FAILED';
  static readonly CLOSE_SCORM_COURSE = 'CLOSE_SCORM_COURSE';
  static readonly SCORM_COURSE_CLOSED = 'SCORM_COURSE_CLOSED';

  @dispatch()
  loadScorm = (resourceId: number): ScormCourseAction<number> => ({
    type: ScormCourseActions.LOAD_SCORM_COURSE,
    meta: {},
    payload: resourceId
  });

  @dispatch()
  closeScorm = (): ScormCourseAction<void> => ({
    type: ScormCourseActions.CLOSE_SCORM_COURSE,
    meta: {},
  });

  loadStarted = (): ScormCourseAction<void> => ({
    type: ScormCourseActions.LOAD_SCORM_STARTED,
  });

  loadSucceeded = (scorm: ScormCourse): ScormCourseAction<ScormCourse> => ({
    type: ScormCourseActions.LOAD_SCORM_SUCCEEDED,
    payload: {...scorm},
  });

  loadFailed = (error: ScormError): ScormCourseAction<ScormError> => ({
    type: ScormCourseActions.LOAD_SCORM_FAILED,
    payload: {...error}
  });

  scormClosed = (): ScormCourseAction<void> => ({
    type: ScormCourseActions.SCORM_COURSE_CLOSED,
  })


}

