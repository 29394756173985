import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../../src/app/authentication/guard';
import {BootGuard} from '../../../../src/app/boot/guard';
import {LoginComponent} from '../../../../src/app/authentication/login/component';
import {UserProfileComponent} from '../../../../src/app/user/profile/component';
import {PrivacyLayoutComponent} from '../../../../src/app/layout/privacy/component';
import {HomeComponent} from '../../../../src/app/layout/home/component';
import {LmsPathComponent} from '../../../../src/app/lms/paths/component';
import {CoursesComponent} from '../../../../src/app/lms/courses/list/component';
import {ExternalCoursesComponent} from '../../../../src/app/lms/external/courses/external-courses.component';
import {LmsCatalogComponent} from '../../../../src/app/lms/catalog/component';
import {CatalogCourseComponent} from '../../../../src/app/lms/paths/details/catalog-course.component';
import {ExternalCourseComponent} from '../../../../src/app/lms/external/course/external-course.component';
import {
  AdministrativeUserImpersonationComponent
} from '../../../../src/app/authentication/impersonation/administrative-user-impersonation.component';
import {SessionsCalendarComponent} from '../../../../src/app/lms/synchronous/sessions-calendar/sessions-calendar.component';
import {RefreshTokenLoginComponent} from '@shared/app/authentication/refresh-token/refresh-token-login.component';
import {ErrorDefaultComponent} from '@shared/app/layout/error-default/error-default.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent, canActivate: [BootGuard]},
  {path: 'logout', component: LoginComponent},
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent, canActivate: [BootGuard, AuthGuard]},
  {path: 'profile', component: UserProfileComponent, canActivate: [AuthGuard]},
  {path: 'privacy', component: PrivacyLayoutComponent, canActivate: [BootGuard]},
  {path: 'courses', component: CoursesComponent, canActivate: [AuthGuard]},
  {path: 'catalog', component: LmsCatalogComponent, canActivate: [AuthGuard]},
  {path: 'impersonate/:userId', component: AdministrativeUserImpersonationComponent},
  {path: 'refreshToken', component: RefreshTokenLoginComponent},
  {
    path: 'path/:id', component: LmsPathComponent, canActivate: [AuthGuard], children: [
      {path: 'course/:courseId', component: LmsPathComponent, canActivate: [AuthGuard]}
    ]
  },
  {path: 'externalcourses', component: ExternalCoursesComponent, canActivate: [AuthGuard]},
  {path: 'catalogcourse/:id', component: CatalogCourseComponent, canActivate: [AuthGuard]},
  {path: 'catalogcourse/:id/session/:sessionId', component: CatalogCourseComponent, canActivate: [AuthGuard]},
  {path: 'externalcourse/:id', component: ExternalCourseComponent, canActivate: [AuthGuard]},
  {path: 'calendar', component: SessionsCalendarComponent, canActivate: [BootGuard, AuthGuard]},
  {path: 'error', component: ErrorDefaultComponent, data: {template: 'wide'}},
  {path: 'error/:errorCode', component: ErrorDefaultComponent, data: {template: 'wide'}},
  {path: 'error/:errorCode/message/:errorMessage', component: ErrorDefaultComponent, data: {template: 'wide'}},
  {path: '**', redirectTo: ''}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule {

}
