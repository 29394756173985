import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AuthActions} from "./store/actions";
import {AuthenticationEpics} from "./store/epics";
import {AuthenticationService} from "./service";
import {LoginComponent} from "./login/component";
import {FormsModule} from "@angular/forms";
import {AuthGuard} from "./guard";
import {RouterModule} from "@angular/router";
import {AppConfigModule} from "../config/module";
import {CasService} from "./cas/service";
import {ImpersonateComponent} from "./impersonate/impersonate.component";
import {UserModule} from "../user/module";

@NgModule({
  declarations: [LoginComponent, ImpersonateComponent],
  exports: [],
  imports: [CommonModule, AppConfigModule, FormsModule, RouterModule, UserModule],
  providers: [
    AuthActions,
    AuthenticationEpics,
    AuthenticationService,
    AuthGuard,
    CasService
  ],
  entryComponents: [ImpersonateComponent]
})
export class AuthenticationModule {
}
