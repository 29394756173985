import {Action} from 'redux';
import {AuthAction, AuthActions} from './actions';
import {AuthError, LoginError, UserAuth} from '../model';
import {CasProvider} from '../cas/model';

export interface AuthState {
  casProviders?: CasProvider[];
  access_token?: string;
  login_started?: boolean;
  login_succeeded?: boolean;
  login_failed?: boolean;
  login_error?: LoginError;
  authenticating?: boolean;
  authenticated?: boolean;
  authFailed?: boolean;
  auth_error?: AuthError;
  logged_out?: boolean;
  impersonating?: boolean;
  refresh_token?: string;
}

const INITIAL_STATE: AuthState = {
  casProviders: [],
  access_token: undefined,
  login_started: false,
  login_succeeded: false,
  login_failed: false,
  login_error: undefined,
  authenticating: false,
  authenticated: false,
  authFailed: false,
  auth_error: undefined,
  logged_out: undefined,
  impersonating: false,
  refresh_token: undefined
};

export const authReducer = () => (state: AuthState = INITIAL_STATE, action: Action): AuthState => {

  switch (action.type) {
    case AuthActions.AUTH_FOUND_TOKEN:
      return {
        ...state,
        access_token: (action as AuthAction<string>).payload,
      };
    case AuthActions.USER_LOGIN_STARTED:
      return {
        ...state,
        authenticated: false,
        login_started: true,
        auth_error: undefined,
      };
    case AuthActions.USER_LOGIN_SUCCEEDED: {
      const userAuth = {...(action as AuthAction<UserAuth>).payload};
      return {
        ...state,
        login_started: false,
        login_succeeded: true,
        authenticated: true,
        access_token: userAuth.access_token
      };
    }
    case AuthActions.USER_LOGIN_FAILED:
      return {
        ...state,
        login_started: false,
        login_failed: true,
        login_error: {...(action as AuthAction<LoginError>).payload}
      };
    case AuthActions.USER_IMPERSONATE_LOGOUT:
      return {
        ...state,
        authenticating: true,
        impersonating: false,
        authenticated: false,
        auth_error: undefined,
      };
    case AuthActions.USER_AUTHENTICATION_STARTED: {
      const userAuth = {...(action as AuthAction<UserAuth>).payload};
      return {
        ...state,
        authenticating: true,
        authenticated: false,
        auth_error: undefined,
      };
    }
    case AuthActions.USER_AUTHENTICATION_SUCCEEDED: {
      const userAuth = {...(action as AuthAction<UserAuth>).payload};
      return {
        ...state,
        authenticating: false,
        authenticated: true,
        auth_error: undefined,
        access_token: userAuth.access_token
      };
    }
    case AuthActions.USER_AUTHENTICATION_FAILED:
      return {
        ...state,
        authenticating: false,
        authFailed: true,
        auth_error: {...(action as AuthAction<AuthError>).payload}
      };
    case AuthActions.USER_LOGGED_OUT:
      return {
        ...state,
        authenticated: false,
        logged_out: true,
      };

    case AuthActions.USER_IMPERSONATE_LOGIN_STARTED:
      return {
        ...state,
        authenticating: true,
        authenticated: false,
        auth_error: undefined,
      };

    case AuthActions.USER_IMPERSONATE_LOGIN_SUCCEEDED: {
      const userAuth = {...(action as AuthAction<UserAuth>).payload};
      return {
        ...state,
        authenticating: false,
        authenticated: true,
        auth_error: undefined,
        access_token: userAuth.access_token,
        impersonating: true,
      };
    }
    case AuthActions.USER_IMPERSONATE_LOGIN_FAILED: {
      const authError = {...(action as AuthAction<AuthError>).payload};
      return {
        ...state,
        authenticating: false,
        authenticated: false,
        auth_error: {...authError},
        impersonating: false,
      };
    }
    case AuthActions.USER_IMPERSONATE_LOGOUT_STARTED:
      return {
        ...state,
      };
    case AuthActions.USER_IMPERSONATE_LOGOUT_SUCCEEDED: {
      const userAuth = {...(action as AuthAction<UserAuth>).payload};
      return {
        ...state,
        authenticating: false,
        authenticated: true,
        auth_error: undefined,
        access_token: userAuth.access_token,
        impersonating: false,
      };
    }
    case AuthActions.USER_IMPERSONATE_LOGOUT_FAILED:
      return {
        ...state,
        auth_error: {...(action as AuthAction<AuthError>).payload},
        impersonating: false,
      };
    case AuthActions.CAS_PROVIDERS_LOADED:
      return {
        ...state,
        casProviders: (action as AuthAction<any>).payload
      };

    case AuthActions.USER_REFRESH_TOKEN_LOGIN: {
      return {
        ...state,
        refresh_token: (action as AuthAction<any>).payload
      };
    }
  }
  return state;
};

