import {Component, Input, OnInit} from '@angular/core';
import {VERSION} from "../../../environments/version";

@Component({
  selector: 'app-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss']
})
export class VersionInfoComponent implements OnInit {

  version = VERSION;
  @Input() showLabel: boolean;
  @Input() emptyStyle: boolean;

  constructor() {
    this.showLabel = false;
    this.emptyStyle = false;
  }

  ngOnInit() {
  }

}
