import {Injectable} from "@angular/core";
import {FluxStandardAction} from "flux-standard-action";
import {dispatch} from "@angular-redux/store";


export type FieldsPayload = any;
export type FieldsAction<T extends FieldsPayload = undefined> = FluxStandardAction<string, T, any>;

@Injectable()
export class FieldsActions {
  static readonly LOAD_CUSTOM_FIELDS = 'LOAD_CUSTOM_FIELDS';
  static readonly LOAD_CUSTOM_FIELDS_SUCCEEDED = 'LOAD_CUSTOM_FIELDS_SUCCEEDED';


  @dispatch()
  loadCustomFields = (langCode): FieldsAction => ({
    type: FieldsActions.LOAD_CUSTOM_FIELDS,
    meta: langCode,
  });

  loadCustomFieldsSucceeded = (customFields): FieldsAction<any> => ({
    type: FieldsActions.LOAD_CUSTOM_FIELDS_SUCCEEDED,
    meta: {},
    payload: customFields
  })

}
