<app-banner
  [display]="'thin'"
  [title]="bannerTitle"
  [description]="bannerDescription"
  [backgroundImage]="'assets/img/courses-banner.jpg'"
  [colorSchema]="'light'"
></app-banner>

<div class="paths-container page-template-thin">

  <div [spinner]="loading"></div>
  <div class="row " *ngIf="paths" @pageAnimations>
    <div class="path-item col-md-6 col-sm-12" *ngFor="let path of paths" (click)="openPath(path)">
      <div section class="panel panel-box" [@mouseOver]="path['mouse']" (mouseover)="mouseover(path)"
           (mouseout)="mouseout(path)">
        <div class="panel-top bg-main">
          <div class="path-cover" [style.background-image]="path.imageUrl"></div>
          <div class="path-cover-overlay"></div>
          <div class="path-title">{{path.title}}</div>
          <div *ngIf="path.theme" class="path-theme">
            <span class="path-theme-text">{{path.theme}}</span>
          </div>
        </div>
        <div class="panel-bottom bg-reverse">
          <div class="path-status" [ngClass]="path.statusView">
            <span class="path-status-text" translate>{{path.statusLabel}}</span>
          </div>
          <div class="path-details">
            <span class="fa fa-book"></span>&nbsp;
            <strong><span translate>Content</span>:</strong>&nbsp;<span>{{path.totalCourses}} <span
            translate>courses</span></span>
          </div>
<!--          <div *ngIf="path.mandatory" class="path-label-mandatory">-->
<!--            <i class="path-label-mandatory-icon fa fa-hand-o-right"></i>-->
<!--            <span class="path-label-mandatory-text" translate>mandatory</span>-->
<!--          </div>-->
<!--          <div *ngIf="!path.mandatory" class="path-label-optional">-->
<!--            <i class="path-label-mandatory-icon fa fa-hand-o-right"></i>-->
<!--            <span class="path-label-mandatory-text" translate>optional</span>-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <div class="col col-sm-12" *ngIf="paths.length == 0">
      <div class="alert alert-info" translate>No learning path assigned</div>
    </div>
  </div>
  <div class="row" *ngIf="loadingError">
    <div class="col col-sm-12">
      <div class="alert alert-danger" translate>Impossible to retieve learning paths</div>
    </div>
  </div>
</div>
