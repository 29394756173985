import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {AppEnvService} from '@shared/app/config/env/service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Lesson, VdcUrlResponse} from '@shared/app/lms/synchronous/course/model';
import {LessonSession, LessonsSessions, VdcRequest} from '@shared/app/lms/synchronous/model';
import * as moment from 'moment-timezone';

@Injectable()
export class SynchronousService {
  activeSessionsLessonsUser: string;
  allSessionsSessionsLessonsUser: string;

  constructor(private http: HttpClient, private envService: AppEnvService) {
    this.activeSessionsLessonsUser = envService.config().api.activeSessionsLessonsUser;
    this.allSessionsSessionsLessonsUser = envService.config().api.allSessionsSessionsLessonsUser;
  }

  getVdcLink(lessonId: number, vdcId: string, userEmail: string, username: string, role: string): Observable<VdcUrlResponse> {
    const url = this.envService.config().api.vdcLink;
    const request = new VdcRequest();
    request.lessonId = lessonId;
    request.vdcId = vdcId;
    request.userEmail = userEmail;
    request.role = role;
    request.username = username;

    return this.http.post<VdcUrlResponse>(url, request).pipe(map(s => {
      return s;
    }));
  }

  canOpenVdc(lesson: Lesson): boolean {
    if (moment(lesson.dateStart).isSame(moment(), 'day')) {
      return true;
    }
    return false;
  }

  getActiveSessionsLessonsUser = () => this.http.get<LessonSession[]>(this.activeSessionsLessonsUser);

  getAllSessionsSessionsLessonsUser = () => this.http.get<LessonsSessions>(this.allSessionsSessionsLessonsUser);
}

