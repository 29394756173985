import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Resource} from "./model";
import {AppEnvService} from "../../config/env/service";

@Injectable()
export class ResourceService {

    constructor(private http: HttpClient, private envService: AppEnvService) { }

    getResource(resourceId: number): Observable<Resource> {
        let url = `${this.envService.config().api.resources}/${resourceId.toString()}`;
        return this.http.get<Resource>(url);
    }

    setResourceOpened(resourceId: number, courseId: number, pathId: number, catalogId: number): Observable<Object> {
        let url = `${this.envService.config().api.resources}/${resourceId.toString()}/track`;
        return this.http.post(url, {
            courseId: courseId,
            pathId: pathId,
            catalogId: catalogId
        });
    }

    setResourceUsed(objectId: string): Observable<boolean> {
        return this.http.post(this.envService.config().api.used, { resourceId: objectId }).pipe(map(
            data => true,
            err => false
        ));
    }

    unsetResourceUsed(): Observable<boolean> {
        return this.http.delete(this.envService.config().api.used).pipe(map(
            data => true,
            err => false
        ));
    }

    checkResourceUsed(): Observable<string> {
        return this.http.get(this.envService.config().api.used).pipe(map(
          d => d !== undefined ? d['resourceId'] : undefined,
          err => undefined
        ));
    }

    getCertificate(courseId: number): Observable<any> {
        return this.http.post(`${this.envService.config().api.certificates}/render`, { courseId: courseId }, { responseType: 'blob' });
    }

    downloadCertificate(courseId: number): Observable<any> {
      return this.http.post(`${this.envService.config().api.certificates}/render`, { courseId: courseId }, { responseType: 'blob' });
    }
}

export function downloadFile(blob: any, type: string, filename: string): string {
  const url = window.URL.createObjectURL(blob); // <-- work with blob directly

  const a = document.createElement('a');
  a.setAttribute('style', 'display:none;');
  document.body.appendChild(a);

  a.href = url;
  a.download = filename;
  a.click();
  return url;
}
