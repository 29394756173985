import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {ExternalCourse} from "./model";
import {AppEnvService} from "../../../config/env/service";

@Injectable()
export class ExternalCourseService {

  apiUrl: string;

  constructor(private http: HttpClient, private envService: AppEnvService) {
    this.apiUrl = `${this.envService.config().api.external.courses}/me`;
  }

  externalCourses = (): Observable<ExternalCourse[]> =>
    this.http.get<ExternalCourse[]>(this.apiUrl);

  externalCourse = (id: number): Observable<ExternalCourse> =>
    this.http.get<ExternalCourse>(`${this.apiUrl}/${id}`);
}
