import {Component, ComponentFactory, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CoursesByThemeAreaComponent} from './by-them-area/component';
import {FlatCoursesComponent} from './flat/flat-courses.component';
import {map} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {AppEnvService} from '../../../config/env/service';
import {ThemeState} from '../../../theme/store/reducers';
import {select} from '@angular-redux/store';
import {ComponentHostDirective} from '../../../common/directives';

@Component({
  selector: 'app-courses',
  templateUrl: './component.html'
})
export class CoursesComponent implements OnInit, OnDestroy {

  @ViewChild(ComponentHostDirective, {static: true}) componentHost: ComponentHostDirective;

  subscriptions: Subscription[] = [];
  byAreaThemes: string[];

  @select('theme')
  readonly themeState: Observable<ThemeState>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private environmentService: AppEnvService) {
    this.byAreaThemes = this.environmentService.config().theme.views.courses.byThemeArea;
  }

  ngOnInit(): void {
    const componentFactory = (themeState: ThemeState) => {
      return this.byAreaThemes.includes(themeState.config.name) || themeState.config.courseByThemeArea === true
        ? (this.componentFactoryResolver.resolveComponentFactory(CoursesByThemeAreaComponent))
        : this.componentFactoryResolver.resolveComponentFactory(FlatCoursesComponent);
    };

    const createComponent = (factory: ComponentFactory<any>) => {
      this.componentHost.viewContainerRef.clear();
      this.componentHost.viewContainerRef.createComponent(factory);
    };

    this.subscriptions.push(
      this.themeState.pipe(
        map(componentFactory),
        map(createComponent)
      ).subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
