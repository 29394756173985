import {Pipe, PipeTransform} from '@angular/core';


@Pipe({name: 'linkify'})
export class LinkifyPipe implements PipeTransform {
  transform(link: string, textToReplace?: string): string {
    return this.linkify(link, textToReplace);
  }

  private linkify(plainText, textToReplace): string {
    let replacedText;
    let replacePattern1;
    let replacePattern2;
    let replacePattern3;

    textToReplace = textToReplace ? textToReplace : plainText;

    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = plainText.replace(replacePattern1, `<a href="$1" target="_blank">${textToReplace}</a>`);

    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, `$1<a href="http://$2" target="_blank">${textToReplace}</a>`);

    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, `<a href="mailto:$1">${textToReplace}</a>`);

    return replacedText;
  }
}
