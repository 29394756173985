export class ExternalCourse {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  title: string;
  description: string;
  duration: number;
  durationDate: Date;
  status: string;
  fileName: string;
  fileUrl: string;
  file: File;
  createdAt: string;
  trainingDateFrom: string;
  trainingDateTo: string;
}
