import {NgModule} from '@angular/core';
import {CourseScormComponent} from './course/course-scorm.component';
import {AppCommonModule} from '../../common/module';
import {CommonModule} from '@angular/common';
import {ScormUnitActions} from './unit/store/actions';
import {ScormUnitEpics} from './unit/store/epics';
import {ScormCourseActions} from './course/store/actions';
import {ScormCourseEpics} from './course/store/epics';
import {ScormCourseService} from './course/store/service';
import {LessonsEpics} from '@shared/app/lms/synchronous/store/epics';
import {LmsLessonsActions} from '@shared/app/lms/synchronous/store/actions';

@NgModule({

  declarations: [CourseScormComponent],
  imports: [CommonModule, AppCommonModule],
  providers: [ScormCourseService, ScormUnitActions, ScormUnitEpics, ScormCourseActions, ScormCourseEpics],
  exports: [CourseScormComponent]

})
export class ScormModule {

}
