import {Injectable} from "@angular/core";
import {AppEnvService} from "../../config/env/service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {DomSanitizer, SafeStyle} from "@angular/platform-browser";
import {Path} from "./model";

@Injectable()
export class LmsPathService {
  constructor(
    private envService: AppEnvService,
    private http: HttpClient,private sanitizer: DomSanitizer) {

  }

  paths(): Observable<Path[]> {
    return this.http.get<Path[]>(this.envService.config().api.lms.paths).pipe(map(paths => {
      paths.forEach(p => p.imageUrl = this.getPathImageUrl(p.image));
      return paths;
    }));
  }

  private getPathImageUrl(url: string): SafeStyle {
    if (url !== undefined && url !== null && url !== '') {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + this.escapeQuote(url) + ')');
    } else {
      return this.sanitizer.bypassSecurityTrustStyle('url(assets/img/path-cover-default.jpg)');
    }
  }

  private escapeQuote(s: string): string {
    return encodeURI(s).replace('\'', '\\\'');
  }



}