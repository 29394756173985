import {Action} from 'redux';
import {LmsLessonsActions, LessonsAction} from './actions';
import {LessonsSessions} from '@shared/app/lms/synchronous/model';


export interface LessonsState {
  loadStarted: boolean,
  loadFilter: string,
  loadFailed: boolean,
  loadCompleted: boolean,
  loadError: any,
  list: Array<LessonsSessions>,
}

const INITIAL_STATE: LessonsState = {
  loadStarted: false,
  loadFailed: false,
  loadFilter: undefined,
  loadCompleted: false,
  loadError: undefined,
  list: undefined,
};

export const lessonsReducer = () => (state: LessonsState = INITIAL_STATE, action: Action): LessonsState => {
  switch (action.type) {
    case LmsLessonsActions.LESSONS_LOAD:
      return {
        ...state,
        loadFilter: (action as LessonsAction<string>).payload,
      };
    case LmsLessonsActions.LESSONS_LOAD_STARTED:
      return {
        ...state,
        loadStarted: true,
      };
    case LmsLessonsActions.LESSONS_LOAD_FAILED:
      return {
        ...state,
        loadStarted: false,
        loadFailed: true,
        loadError: {...(action as LessonsAction<any>).payload}
      };
    case LmsLessonsActions.LESSONS_LOAD_SUCCEED:
      return {
        ...state,
        loadCompleted: true,
        loadStarted: false,
        loadFailed: false,
        list: (action as LessonsAction<Array<LessonsSessions>>).payload
      };
  }
  return state;
};

