import {NgModule} from "@angular/core";
import {UserService} from "./service";
import {UserActions} from "./store/actions";
import {UserEpics} from "./store/epics";
import {HierarchyService} from "./hierarchy/api/service";
import {UserRealmSelectionComponent} from "./hierarchy/component";
import {CommonModule} from "@angular/common";
import {UserProfileComponent} from "./profile/component";
import {RouterModule} from "@angular/router";
import {ImageUploadModule} from "angular2-image-upload";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {I18NModule} from "../i18n/module";
import {TabsModule} from "ngx-bootstrap/tabs";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {CustomFieldModule} from "../config/custom-field/custom-field.module";
import {PrivacyComponent} from "./privacy/privacy.component";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [UserRealmSelectionComponent, UserProfileComponent, PrivacyComponent],
  exports: [UserRealmSelectionComponent, UserProfileComponent, PrivacyComponent],
  imports: [
    CommonModule,
    RouterModule,
    ImageUploadModule.forRoot(),
    ReactiveFormsModule,
    TabsModule.forRoot(),
    CustomFieldModule,
    FormsModule,
    TooltipModule.forRoot(),
    I18NModule
  ],
  providers: [UserService, UserActions, UserEpics, HierarchyService]

})

export class UserModule {

}
