<app-banner *ngIf="path"
            [display]="'thin'"
            [title]="path!.title"
            [backgroundImage]="path.image"
            [colorSchema]="'light'"
></app-banner>

<div class="path-container page-template-thin">
  <div [spinner]="loading"></div>
  <div class="row" *ngIf="path">
    <div class="col-md-4 col-sm-12">
      <div class="path-info-box box-1">
        <div class="path-details">
          <div class="path-details-row">
            <strong><i class="fa fa-book"></i>&nbsp;<span translate>Content</span>:</strong>&nbsp;<span
            *ngIf="path.courses">{{path.courses.length}} corsi</span>
          </div>
<!--          <div *ngIf="path.mandatory" class="path-label-mandatory">-->
<!--            <i class="path-label-mandatory-icon fa fa-hand-o-right"></i>-->
<!--            <span class="path-label-mandatory-text" translate>mandatory</span>-->
<!--          </div>-->
<!--          <div *ngIf="!path.mandatory" class="path-label-optional">-->
<!--            <i class="path-label-mandatory-icon fa fa-hand-o-right"></i>-->
<!--            <span class="path-label-mandatory-text" translate>optional</span>-->
<!--          </div>-->
          <div class="path-details-row">
            <strong>
              <i class="fa fa-book"></i>&nbsp;<span class="text-capitalize" translate>duration</span>:
            </strong>
            <span>
								<span [appDuration]="path.totalLength" [format]="'minutes'"></span>
							</span>
            <span></span>
          </div>
          <div class="path-details-row">
            <button class="btn btn-primary clickable btn-enter" *ngIf="path.enrolled !== true"
                    [ngClass]="{'disabled': loadingEnroll === true}"
                    (click)="enrollToPath(path)">
              <span translate>enroll</span>&nbsp;
              <i *ngIf="loadingEnroll !== true" class="fa fa-user-plus"></i>
              <i *ngIf="loadingEnroll === true" class="fa fa-spinner fa-spin"></i>
            </button>
            <span *ngIf="path.enrolled === true" class="course-enrolled"><i class="fa fa-check"></i>&nbsp;<span
              translate>enrolled</span></span>
          </div>
          <div class="path-details-row">
            <button class="btn btn-primary clickable btn-enter" *ngIf="path.enrolled === true && path.totalCourses > 1"
                    (click)="goToPath(path)"><span translate>go to path</span>&nbsp;&nbsp;<i
              class="fa fa-caret-right"></i></button>
            <button class="btn btn-primary clickable btn-enter"
                    *ngIf="path.enrolled === true && path.totalCourses === 1"
                    (click)="goToPath(path)">
              <span translate>go to course</span>&nbsp;<i class="fa fa-caret-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="path-info-box box-2">
        <div class="path-description">
          <strong translate>Description</strong>
          <p [innerHTML]="path.description"></p>
        </div>
      </div>
    </div>

    <div class="col-md-8 col-sm-12">
      <div class="courses-container" *ngIf="path.courses">
        <div class="course-item" [id]="'course_'+course.id | encodeURI" *ngFor="let course of orderedCourses(); let i = index">
          <div class="course-box">
            <div *ngIf="course.theme" class="course-theme">
              <span class="course-theme-text">{{course.theme}}</span>
            </div>
            <div class="course-box-text">
              <div class="course-box-index">
                {{i + 1}}.
              </div>
              <div class="course-box-title">
                <span class="course-title">{{course.title}}</span>
                <div class="course-box-contents">
                  <div class="course-resources">
                    <div class="course-resource" [spinner]="course.isLoading"></div>
                    <div class="course-resource" *ngFor="let resource of course.resources">
                      <div class="resource-scorm" *ngIf="resource.type === resourceType.SCORM">
                        <div class="resource-module" *ngFor="let module of resource.metadata.modules">
                          <div class="resource-unit" *ngFor="let unit of module.units">
                            <div class="course-item" [id]="'unit_'+unit.id | encodeURI"><span translate
                                                           class="label sentence-capitalize">Lesson</span> {{unit.title}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="resource-scorm" *ngIf="resource.type === resourceType.SYNCHRONOUS">
                        <div class="resource-module" *ngFor="let session of resource.metadata.sessions">
                          <div class="course-item" [ngClass]="{'session-to-height-light bg-successs pulse': sessionIdToHeightLight.toString() === session.id.toString()}" [id]="'session_'+session.id | encodeURI">
                            <span translate class="label sentence-capitalize">classroom</span>&nbsp;

<!--                            non iscritto al path messaggio di obbligo iscrizione -->
                            <span
                              *ngIf="path.enrolled !== true"
                              class="label sentence-capitalize label-lesson label-lesson-info bg-danger"
                              tooltip="{{'You must be associated to a path before enroll this session' | translate}}">
                            <span translate>{{userSessionStatusLang[session.role]}}</span>&nbsp;<i
                              class="fa fa-info-circle"></i></span>

<!--                            iscritto al path, iscirtto alla sessione, sessione user/manager-->
                            <span
                              *ngIf="path.enrolled === true && session.role === UserSessionStatus.USER &&
                                    (session.rolesRegistration === RolesRegistration.USER || session.rolesRegistration === RolesRegistration.MANAGER)"
                              class="label sentence-capitalize label-lesson bg-success">
                            <span translate>{{userSessionStatusLang[session.role]}}</span></span>

<!--                            iscritto al path, possibile iscriversi alla sessione, sessione user-->
                            <span
                              *ngIf="path.enrolled === true && session.role === UserSessionStatus.ASSIGNABLE && session.rolesRegistration === RolesRegistration.USER"
                              class="label clickable sentence-capitalize label-lesson bg-info label-hover-opacity"
                              [ngClass]="{'disabled': session.isLoading === true || loadingAsyncSession === true}"
                              (click)="enrollToSession(session)" tooltip="{{ 'subscribe' | translate}}">
                              <span *ngIf="session.isLoading"><i class="fa fa-spinner fa-spin"></i>&nbsp;</span>
                              <span translate>{{userSessionStatusLang[session.role]}}</span>
                            </span>

<!--                            iscritto al path, possibile iscriversi, sessione manager-->
                            <span
                              *ngIf="path.enrolled === true && session.role ===  UserSessionStatus.ASSIGNABLE && session.rolesRegistration === RolesRegistration.MANAGER"
                              class="label sentence-capitalize label-lesson bg-success">
                            <span translate>the auto subscription for this session is disabled</span></span>

<!--                            iscritto al path, iscritto sessione, solo sessione user-->
                            <span
                              *ngIf="path.enrolled === true && session.role === UserSessionStatus.USER && session.rolesRegistration === RolesRegistration.USER">&nbsp;</span>
                            <span
                              *ngIf="path.enrolled === true && session.role === UserSessionStatus.USER && session.rolesRegistration === RolesRegistration.USER && session.state != SessionState.CLOSED"
                              class="label clickable sentence-capitalize bg-danger label-hover-opacity"
                              [ngClass]="{'disabled': session.isLoading === true || loadingAsyncSession === true}"
                              (click)="removeFromSession(session)" tooltip="{{ 'unsubscribe' | translate}}">
                              <span *ngIf="session.isLoading"><i class="fa fa-spinner fa-spin"></i>&nbsp;</span>
                              <span translate>unsubscribe</span>&nbsp;
                              <i class="fa fa-times"></i>
                            </span>

<!--                            iscritto al path, iscritto sessione in lista attesa-->
                            <span *ngIf="path.enrolled === true && session.role === UserSessionStatus.WAITING"
                                  [ngClass]="{'disabled': session.isLoading === true || loadingAsyncSession === true}"
                                  class="label sentence-capitalize label-lesson bg-warning">
                            <span
                              translate>{{userSessionStatusLang[session.role]}}</span></span> {{session.description}}
                          </div>
                        </div>
                      </div>
                      <div class="resource-document" *ngIf="resource.type === resourceType.DOCUMENT">
                        <div class="course-item" [id]="'resource_'+resource.id | encodeURI"><span translate
                                                       class="label sentence-capitalize">Document</span> {{resource.title}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="errorMessage" class="alert alert-danger">{{errorMessage}}</div>
</div>
