<app-banner [display]="'thin'" [title]="bannerTitle" [description]="bannerDescription"
  [backgroundImage]="'assets/img/course-banner.jpg'" [colorSchema]="'light'"></app-banner>

<div class="external-courses-container page-template-thin">
  <div class="row external-courses-toolbar">
    <button class="btn btn-info" (click)="openModal(externalCourseAddModalTemplate, $event)"><i class="fa fa-add"></i>
      <span translate>add new</span></button>
  </div>
  <div class="external-courses-content" *ngIf="externalCourses">
    <div class="row course-item" [id]="'course_'+course.id | encodeURI" *ngFor="let course of externalCourses">
      <div class="col-sm-2">
        <div class="thumbnail-container">
          <div class="thumbnail-image">
            <a [attr.href]="course.fileUrl" class="thumbnail-icon" target="_blank"><i class="fa fa-file-pdf-o"></i></a>
          </div>
        </div>
      </div>
      <!--<div class="col-sm-2 image-container clickable" (click)="onCourseDetails(course)">
           <pdf-viewer
          [src]="'http://dev-cf-lite.ad.simulware.net/data/generali/lms/classes/data_1/kotlin-docs.pdf'"
          [page]="2"
          [original-size]="false"
          [show-all]="false"
          style="display: block;"
          ></pdf-viewer>
      </div>-->
      <div class="col-sm-8 details-container">
        <div class="course-title size-1 brand-color clickable" (click)="onCourseDetails(course)">{{course.title}}</div>
        <div [innerHTML]="course.description"></div>
        <div class="details-bottom">
          <a (click)="onCourseDetails(course)" class="coursedetails clickable"><i class="fa fa-info-circle"></i> <span
              translate>Details</span></a>
        </div>
      </div>
      <div class="col-sm-2 buttons-container">
        <!-- <div *ngIf="course.duration" class="course-duration-x">Durata: {{course.duration}}</div> -->
        <!-- <button class="btn btn-primary clickable" (click)="removeCourse(course)"><i class="fa fa-remove"></i> <span translate>remove</span></button> -->
        <span *ngIf="course.status === 'APPROVED'" class="course-approved"><i class="fa fa-check"></i> <span translate>approved</span></span>
        <span *ngIf="course.status === 'REJECTED'" class="course-rejected"><i class="fa fa-thumbs-down"></i> <span
            translate>rejected</span></span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="externalCourses && externalCourses.length === 0" class="alert alert-info">
  <span translate>No external course available</span>
</div>

<ng-template #externalCourseAddModalTemplate>
  <div class="modal-header">
    <h5 class="modal-title pull-left" translate>New</h5>
    <button type="button" class="close pull-right" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="model">
    <form name="form" role="form" (ngSubmit)="f.form.valid && save()" #f="ngForm" novalidate>
      <div class="form-group">
        <label for="courseTitle"><span translate>course title</span></label>
        <input type="text" name="courseTitle" class="form-control" [(ngModel)]="model.title" required>
      </div>
      <div class="form-group">
        <label for="courseDescr"><span translate>course description</span></label>
        <input type="text" name="courseDescr" class="form-control" [(ngModel)]="model.description" required>
      </div>
      <div class="row">
        <div class="col-xs-12 col-12 col-md-4 form-group">
          <label for="courseDuration"><span translate>course duration</span></label>
          <timepicker
          	[(ngModel)]="model.durationDate" required
          	[ngModelOptions]="{standalone: true}" id="courseDuration"
            [showMeridian]="false"
            [readonlyInput]="false"
            [showSpinners]="true"></timepicker>
        </div>
        <div class="col-xs-12 col-12 col-md-4 form-group">
          <label for="courseDateFrom"><span translate>course date start</span></label>
          <input type="text" name="courseDate" id="courseDateFrom" class="form-control" #dp="bsDatepicker" bsDatepicker [bsValue]="bsValue1"
            [(ngModel)]="model.trainingDateFrom" required [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }">
        </div>
        <div class="col-xs-12 col-12 col-md-4 form-group">
          <label for="courseDateTo"><span translate>course date end</span></label>
          <input type="text" name="courseDate" id="courseDateTo" class="form-control" #dp="bsDatepicker" bsDatepicker [bsValue]="bsValue2"
            [(ngModel)]="model.trainingDateTo" required [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }">
        </div>
      </div>
      <div class="form-group">
        <label for="courseDocument"><span translate>course attachment</span></label>
        <div class="input-group mb-3">
          <input type="text" class="form-control" name="courseDocument" aria-describedby="basic-addon3" [(ngModel)]="model.fileName">
          <div class="input-group-append">
            <span class="btn input-group-text btn-file"><span translate>browse file</span><input type="file" (change)="onFileChange($event)"
                accept="*.pdf">
            </span>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="!(f.form.valid && f.form.touched)">
        <span translate>Confirm</span>
      </button>
      <button class="btn" (click)="onModalCancel($event)">
        <span translate>Cancel</span>
      </button>
    </form>
  </div>
</ng-template>
