import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {forkJoin} from 'rxjs';
import {DynamicPath, IGraphConfig, PathElement} from 'ngx-sep-graph-builder';
import {dia} from 'jointjs';
import CellView = dia.CellView;
import Cell = dia.Cell;
import * as _ from 'lodash';
import {DomSanitizer} from '@angular/platform-browser';
import {map} from 'rxjs/operators';
import {AuthenticationService} from '../../authentication/service';
import {LocalStorageService} from '../../storage/local/service';
import {Path} from './model';
import {AppEnvService} from '../../config/env/service';
import {Course} from '../courses/model';
import {User} from '../../user/model';
import {ScormCourse} from '../scorm/course/model';
import {LmsPath} from '@shared/app/lms/paths/store/model';


@Injectable()
export class PathService {

  labels: Object;
  path: DynamicPath;

  public unitHasBeenClosed = new EventEmitter<any>();

  constructor(
    private http: HttpClient, private translateService: TranslateService, private envService: AppEnvService,
    private sanitizer: DomSanitizer, private authService: AuthenticationService, private localStorageService: LocalStorageService
  ) {
    this.labels = {};
    forkJoin(
      this.translateService.get('started'),
      this.translateService.get('completed'),
      this.translateService.get('not started'),
      this.translateService.get('failed')
    ).subscribe(t => {
      this.labels['incomplete'] = t[0];
      this.labels['completed'] = t[1];
      this.labels['notattempted'] = t[2];
      this.labels['failed'] = t[3];
    });
  }

  getPaths(): Observable<Path[]> {
    return this.http.get<Path[]>(this.envService.config().api.paths);
  }

  getPath(pathId: number): Observable<Path> {
    const url = `${this.envService.config().api.paths}/${pathId.toString()}`;
    return this.http.get<Path>(url).pipe(map(path => {
      if (path.state !== undefined) {
        if (path.state.toLowerCase() === 'completed') {
          path.statusView = 'complete';
          path.statusLabel = this.labels['completed'];
        } else if (path.state.toLowerCase() === 'not attempted') {
          path.statusView = 'notattempted';
          path.statusLabel = this.labels['notattempted'];
        } else if (path.state.toLowerCase() === 'incomplete') {
          path.statusView = 'incomplete';
          path.statusLabel = this.labels['incomplete'];
        } else {
          path.statusView = path.state.toLowerCase();
        }
      } else {
        path.statusView = 'notattempted';
        path.statusLabel = this.labels['notattempted'];
      }
      if (path.image === undefined || path.image === '') {
        path.image = 'assets/img/path-cover-default.jpg';
      }
      if (path.courses !== undefined) {
        for (const c of path.courses) {
          if (c.state !== undefined) {
            if (c.state.toLowerCase() === 'completed') {
              c.statusView = 'complete';
            } else if (c.state.toLowerCase() === 'not attempted') {
              c.statusView = 'notattempted';
            } else {
              c.statusView = c.state.toLowerCase();
            }
          } else {
            c.statusView = 'notattempted';
          }
        }
      }
      return path;
    }));
  }


  getPathView(pathP: LmsPath): Path {
    const path: LmsPath = pathP;
    if (path.state !== undefined) {
      if (path.state.toLowerCase() === 'completed') {
        path.statusView = 'complete';
        path.statusLabel = this.labels['completed'];
      } else if (path.state.toLowerCase() === 'not attempted' || path.state.toLowerCase() === 'not_attempted') {
        path.statusView = 'notattempted';
        path.statusLabel = this.labels['notattempted'];
      } else if (path.state.toLowerCase() === 'incomplete') {
        path.statusView = 'incomplete';
        path.statusLabel = this.labels['incomplete'];
      } else {
        path.statusView = path.state.toLowerCase();
      }
    } else {
      path.statusView = 'notattempted';
      path.statusLabel = this.labels['notattempted'];
    }
    if (path.image === undefined || path.image === '') {
      path.image = 'assets/img/path-cover-default.jpg';
    }
    if (path.courses !== undefined) {
      for (const c of path.courses) {
        if (c.state !== undefined) {
          if (c.state.toLowerCase() === 'completed') {
            c.statusView = 'complete';
          } else if (c.state.toLowerCase() === 'not attempted') {
            c.statusView = 'notattempted';
          } else {
            c.statusView = c.state.toLowerCase();
          }
        } else {
          c.statusView = 'notattempted';
        }
      }
    }
    return path as unknown as Path;
  }

  getStatefulPath(path) {

    path.statusView = 'incomplete';
    path.statusLabel = this.labels['incomplete'];

    const config = JSON.parse(path.json) as IGraphConfig;
    for (const [key, value] of Object.entries(path.dynamicState.blocks)) {
      const _elemConfig = config.cells.filter(u => u.id === key);
      if (_elemConfig && _elemConfig.length > 0) {
        _elemConfig[0]['sep']['status'] = (value as PathElement).status;
        _elemConfig[0]['sep']['enabled'] = (value as PathElement).enabled;
        if ((value as PathElement).type === 'end' && (value as PathElement).enabled) {
          path.statusView = 'complete';
          path.statusLabel = this.labels['completed'];
        }
      }
    }
    const dynamicPath = new DynamicPath(config);
    for (const [key, value] of Object.entries(path.dynamicState.blocks)) {
      const _elem = dynamicPath.elements.find(u => u.id === key);
      if (_elem) {
        _elem.enabled = (value as PathElement).enabled;
        _elem.status = (value as PathElement).status;
      }
    }

    dynamicPath.id = path.id;
    this.path = dynamicPath;
    return this.path;
  }

  public getIdCourse(cell: CellView): number {
    return cell && cell.model as Cell | CellView && cell.model.id ? cell.model.attributes.sep.idCourse : null;
  }

  getCourse(courseId: number): Observable<Course> {
    const url = `${this.envService.config().api.courses}/${courseId.toString()}`;
    return this.http.get<Course>(url).pipe(map(c => {
      if (c.resources !== undefined) {
        for (const r of c.resources) {
          if (r.state !== undefined) {
            if (r.state.toLowerCase() === 'completed') {
              r.statusView = 'complete';
            } else if (r.state.toLowerCase() === 'not attempted') {
              r.statusView = 'notattempted';
            } else {
              r.statusView = r.state.toLowerCase();
            }
          } else {
            r.statusView = 'notattempted';
          }
        }
      }
      return c;
    }));
  }

  xApiUrl(xApiUrl: string, user: User) {
    /*let username = 'g_diego';
      let idUser = '292532';*/
    const username = user.username;
    const idUser = user.id;
    const url = `${xApiUrl}?endpoint=${this.envService.config().lrs.url}&auth=${this.envService.config().lrs.auth}&actor={"name":["${username}"],"mbox":["mailto:${idUser}@simulware.com"]}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  scormUrl(course: Course, user: User) {
    const _resource = course.resources[0];
    const courseScorm = new ScormCourse();
    Object.assign(courseScorm, _resource.metadata);
    courseScorm.resourceId = _resource.id;
    courseScorm.courseId = _resource.courseId;
    courseScorm.pathId = _resource.pathId;
    courseScorm.catalogId = _resource.catalogId;
    courseScorm.duration = _resource.duration;
    const unit = courseScorm.modules[0].units[0];
    let params = '?unit=' + unit.id;
    params += '&pathId=' + _resource.pathId + '&credits=' + _resource.duration + '&courseId=' + _resource.courseId + '&resourceId=' + _resource.id + '&userId=' + user.id + '&catalogId=' + _resource.catalogId + '&class=' + _resource.courseId + '&token=' + this.localStorageService.get(LocalStorageService.OAuthTokenKeyName);
    +'&debug=1';
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.envService.config().scorm.viewerUrl + params);
  }

}
