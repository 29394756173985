import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'encodeURI'
})
export class EncodeURIPipe implements PipeTransform {

    constructor() { }

    transform(url: string): string{
        return encodeURI(url);
    }
}
