import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {filter, map, merge, switchMap} from "rxjs/operators";
import {Observable, Subscription} from "rxjs";
import {of} from "rxjs";
import {zip} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {Path} from "../../../paths/model";
import {ThemeArea} from "../../../themeAreas/model";
import {CourseService} from "../../course.service";

@Component({
  selector: 'app-courses-by-theme-area',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class CoursesByThemeAreaComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  allThemeAreas: ThemeArea[] = [];
  selectedTheme: ThemeArea;
  paths: Path[] = [];
  viewMode: string = 'PATH_AREAS';
  banner: {
    title: string,
    description: string
  };

  constructor(
    private courseService: CourseService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {

  }

  private loadAllThemeAreas = (): Observable<ThemeArea[]> =>
    this.allThemeAreas!.length ? of(this.allThemeAreas) : this.courseService.getAllAreas();

  private loadPathsByAreaId = (areaId) => zip(
    this.loadAllThemeAreas(),
    this.courseService.getMyPaths(areaId)
  ).pipe(
    map(([areas, paths]) => {
      this.selectedTheme = areas.find((area) => area.id == areaId);
      this.paths = paths.filter((path) => path.themeId == areaId);
      this.viewMode = 'AREA_PATHS';
    })
  );

  ngOnInit(): void {

    const initializeBanner = zip(
      this.translateService.get('My courses'),
      this.translateService.get('Browse the course you\'re enrolled in, and access both mandatory and elective learning materials.')
    ).pipe(
      map(([title, description]) => this.banner = {title, description})
    );

    const loadByThemeArea = this.route.queryParams.pipe(
      filter(params => !!params['a']),
      switchMap((params) => this.loadPathsByAreaId(params['a']))
    );

    const loadAllAreas = this.route.queryParams.pipe(
      filter(params => !params['a']),
      switchMap(() => zip(this.loadAllThemeAreas(), this.courseService.getMyPaths())
        .pipe(map(([areas, paths]) => {
          this.allThemeAreas = areas.filter(a => paths.map(p => p.themeId).includes(a.id));
          this.viewMode = 'PATH_AREAS';
        }))
      )
    );

    this.subscriptions.push(
      initializeBanner.subscribe(),
      loadByThemeArea.pipe(merge(loadAllAreas)).subscribe()
    );

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  selectArea(area: ThemeArea) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: {
        a: area.id
      }
    });

  }

  openPath(path: Path) {
    this.router.navigate(['path', path.id]).then();
  }
}
