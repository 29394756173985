import {ScormModule} from '../module/model';
import {ScormClass} from '../class/model';
import {ResourcePathAware} from '../../resources/model';
import {ScormUnit} from '../unit/model';

export class ScormCourse extends ResourcePathAware {
  id: number;
  title: string;
  description: string;
  state: string;
  modules: ScormModule[];
  class: ScormClass;
  cover: string;
  duration: number;
  locked: boolean;
  flatUnits: ScormUnit[];
  imageUrl: string;
}
