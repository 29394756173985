<div class="profile-container page-template-thin">
    <div class="row" *ngIf="user | async as user">
        <div class="col-md-5">
            <div class="row">
                <div class="col-sm-12">
                    <div class="panel panel-profile">
                        <div class="panel-heading bg-main fg-1 clearfix">
                            <a href="" class="pull-left profile">
                                <div class="user-info-avatar" [style.backgroundImage]="'url('+ user.imageUrl +')'">
                                    <a *ngIf="!isDefaultImageUrl(user.imageUrl)"
                                       [routerLink]=""
                                       (click)="deletePicture($event)" class="delete">
                                        <i class="icon fa fa-trash delete-profile-image"></i>
                                    </a>
                                </div>
                            </a>
                            <h3>
                                {{user.firstName + " " + user.lastName}}
                            </h3>
                            <p>
                                {{user.level}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="authState | async as auth" class="row">
                <div class="col-sm-12">
                    <div class="panel panel-default manage-account">
                        <div class="panel-body">
                            <image-upload *ngIf="isDefaultImageUrl(user.imageUrl)"
                                          [style]="customStyle"
                                          [max]="1"
                                          [url]="profileImgUploadUrl"
                                          [maxFileSize]="1048576"
                                          [headers]="{'Authorization': auth.access_token}"
                                          [partName]="'picture'"
                                          [buttonCaption]="translations['Select image']"
                                          (uploadFinished)="onUploadFinished($event)"
                                          [dropBoxMessage]="translations['or drop it here']"
                                          [preview]="false">
                            </image-upload>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="mt-3 col-sm-12">
                    <div class="panel panel-default" [hidden]="changePasswordVisible">
                        <div class="panel-body">
                            <button class="btn btn-info btn-block" (click)="changePasswordVisible = true">
                                <i class="icon fa fa-key user-profile-icon"></i>&nbsp;
                                <span translate>Change password</span>
                            </button>
                        </div>
                    </div>
                    <div class="panel panel-default manage-account" [hidden]="!changePasswordVisible">
                        <div class="panel-heading">
                            <strong>
                                <span translate>Manage change password</span>
                            </strong>
                        </div>
                        <div class="panel-body">
                            <form [formGroup]="form" (ngSubmit)="onChangePassword($event)">
                                <div class="form-group">
                                    <label for="currentPassword"><span translate>Current password</span></label>
                                    <input type="password" id="currentPassword" class="form-control"
                                           formControlName="currentPassword" [formGroup]="form">
                                </div>
                                <div class="form-group">
                                    <label for="newPassword"><span translate>New password</span></label>
                                    <input type="password" id="newPassword" class="form-control"
                                           formControlName="newPassword" [formGroup]="form">
                                </div>
                                <div class="form-group">
                                    <label for="confirmPassword"><span translate>Confirm password</span></label>
                                    <input type="password" id="confirmPassword" class="form-control"
                                           formControlName="confirmPassword" [formGroup]="form">
                                </div>
                                <div class="alert alert-danger"
                                     *ngIf="form.controls['confirmPassword'].errors?.matchPassword">
                                    <span translate>Different password message</span>
                                </div>
                              {{(passwordChangeState | async).status}}
                                <div class="alert alert-danger" *ngIf="(passwordChangeState | async).status ">
                                    {{(passwordChangeState | async).error_description}}
                                </div>
                                <button type="submit" class="btn btn-primary">
                                    <span translate>Confirm</span>
                                </button>
                                <button class="btn btn" (click)="closeChangePassword()">
                                    <span translate>Cancel</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="mt-3 col-sm-12">
                    <div class="panel panel-default">
                        <div class="panel-body">
                            <button class="btn btn-info btn-block" (click)="onExternalCoursesButtonClick($event)">
                                <i class="icon fa fa-link"></i>&nbsp;
                                <span translate>External training</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-7">

            <div class="panel panel-default panel-right">

                <tabset>
                    <tab heading="{{'Personal data' | translate}}">

                        <div class="panel-body">
                            <div class="media">
                                <div class="media-body">
                                    <ul class="list-unstyled list-info">
                                        <li>
                                            <span class="icon fa fa-user user-profile-icon"></span>
                                            <label><span translate>Name</span>:</label>
                                            {{user.firstName}} {{user.lastName}}
                                        </li>
                                        <li [appCustomField]="'matricola'">
                                            <span class="icon fa fa-barcode user-profile-icon"></span>
                                            <label><span translate>Matricola</span>:</label>
                                            {{user.matricola}}
                                        </li>
                                        <li [appCustomField]="'gender'">
                                            <span class="icon fa fa-genderless user-profile-icon"></span>
                                            <label><span translate>Gender</span>:</label>
                                            <span *ngIf="user.gender === 'M'" translate>Male</span>
                                            <span *ngIf="user.gender === 'F'" translate>Female</span>
                                        </li>
                                        <li [appCustomField]="'codiceFiscale'">
                                            <span class="icon fa fa-id-card user-profile-icon"></span>
                                            <label><span translate>Codice fiscale</span>:</label>
                                            {{user.codiceFiscale}}
                                        </li>
                                        <li>
                                            <span class="icon fa fa-language user-profile-icon"></span>
                                            <label><span translate>Language</span>:</label>
                                            <span *ngIf="!selectLang">{{getLanguageName(user.preferences.cultureCode)}}</span>
                                            <span *ngIf="!selectLang">&nbsp;<a
                                                    (click)="onLangEditClick($event, user.preferences.cultureCode)"
                                                    class="fa fa-edit clickable small"
                                                    tooltip="{{'edit' | translate}}"></a></span>
                                            <select *ngIf="selectLang" class="form-control"
                                                    [ngModel]="userPreferences.cultureCode"
                                                    (change)="onLanguageChange($event)">
                                                <option *ngFor="let lang of languages"
                                                        [value]="lang.code">{{lang.name}}</option>
                                            </select>
                                        </li>
                                        <li>
                                            <span class="icon fa fa-globe user-profile-icon"></span>
                                            <label><span translate>Time zone</span>:</label>
                                            <span *ngIf="!user.preferences.countryCode" class="font-italic" translate>not set</span>
                                            <span *ngIf="!selectTZ && user.preferences.countryCode">{{user.preferences.countryCode.zone}}</span>
                                            <span *ngIf="!selectTZ">&nbsp;<a (click)="selectTZ = true"
                                                                             class="fa fa-edit clickable small"
                                                                             tooltip="{{'edit' | translate}}"></a></span>
                                            <span *ngIf="selectTZ">
										<ng2-timezone-picker
                                                (change)="onTimezoneChange($event)"
                                                placeholder="{{'Select' | translate}}" showOffset="true" guess="false">
										</ng2-timezone-picker>
									</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </tab>
                    <tab heading="{{'User data' | translate}}">

                        <div class="panel-body">
                            <div class="media">
                                <div class="media-body">
                                    <ul class="list-unstyled list-info">
                                        <li [appCustomField]="'username'">
                                            <span class="icon fa fa-user-circle user-profile-icon"></span>
                                            <label><span translate>Username</span>:</label>
                                            {{user.username}}
                                        </li>
                                        <li [appCustomField]="'email'">
                                            <span class="icon fa fa-envelope user-profile-icon"></span>
                                            <label><span translate>Email</span>:</label>
                                            {{user.email}}
                                        </li>
                                        <li [appCustomField]="'status'">
                                            <span class="icon fa fa-circle-o user-profile-icon"></span>
                                            <label><span translate>State</span>:</label>
                                            <span *ngIf="user.lmsUserActive === true" translate>Active</span>
                                            <span *ngIf="user.lmsUserActive === false" translate>Not active</span>
                                        </li>
                                        <li [appCustomField]="'company'">
                                            <span class="icon fa fa-circle-o user-profile-icon"></span>
                                            <label><span translate>Company</span>:</label>
                                            {{user.company}}
                                        </li>
                                        <li [appCustomField]="'direction group'">
                                            <span class="icon fa fa-circle-o user-profile-icon"></span>
                                            <label><span translate>Direction group</span>:</label>
                                            {{user.direction_name}}
                                        </li>
                                        <li [appCustomField]="'role'">
                                            <span class="icon fa fa-circle-o user-profile-icon"></span>
                                            <label><span translate>Role</span>:</label>
                                            {{user.roles}}
                                        </li>
                                        <li [appCustomField]="'level'">
                                            <span class="icon fa fa-circle-o user-profile-icon"></span>
                                            <label><span translate>SeP Level</span>:</label>
                                            {{user.level}}
                                        </li>
                                        <li [appCustomField]="'lms_evel'">
                                            <span class="icon fa fa-circle-o user-profile-icon"></span>
                                            <label><span translate>LMS Level</span>:</label>
                                            {{user.lmsUserLevel}}
                                        </li>
                                        <!-- <li>
                                           <span class="icon fa fa-mobile user-profile-icon"></span>
                                           <label><span translate>Mobile phone</span>:</label>
                                           {{user.mobile}}
                                         </li>
                                         <li>
                                           <span class="icon fa fa-phone user-profile-icon"></span>
                                           <label><span translate>Office phone</span>:</label>
                                           {{user.phone}}
                                         </li>-->
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </tab>
                    <tab heading="{{'Working data' | translate}}">

                        <div class="panel-body">
                            <div class="media">
                                <div class="media-body">
                                    <ul class="list-unstyled list-info">
                                        <li [appCustomField]="'Rui code'">
                                            <span class="icon fa fa-circle-o user-profile-icon"></span>
                                            <label><span translate>Rui code</span>:</label>
                                            {{user.codeRui}}
                                        </li>
                                        <li [appCustomField]="'ruiEnrollmentDate'">
                                            <span class="icon fa fa-calendar user-profile-icon"></span>
                                            <label><span translate>Rui enrollment date</span>:</label>
                                            {{user.ruiEnrollmentDate | date :  "dd/MM/y"}}
                                        </li>
                                        <li [appCustomField]="'userJob'">
                                            <span class="icon fa fa-circle-o user-profile-icon"></span>
                                            <label><span translate>Work type</span>:</label>
                                            {{user.job}}
                                        </li>
                                        <li [appCustomField]="'businessUnit'">
                                            <span class="icon fa fa-circle-o user-profile-icon"></span>
                                            <label><span translate>Business unit</span>:</label>
                                            {{user.business_unit}}
                                        </li>
                                        <li [appCustomField]="'businessGroup'">
                                            <span class="icon fa fa-circle-o user-profile-icon"></span>
                                            <label><span translate>Business group</span>:</label>
                                            {{user.business_group}}
                                        </li>
                                        <li [appCustomField]="'activityGroup'">
                                            <span class="icon fa fa-circle-o user-profile-icon"></span>
                                            <label><span translate>Activity group</span>:</label>
                                            {{user.activity_group}}
                                        </li>
                                        <li [appCustomField]="'contractLevel'">
                                            <span class="icon fa fa-circle-o user-profile-icon"></span>
                                            <label><span translate>Contract type</span>:</label>
                                            {{user.contractLevel}}
                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>

                    </tab>
                    <tab heading="{{'Addresses' | translate}}">
                        <div class="panel-body">
                            <div class="media">
                                <div class="media-body">
                                    <ul class="list-unstyled list-info">
                                        <li [appCustomField]="'Home address 1'">
                                            <span class="icon fa fa-home user-profile-icon"></span>
                                            <label><span translate>Home address 1</span>:</label>
                                            {{user.home_addr_address1}}
                                        </li>
                                        <li [appCustomField]="'Home address 2'">
                                            <span class="icon fa fa-home user-profile-icon"></span>
                                            <label><span translate>Home address 2</span>:</label>
                                            {{user.home_addr_address2}}
                                        </li>
                                        <li [appCustomField]="'Home address 3'">
                                            <span class="icon fa fa-home user-profile-icon"></span>
                                            <label><span translate>Home address 3</span>:</label>
                                            {{user.home_addr_address3}}
                                        </li>
                                        <li [appCustomField]="'Home postal code'">
                                            <span class="icon fa fa-map-pin user-profile-icon"></span>
                                            <label><span translate>Home postal code</span>:</label>
                                            {{user.home_addr_postcode}}
                                        </li>
                                        <li [appCustomField]="'Home country'">
                                            <span class="icon fa fa-circle-o user-profile-icon"></span>
                                            <label><span translate>Home country</span>:</label>
                                            {{user.home_addr_country}}
                                        </li>
                                        <li [appCustomField]="'Home state'">
                                            <span class="icon fa fa-flag user-profile-icon"></span>
                                            <label><span translate>Home state</span>:</label>
                                            {{user.home_addr_state}}
                                        </li>
                                        <li [appCustomField]="'Home other'">
                                            <span class="icon fa fa-circle-o user-profile-icon"></span>
                                            <label><span translate>Home other</span>:</label>
                                            {{user.home_addr_other}}
                                        </li>

                                        <li>

                                            <label><span></span></label>

                                        </li>

                                        <li [appCustomField]="'Office address 1'">
                                            <span class="icon fa fa-building user-profile-icon"></span>
                                            <label><span translate>Office address 1</span>:</label>
                                            {{user.office_addr_address1}}
                                        </li>
                                        <li [appCustomField]="'Office address 2'">
                                            <span class="icon fa fa-building user-profile-icon"></span>
                                            <label><span translate>Office address 2</span>:</label>
                                            {{user.office_addr_address2}}
                                        </li>
                                        <li [appCustomField]="'Office address 3'">
                                            <span class="icon fa fa-building user-profile-icon"></span>
                                            <label><span translate>Office address 3</span>:</label>
                                            {{user.office_addr_address3}}
                                        </li>
                                        <li [appCustomField]="'Office postal code'">
                                            <span class="icon fa fa-map-pin user-profile-icon"></span>
                                            <label><span translate>Office postal code</span>:</label>
                                            {{user.office_addr_postCode}}
                                        </li>
                                        <li [appCustomField]="'Office country'">
                                            <span class="icon fa fa-circle-o user-profile-icon"></span>
                                            <label><span translate>Office country</span>:</label>
                                            {{user.office_addr_country}}
                                        </li>
                                        <li [appCustomField]="'Office state'">
                                            <span class="icon fa fa-flag user-profile-icon"></span>
                                            <label><span translate>Office state</span>:</label>
                                            {{user.office_addr_state}}
                                        </li>
                                        <li [appCustomField]="'Office other'">
                                            <span class="icon fa fa-circle-o user-profile-icon"></span>
                                            <label><span translate>Office other</span>:</label>
                                            {{user.office_addr_other}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </tab>
                </tabset>
            </div>


        </div>
    </div>
</div>

