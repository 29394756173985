import {Injectable} from "@angular/core";
import {LocalStorageService} from "@shared/app/storage/local/service";
import {combineEpics, ofType} from "redux-observable";
import {LmsResourcesActions, ResourcesAction} from "@shared/app/lms/resources/store/actions";
import {LmsResourcesService} from "@shared/app/lms/resources/store/service";
import {catchError, startWith, switchMap} from "rxjs/operators";
import {of} from "rxjs";

@Injectable()
export class LmsResourcesEpics {
  constructor(
    private actions: LmsResourcesActions,
    private service: LmsResourcesService,
    private localStorageService: LocalStorageService) {

  }

  create = () => combineEpics(
    this.createOpenResourceEpic()
  );

  private openResource = (resourceId: number) =>
    this.service.getResource(resourceId).pipe(
      switchMap(resource => of(this.actions.resourceOpened(resource))),
      catchError((error) => of(this.actions.resourceOpenError(error))),
      startWith(this.actions.resourceOpening())
    )

  private createOpenResourceEpic = () =>
    (action$, state$) =>
      action$.pipe(
        ofType(LmsResourcesActions.RESOURCE_OPEN),
        switchMap((action: ResourcesAction) => this.openResource(action.payload)),
      );
}
