<app-banner *ngIf="course" [display]="'thin'" [title]="course.title" [backgroundImage]="'assets/img/course-banner.jpg'" [colorSchema]="'light'"></app-banner>

<div class="external-course-container page-template-thin">

  <div [spinner]="loading"></div>
  <div class="row" *ngIf="course">
    <div class="col-md-12 col-sm-12">
      <div class="external-course-info-box box-1">
        <div class="external-course-details">
          <div class="external-course-details-row">
            <strong><i class="fa fa-calendar"></i> <span translate>Date</span>:</strong>
            <span>{{course.trainingDateFrom | date:'dd/MM/yyyy'}} - {{course.trainingDateTo | date:'dd/MM/yyyy'}}</span>
          </div>
          <div class="external-course-details-row">
            <strong>
              <i class="fa fa-clock-o"></i> <span translate>duration</span>:
              <span>
                <span [appDuration]="course.duration" [format]="'minutes'"></span>
              </span>
            </strong>
            <span></span>
          </div>
          <div class="external-course-details-row">
            <strong>
              <i class="fa fa-thumbs-up"></i> <span translate>Status</span>:
              <span *ngIf="course.status === 'APPROVED'" class="course-approved"><i class="fa fa-check"></i> <span translate>approved</span></span>
              <span *ngIf="course.status === 'REJECTED'" class="course-rejected"><i class="fa fa-thumbs-down"></i> <span translate>rejected</span></span>
            </strong>
          </div>
          <!-- <div class="external-course-details-row">
              <button class="btn btn-primary clickable" *ngIf="path.enrolled === true && path.totalCourses > 1" (click)="goToPath(path)"><i class="fa fa-caret-right"></i> <span translate>go to path</span></button>
              <button class="btn btn-primary clickable" *ngIf="path.enrolled === true && path.totalCourses === 1" (click)="goToPath(path)">
                <i class="fa fa-caret-right"></i>
                <span translate>go to course</span>
              </button>
            </div> -->
        </div>
      </div>
      <div class="external-course-info-box box-2">
        <div class="external-course-description">
          <strong translate>Description</strong>
          <p [innerHTML]="course.description"></p>
        </div>
      </div>
      <div class="external-course-info-box box-2">
        <div class="external-course-description">
          <strong translate>File</strong>
          <a [attr.href]="course.fileUrl" target="_blank">documento</a>
        </div>
      </div>
      <div class="external-course-info-box box-2">
        <button class="btn btn-primary clickable" (click)="onBackToExternalCourses()"><i class="fa fa-caret-right"></i>
          <span translate>back to list</span></button>
      </div>


    </div>

    <div class="col-md-8 col-sm-12">
      <div class="external-courses-container">
      </div>
    </div>

    <div *ngIf="errorMessage" class="alert alert-danger">{{errorMessage}}</div>
  </div>
