import {Component, OnInit} from '@angular/core';
import {select} from "@angular-redux/store";
import {Observable} from "rxjs";
import {ThemeState} from "../../theme/store/reducers";
import {filter, map} from "rxjs/operators";

@Component({
  moduleId: module.id,
  selector: 'app-custom-message',
  templateUrl: 'custom-message.component.html',
  styleUrls: ['./custom-message.component.scss']
})

export class CustomMessageComponent implements OnInit {

  @select('theme')
  readonly themeState: Observable<ThemeState>;

  private nobis = false;

  constructor() {
  }

  ngOnInit() {
    this.nobis = false;
    this.themeState.pipe(
      filter(state => !!state.config),
      map(
        t => {
          if (t.config.name === 'nobis') {
            this.nobis = true;
          }
        }));
  }

}
