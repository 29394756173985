import {MissingTranslationHandler, MissingTranslationHandlerParams} from "@ngx-translate/core";

export class CustomMissingTranslationHandler extends MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
  }
}

export function AppMissingTranslationHandler() {
  return new CustomMissingTranslationHandler();
}
