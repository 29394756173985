import {DeviceInfo} from "ngx-device-detector";

export interface AppEnv {
  production?: boolean
}

export class EnvironmentDetails{

  deviceInfo: DeviceInfo;
  platform: string;
  url: string;
  page: string;
  localStorage: any;
  username: string;

}
