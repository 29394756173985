import {LmsCourse} from './model';
import {Action} from 'redux';
import {CoursesAction, LmsCoursesActions} from './actions';
import {LmsResource} from '../../model';


export interface LmsCoursesCompletion {
  overallDuration: number;
  completedByUser: number;
  percent: number;
}

export interface LmsCourseState {
  openError: any;
  opening: boolean;
  opened: LmsCourse;
  resources: LmsResource[];
}

export interface CoursesState {
  list: Array<LmsCourse>;
  current: LmsCourseState;
  lockedResourceId: string;
  statistics: LmsCoursesCompletion;
}

const INITIAL_STATE: CoursesState = {
  list: undefined,
  current: undefined,
  lockedResourceId: undefined,
  statistics: undefined
};

export const coursesReducer = () => (state: CoursesState = INITIAL_STATE, action: Action): CoursesState => {
  switch (action.type) {

    case LmsCoursesActions.COURSE_OPENING:
      return {
        ...state,
        current: {...state.current, opening: true}
      };
    case LmsCoursesActions.COURSE_OPEN_FAILED:
      return {
        ...state,
        current: {...state.current, opening: false, openError: {...(action as CoursesAction<any>).payload}},
      };
    case LmsCoursesActions.COURSE_OPENED:
      const course = (action as CoursesAction<LmsCourse>).payload;
      return {
        ...state,
        current: {...state.current, opening: false, opened: course, resources: course.resources}
      };
    case LmsCoursesActions.COURSE_LOCKED:
      return {
        ...state,
        current: {...state.current},
        lockedResourceId: (action as CoursesAction<string>).payload
      };
    case LmsCoursesActions.COURSE_UNLOCKED:
      return {
        ...state,
        lockedResourceId: undefined
      };
    case LmsCoursesActions.COURSES_LOAD:
      return {
        ...state,
        current: undefined
      };
    case LmsCoursesActions.COURSES_LOAD_FAILED:
      return {
        ...state,
        statistics: undefined
      };
    case LmsCoursesActions.COURSES_LOADED:
      const courses = (action as CoursesAction<Array<LmsCourse>>).payload;
      const overallDuration = courses.flatMap(c => c.statistics.resources).reduce((total, res) => total += res.duration, 0);
      const completedByUser = courses.flatMap(c => c.statistics.resources).filter(res => res.status === 'COMPLETED').reduce((total, res) => total += res.duration, 0);

      /////////////////////////////////////////////////////////////////////////////////////
      // Soluzione temporanea per la getsione del locked, in attesa di soluzione lato BE //
      /////////////////////////////////////////////////////////////////////////////////////

        //courses.map(x => x.orderMandatory ? x.locked = true : x.locked = true);

        for (let i = 0; i < courses.length; i++) {

          const element = courses[i];
          if(i != 0 ) {
            if(!element.orderMandatory) {element.locked = false} else {
              for (let j = 0; j < i; j++) {
                const element2 = courses[j];
                if(element2.state != 'COMPLETED') {
                  element.locked = true
                } else {
                  element.locked = false
                }
              }
            }
          }
        }


      /////////////////////////////////////////////////////////////////////////////////////

      const percent = completedByUser * 100 / overallDuration ? completedByUser * 100 / overallDuration : 0;
      return {
        ...state,
          list: courses,
        lockedResourceId: undefined,
        statistics: {overallDuration, completedByUser, percent}
      };
  }
  return state;
};

