import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthActions} from '@shared/app/authentication/store/actions';
import {map} from 'rxjs/operators';
import {filter} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {select} from '@angular-redux/store';
import {AuthState} from '@shared/app/authentication/store/reducers';

@Component({
  template: ''
})
export class AdministrativeUserImpersonationComponent implements OnInit, OnDestroy {


  @select('auth')
  readonly authState: Observable<AuthState>;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authActions: AuthActions) {
  }

  ngOnInit() {
    this.subscriptions.push(this.route.params.pipe(
        map(params => params['userId']),
        filter(userId => !!userId && !isNaN(userId)),
        map(userId => this.authActions.impersonateLogin(parseInt(userId), 'admin'))
      ).subscribe(),
      this.authState.pipe(
        filter(authState => (true === authState?.authenticated) && (true === authState?.impersonating)),
        map(() => this.router.navigate(['/']))
      ).subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
