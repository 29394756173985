import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {TranslateService} from "@ngx-translate/core";
import {NotificationsService} from "angular2-notifications";
import {AuthActions} from "../../authentication/store/actions";
import {ActivatedRoute, Router} from "@angular/router";
import {UserActions} from "../../user/store/actions";
import {select} from "@angular-redux/store";
import {from, Observable, of, Subscription} from "rxjs";
import {UserState} from "../../user/model";
import {filter, flatMap, map} from "rxjs/operators";


@Component({
  selector: 'privacy-layout',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class PrivacyLayoutComponent implements OnInit, OnDestroy {


  @ViewChild('privacyTemplate', {static: true}) privacyTmpl: TemplateRef<any>;
  privacyModalRef: BsModalRef;

  @select('user')
  private userState: Observable<UserState>;

  subscriptions: Subscription[] = [];

  constructor(private modalService: BsModalService,
              private authActions: AuthActions,
              private translateService: TranslateService,
              private notificationService: NotificationsService,
              private userActions: UserActions,
              private route: ActivatedRoute,
              private router: Router,
  ) {
  }


  ngOnInit(): void {

    this.subscriptions.push(
      this.userState.pipe(
        filter(state => !!state && !!state.details),
        // filter(state => state.details.privacyChecked),
        flatMap((state) => {
            if (state.details.privacyChecked) {
              if (this.privacyModalRef) {
                this.privacyModalRef.hide();
              }
              //const routerState = this.activeRoute$.snapshot.
              const returnUrl = this.route.snapshot.queryParams['returnUrl'] || this.router.url;
              return from(this.router.navigate([returnUrl]))
            } else {
              this.privacyModalRef = this.modalService.show(this.privacyTmpl, {
                backdrop: true,
                ignoreBackdropClick: true,
                keyboard: false,
                class: 'modal-lg'
              })
            }
            return of(true)
          }
        )).subscribe());
  }

  declinePrivacy() {
    this.subscriptions.push(
    this.translateService.stream([
      'Sorry',
      'You must accept the privacy before accessing the application'])
      .pipe(
        map(t => {
          this.privacyModalRef && this.privacyModalRef.hide();
          const toast = this.notificationService.error(
            t['Sorry'],
            t['You must accept the privacy before accessing the application'],
            {timeOut: 5000, clickToClose: true});
          [toast.click, toast.timeoutEnd].forEach(event => event.subscribe(() => this.authActions.logoutUser()))
        })).subscribe());
  }

  acceptPrivacy() {

    this.userActions.acceptPrivacy();

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }


}
