import {TranslatePoHttpLoader} from "@biesbjerg/ngx-translate-po-http-loader";
import {HttpClient} from "@angular/common/http";
import * as gettext from 'gettext-parser';
import {MissingTranslationHandler, MissingTranslationHandlerParams, TranslateDefaultParser} from "@ngx-translate/core";


/***
 * modificato metodo parse per caricare tutti i contesti (oltre a quello di default '')
 */
export class CustomTranslatePoHttpLoader extends TranslatePoHttpLoader {

  constructor(_http: HttpClient, _prefix?: string, _suffix?: string) {
    super(_http,_prefix,_suffix);
  }

  public parse(contents: string): any {
    const translations: { [key: string]: string } = {};

    const po = gettext.po.parse(contents, 'utf-8');
    console.log('CustomTranslatePoHttpLoader', po);
    if (!po.translations.hasOwnProperty(this.domain)) {
      return translations;
    }

    const contexts = Object.keys(po.translations);
    for (const ctx of contexts) {
      Object.keys(po.translations[ctx]).forEach(key => {
        const translation: string = po.translations[ctx][key].msgstr.pop();
        let keyCtxAware: string;
        if (ctx !== '') {
          keyCtxAware = ctx + '|' + key;
        } else {
          keyCtxAware = key;
        }
        if (keyCtxAware.length > 0 && translation.length > 0) {
          translations[keyCtxAware] = translation;
        }
      });
    }
    return translations;
  }
}

export function AppTranslateLoader(http: HttpClient) {
  const basePath = 'assets';
//    return new TranslatePoHttpLoader(http, basePath + '/i18n', '.po');
  return new CustomTranslatePoHttpLoader(http, basePath + '/i18n', '.po');
}

export class InterpolatedTranslateParser extends TranslateDefaultParser {
  public templateMatcher: RegExp = /{\s?([^{}\s]*)\s?}/g;
}




