import {Injectable} from "@angular/core";
import {CourseService} from "../course.service";
import {AppEnvService} from "../../../config/env/service";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {LmsCourse} from "./model";
import {LmsPath} from "../../paths/store/model";
import {catchError, map, mergeMap} from "rxjs/operators";
import {Observable, of, throwError, zip} from "rxjs";
import {LocalStorageService} from "@shared/app/storage/local/service";

@Injectable()
export class LmsCoursesService {

  constructor(
    private courseService: CourseService,
    private envService: AppEnvService,
    private http: HttpClient
  ) {
  }

  getCourse = (id: number) => {
    const url = `${this.envService.config().api.courses}/${id}`;
    return this.http.get<LmsCourse>(url);
  }

  acquireLock = (resourceId: string): Observable<boolean> =>  this.http.post<boolean>(this.envService.config().api.used, { resourceId: resourceId })
  releaseLock = (): Observable<any> => this.http.delete(this.envService.config().api.used);
  checkLock = (): Observable<string> => this.http.get(this.envService.config().api.used).pipe(
    map(response => response['resourceId']),
    catchError((error: HttpErrorResponse) => error.status === 404 ? of(null) : throwError(error))
  )

  getPath = (id: number): Observable<LmsPath> => {
    const url = `${this.envService.config().api.paths}/${id}`;
    return this.http.get<LmsPath>(url);
  }

  loadCourses = () => {
    const url = `${this.envService.config().api.courses}/me`;
    return this.http.get<LmsPath[]>(url).pipe(
      mergeMap(paths => zip(...paths.map(path => this.getPath(path.id)))),
      map(paths => paths.flatMap(path => path.courses)),
    )

  }

}
