<div>
  <div class="modal-header">
    <h5 class="modal-title pull-left">{{course?.course_title}}</h5>&nbsp;
    <a class="btn btn-sm btn-primary btn-download" (click)="downloadCertificate()"><i
      class="fa fa-download">&nbsp;</i><span translate>download</span></a>
    <a class="btn close pull-right" (click)="closeCertificate()">
      <span aria-hidden="true">&times;</span>
    </a>
  </div>
  <div class="modal-body">
    <iframe [attr.src]="certificateSrc" type="application/pdf"></iframe>
  </div>
</div>
