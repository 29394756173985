import {Injectable} from "@angular/core";
import {combineEpics} from "redux-observable";
import {catchError, mergeMap, startWith, switchMap} from "rxjs/operators";
import {BootActions} from "./actions";
import {AppBootService} from "../service";
import {ThemeActions} from "../../theme/store/actions";
import {AuthActions} from "../../authentication/store/actions";
import {concat, of} from "rxjs";
import {ofType} from 'redux-observable';

@Injectable()
export class BootEpics {

  constructor(
    private service: AppBootService,
    private themeActions: ThemeActions,
    private authActions: AuthActions,
    private actions: BootActions) {
  }

  create = () => combineEpics(
    this.createBootEpic(),
  );

  private bootCompleted = (currToken, casProviders, themeConfig) => concat(
    !!currToken
      ? of(this.authActions.authenticationSucceeded({...currToken}))
      : of(this.authActions.authenticationFailed({error: 'No oAuth token found.'})),
    of(this.themeActions.loadCustomThemeSucceeded(themeConfig)),
    of(this.authActions.casProvidersLoaded(casProviders)),
    of(this.actions.bootCompleted())
  );

  private bootFailed = (err) => of(this.actions.bootFailed(err));

  private bootApp = () =>
    this.service.boot().pipe(
      mergeMap(([userAuth, casProviders, themeConfig]) => this.bootCompleted(userAuth, casProviders, themeConfig)),
      catchError(this.bootFailed),
      startWith(this.actions.bootStarted())
    );


  private createBootEpic = () =>
    (action$, state$) =>
      action$.pipe(
        ofType(BootActions.START_BOOT),
        switchMap(this.bootApp),
      );

}
