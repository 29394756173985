import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Directive({
  selector: '[appDuration]'
})
export class DurationDirective implements OnInit {

  @Input('appDuration')
  duration: number;

  @Input()
  format: string;

  @Input()
  showNumberIfNullOrZero: boolean;

  @Input()
  minutesSmall: number;


  constructor(
    el: ElementRef,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef) {
    this.format = 'hours';
  }

  ngOnInit() {
    this.minutesSmall = this.minutesSmall ? this.minutesSmall : 100;
    let returnString = '';
    if ((this.duration === undefined || this.duration === 0)) {
      returnString = this.showNumberIfNullOrZero === true ? '0' + FormatMap[this.format] : 'N/A';
    } else {
      switch (this.format) {
        case 'hours':
          if (this.duration >= 3600) {
            returnString = `${Math.floor(this.duration / 3600)}h `;
          }
          if (this.duration >= 60) {
            returnString = `<span style="font-size: ${this.minutesSmall}%">${Math.floor(this.duration / 60)}m </span>`;
          }
          if (this.duration % 60 > 0) {
            returnString += `<span style="font-size: ${this.minutesSmall}%">${this.duration % 60}s</span>`;
          }
          break;
        case 'minutes':
          if (this.duration >= 60) {
            returnString = `${Math.floor(this.duration / 60)}h `;
          }
          if (this.duration % 60 > 0) {
            returnString += `<span style="font-size: ${this.minutesSmall}%">${this.duration % 60}m</span>`;
          }
          break;
//              case 'days':
//                returnString = $filter('number')(this.duration / (60 * 24), 2);
//              break;
//              case 'workingDays':
//                returnString = $filter('number')(this.duration / (60 * 8), 2);
//              break;
//              case 'weeks':
//                returnString = $filter('number')(this.duration / (60 * 24 * 7), 2);
//              break;
//              case 'workingWeeks':
//                returnString = $filter('number')(this.duration / (60 * 8 * 5), 2);
//              break;
      }
    }

    this.elementRef.nativeElement.innerHTML = `<span class="sep-duration">${returnString}</span>`;
  }

}

export enum FormatMap {
  hours = 'h',
  minutes = 'm'
}
