import {NgModule} from "@angular/core";
import {BootEpics} from "./store/epics";
import {AppBootService} from "./service";
import {BootActions} from "./store/actions";
import {BootGuard} from "./guard";

@NgModule({
  providers: [BootEpics, AppBootService, BootActions, BootGuard]
})
export class AppBootModule {

}