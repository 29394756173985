import {Component, OnInit} from '@angular/core';
import {select} from "@angular-redux/store";
import {Observable} from "rxjs";
import {ThemeState} from "../../theme/store/reducers";
import {ICustomThemeConfig} from "../../theme/model";

@Component({
  selector: 'app-footer',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class FooterComponent implements OnInit {

  @select('theme')
  readonly themeState: Observable<ThemeState>;
  theme: ICustomThemeConfig;

  constructor() {
  }

  ngOnInit() {
    this.themeState.subscribe(t => {
      this.theme = t.config;
    });
  }

}

