
<div [spinner]="loading"></div>
<div class="resources-container" *ngIf="_course && courseResources">
   	<div class="resource-item" *ngFor="let resource of courseResources; let i = index">
   		<div class="resource-box">
            <div class="resource-box-status" [ngClass]="resource.statusView"></div>
			<div class="resource-box-text">
				<div class="resource-box-text-container">
					<div class="resource-box-title" tooltip="{{ resource.type | translate}}" [adaptivePosition]>
						<span>{{i+1}}. </span>
            <app-resources-icon [type]="resourceType[resource.type]" resize="60" lineHeight="225"></app-resources-icon>&nbsp;<span>{{resource.title}}</span>
            &nbsp;<i *ngIf="resource.locked" class="fa fa-exclamation-circle" tooltip="{{resource.lockReason | translate}}"></i>
					</div>
					<div class="resource-box-description">
						<span *ngIf="resource.metadata"> {{resource.metadata.title}}</span>
					</div>
				</div>
				<div class="resource-contents">
					<app-course-scorm *ngIf="resource.type == resourceType.SCORM"
						[resource]="resource"
						(unitSelected)="doOpenUnit($event)"
						(resourceLoaded)="setResourceData($event)">
					</app-course-scorm>
					<app-course-xapi *ngIf="resource.type == resourceType.XAPI"
						[resource]="resource"
						(resourceLoaded)="setResourceData($event)">
					</app-course-xapi>
					<app-course-synchronous *ngIf="resource.type == resourceType.SYNCHRONOUS"
						[resource]="resource"
            (lessonSelected)="doOpenLesson($event)"
						(resourceLoaded)="setResourceSyncData($event)">
					</app-course-synchronous>
          <div *ngIf="resource.type != resourceType.SCORM && resource.type != resourceType.SYNCHRONOUS && resource.type != resourceType.XAPI"
               class="module-container" (click)="doOpenResource(resource)">
            <div class="row units-container">
              <div class="col col-sm-1 text-right course-row-interactive">
                <div [statusIcon]="resource.state" [onlyIcon]="true"></div>
              </div>
              <div class="col col-sm-11 unit-title-td course-row-interactive">
                <span class="fa fa-caret-right"></span><span class="unit-title clickable">{{resource.title}}</span>
              </div>
            </div>
          </div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="alert alert-info" *ngIf="_course && _course.resources && _course.resources.length == 0">Nessuna risorsa per questo corso</div>
