import {NgModule} from '@angular/core';
import {BannerComponent} from './banner/banner.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {AppCommonModule} from '../common/module';
import {VersionInfoComponent} from './version-info/version-info.component';
import {AuthenticationModule} from '../authentication/module';
import {BrowserModule} from '@angular/platform-browser';
import {I18NModule} from '../i18n/module';
import {ElementsCountComponent} from './elements-count/elements-count.component';
import {PrivacyLayoutComponent} from './privacy/component';
import {UserModule} from '../user/module';
import {ErrorDefaultComponent} from './error-default/error-default.component';
import {HomeComponent} from './home/component';
import {FooterComponent} from './footer/component';
import {BreadcrumbService} from '../common/_services/breadcrumb.service';
import {CountoModule} from 'angular2-counto';
import {ChartsModule} from 'ng2-charts';
import {HomeStatsComponent} from './home-stats/component';
import {HeaderComponent} from './header/component';
import {AppSlideItemDirective, SlideshowComponent} from '@shared/app/layout/slide-show/component';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {FullCalendarModule} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import {CalendarComponent} from '@shared/app/layout/calendar/calendar/calendar.component';
import {ResourcesIconComponent} from '@shared/app/layout/resources-icon/component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';



FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    HomeComponent,
    HomeStatsComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    BannerComponent,
    BreadcrumbComponent,
    VersionInfoComponent,
    ElementsCountComponent,
    PrivacyLayoutComponent,
    ErrorDefaultComponent,
    SlideshowComponent,
    AppSlideItemDirective,
    CalendarComponent,
    ResourcesIconComponent
  ],
  imports: [
    CountoModule,
    ChartsModule,
    CommonModule,
    BrowserModule,
    TooltipModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    AppCommonModule,
    AuthenticationModule,
    UserModule,
    I18NModule,
    CarouselModule,
    FullCalendarModule,
    BrowserAnimationsModule
  ],
  exports: [
    CountoModule,
    ChartsModule,
    HomeComponent,
    HomeStatsComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    BannerComponent,
    BreadcrumbComponent,
    VersionInfoComponent,
    ElementsCountComponent,
    PrivacyLayoutComponent,
    ErrorDefaultComponent,
    SlideshowComponent,
    CalendarComponent,
    ResourcesIconComponent
  ],
  providers: [BreadcrumbService]
})
export class LayoutModule {

}
