import {Hierarchy} from './hierarchy/model';
import {SafeUrl} from '@angular/platform-browser';
import {PASSWORD_RECOVER_STATE} from './store/reducers';
import {LessonsSessions} from '../lms/synchronous/model';

export interface User {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  imageUrl: SafeUrl;
}

export interface UserState {
  loading?: boolean;
  loadFailed?: boolean;
  hierarchies?: Hierarchy[];
  currentHierarchyId?: number;
  details?: UserDetails;
  stats?: UserStatsHome;
  lessonsSessions?: LessonsSessions;
  fields?: any;
  passwordRecoverState?: PASSWORD_RECOVER_STATE;
  passwordChangeFailureMessage?: PasswordFailError;
  passwordChangeState?: PasswordChangeState;
}

export interface UserError {
  status: string;
}

export enum PASSWORD_CHANGE_STATE {
  none,
  changing,
  changed,
  failed
}

export interface PasswordChangeState {
  status: PASSWORD_CHANGE_STATE;
  details?: string[];
  message?: string;
  error?: string;
  error_description?: string;
}


export interface PasswordFailError {
  details: string[];
  error: string;
  error_description: string;
}

export interface UserStatsHome {
  all: UserStats;
  year: UserStats;
}

export interface UserStats {
  totalCourseAvailable: number;
  totalCourseHourAvailable: number;
  totalCourseCompleted: number;
  totalHoursCourseCompleted: number;
  totalCourseCanDo: number;
  totalHoursCanDo: number;
}

export class UserPreferences {
  countryCode?: UserTimeZone;
  cultureCode?: string;
}


export class UserTimeZone {
  iso: string;
  zone: string;
  offsetMinutes: number;
  offsetHours: number;
}

export interface UserPasswordChangeRequest {
  username?: string;
  oldPassword: string;
  newPassword: string;
}


export interface UserDetails {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  level: string;
  privacyChecked: boolean;
  imageUrl: SafeUrl;
  matricola: string;
  codiceFiscale: string;
  gender: string;
  preferences: UserPreferences;
  lmsUserActive: boolean;
  lmsUserLevel: string;
  company: string;
  direction_name: string;
  roles: any[];
  codeRui: string;
  ruiEnrollmentDate: string;
  job: string;
  business_unit: string;
  business_group: string;
  activity_group: string;
  contractLevel: string;
  home_addr_address1: string;
  home_addr_address2: string;
  home_addr_address3: string;
  home_addr_postcode: string;
  home_addr_country: string;
  home_addr_state: string;
  home_addr_other: string;
  office_addr_address1: string;
  office_addr_address2: string;
  office_addr_address3: string;
  office_addr_postCode: string;
  office_addr_country: string;
  office_addr_state: string;
  office_addr_other: string;

}
