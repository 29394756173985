import { Component, OnInit, TemplateRef, Input, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalContentType, ModalContent } from './modal-content';
import { SafeHtml } from '@angular/platform-browser';
import { SafeUrl } from '@angular/platform-browser';
import {select} from "@angular-redux/store";
import {Observable} from "rxjs";
import {ICustomThemeConfig} from "../../theme/model";
import {ThemeState} from "../../theme/store/reducers";

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent implements OnInit {

  configuration: ModalContent;
  theme: ICustomThemeConfig;

  @select('theme')
  readonly themeState: Observable<ThemeState>;

  constructor(
      public bsModalRef: BsModalRef) {
  }

  ngOnInit() {
    this.themeState.subscribe(t => {
      this.theme = {...t.config};
    });
  }

}
