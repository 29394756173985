import {GenericModalComponent} from '../generic-modal/generic-modal.component';
import {ModalContent, ModalContentType} from '../generic-modal/modal-content';
import {Injectable, ElementRef, SecurityContext, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {DomSanitizer} from '@angular/platform-browser';
import {NgRedux, select} from "@angular-redux/store";
import {AppEnvService} from "../../config/env/service";
import {AppState} from "../../store/reducers";
import {Observable, Subscription} from "rxjs";
import {ThemeState} from "../../theme/store/reducers";
import {filter, map} from "rxjs/operators";

@Injectable()
export class SepModalService {

  private modal: BsModalRef;
  private resourcesPath: string;

  @select('theme')
  private readonly themeState: Observable<ThemeState>;

  public modalContentType: ModalContentType;

  public constructor(
    private bsModalService: BsModalService,
    private sanitizer: DomSanitizer,
    private envService: AppEnvService
  ) {

    this.themeState.pipe(
      filter(state => !!state.config),
      map(state => this.resourcesPath = state.config.resourcesPath)
    );

  }

  public showGenericModal(title: string, body: any): BsModalRef {
    this.modal = this.bsModalService.show(GenericModalComponent);
    this.modal.content.title = title;
    this.modal.content.body = body;
    return this.modal;
  }

  public showGenericModalWithTemplate(template: TemplateRef<any>): BsModalRef {
    this.modal = this.bsModalService.show(template);
    return this.modal;
  }


  public showVideoModal = (title: string, url: string): Observable<BsModalRef> =>
    this.renderModal(ModalContentType.VIDEO, title, url, 'modal-lg modal-dialog-centered');


  public showPDFContentModal = (title: string, url: string): Observable<BsModalRef> =>
    this.renderModal(ModalContentType.PDF, title, url, 'modal-lg modal-full');


  private renderModal(type: ModalContentType, title: string, url: string, customClasses: string) {
    return this.themeState.pipe(
      filter(state => !!state.config),
      map(state => `${this.envService.config().env.resourcesPath}/${state.config.theme}/${url}`),
      map(url => this.sanitizer.bypassSecurityTrustResourceUrl(url)),
      map(sanitizedUrl => new ModalContent(title, type, null, sanitizedUrl)),
      map(modalContent => {
        let modal = this.bsModalService.show(GenericModalComponent, {class: customClasses});
        modal.content.configuration = modalContent;
        return modal
      })
    )
  }
}

