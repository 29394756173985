import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {select} from "@angular-redux/store";
import {iif, Observable, zip} from "rxjs";
import {filter, map, takeUntil, takeWhile} from "rxjs/operators";
import {AuthState} from "./store/reducers";
import {UserState} from "../user/model";


@Injectable()
export class AuthGuard implements CanActivate {

  @select(['auth'])
  readonly authState!: Observable<AuthState>;

  @select(['router'])
  readonly route!: Observable<string>;

  @select(['user'])
  readonly userState!: Observable<UserState>;


  constructor(private router: Router) {
  }

  private activateStrategy = (userState: UserState, authState: AuthState, routerState: RouterStateSnapshot) => {

    return (authState.authenticated === true)
      ? true
      : this.router.parseUrl(`/login?returnUrl=${routerState.url}`)
/*
    return (authState.authenticated === true)
      ? !!userState.details && userState.details.privacyChecked
        ? true
        : this.router.parseUrl(`/privacy?returnUrl=${routerState.url}`)
      : this.router.parseUrl(`/login?returnUrl=${routerState.url}`)
*/
  };

  canActivate = (route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree =>
    zip(this.userState, this.authState)
      .pipe(
        map(([userState, authState]) => this.activateStrategy(userState, authState, routerState))
      )

}
