import {Injectable} from "@angular/core";
import {dispatch} from "@angular-redux/store";
import {FluxStandardAction} from "flux-standard-action";
import {BootError} from "../model";

// Flux-standard-action gives us stronger typing of our actions.
export type BootPayload = any | string;
export type BootAction<T extends BootPayload = undefined> = FluxStandardAction<string, T, any>;

@Injectable()
export class BootActions {
  static readonly START_BOOT = 'START_BOOT';
  static readonly BOOT_STARTED = 'BOOT_STARTED';
  static readonly BOOT_FAILED = 'BOOT_FAILED';
  static readonly BOOT_COMPLETED = 'BOOT_COMPLETED';

  @dispatch()
  startBoot = (returnUrl: string): BootAction<string> => ({
    type: BootActions.START_BOOT,
    meta: {},
    payload: returnUrl
  });

  bootStarted = (): BootAction => ({
    type: BootActions.BOOT_STARTED,
    meta: {},
  });


  bootFailed = (bootError: BootError): BootAction<BootError> => ({
    type: BootActions.BOOT_FAILED,
    meta: {},
    payload: {...bootError}
  });

  bootCompleted = (): BootAction => ({
    type: BootActions.BOOT_COMPLETED,
    meta: {},
  });

}
