import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import {concat, merge, Observable, of, Subject, Subscription, zip} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {User, UserState} from "../../user/model";
import {AuthenticationService} from "../service";
import {UserService} from "../../user/service";
import {AuthActions} from "../store/actions";
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from "rxjs/operators";
import {select} from "@angular-redux/store";
import {AuthState} from "../store/reducers";
import {UserActions} from "../../user/store/actions";

@Component({
  selector: 'app-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss']
})


export class ImpersonateComponent implements OnInit, OnDestroy {
  public clients: Observable<User[]>;
  private searchTerms = new Subject<string>();
  public selectedUsername = '';
  public selectedUser: User;
  public resultList = true;
  public searchPlaceholder: Observable<string>;

  public subscriptions: Subscription[] = [];


  @select(['auth'])
  readonly authState!: Observable<AuthState>;

  @select(['user'])
  readonly userState!: Observable<UserState>;


  constructor(
    public bsModalRef: BsModalRef,
    private authService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private userActions: UserActions,
    private translateService: TranslateService,
    private authActions: AuthActions
  ) {
  }

  private searchUser = (term: string) => term.length > 1 ? this.userService.search(term) : of([]);

  ngOnInit() {

    this.searchPlaceholder = this.translateService.get('Search user');
    this.clients = this.searchTerms.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(this.searchUser)
    );

    this.subscriptions.push(
      // this.authState.pipe(
      //   filter(state => state.impersonating === true),
      //   map(() => this.router.navigate(['/']))
      // ).subscribe(),

      zip(this.authState, this.userState).pipe(
        filter((state) => {
          const [authState, userState] = state;
          return (authState.impersonating === true) && (true === userState.loadFailed)
        }),
        map(() => this.authActions.impersonateLogout())
      ).subscribe()

      // this.userState.pipe(
      //   filter(state => {
      //     console.log(`state.loadFailed: ${state.loadFailed}`);
      //     return state.loadFailed === true
      //   }),
      //   map(() => this.authActions.impersonateLogout())
      // ).subscribe()
    )
  }

  onImpersonateLogin() {
    this.bsModalRef.hide();
    this.authActions.impersonateLogin(this.selectedUser.id)
    // this.authService.impersonateLogin(this.selectedUser.id).subscribe(() => {
    //   if ( this.router.url.indexOf('home') !== -1 ) {
    //     this.router.navigate(['/']);
    //   } else {
    //     this.router.navigate(['/home']);
    //   }
    // });
  }

  searchClient(term: string): void {

    this.resultList = true;
    this.searchTerms.next(term);
  }

  onselectClient(user) {
    if (user.id !== 0) {
      this.selectedUsername = user.username;
      this.selectedUser = user;
      this.resultList = false;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    //this.subscriptions.forEach(s => s.unsubscribe())
  }

}
