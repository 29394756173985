import {Injectable} from '@angular/core';
import {dispatch} from '@angular-redux/store';
import {FluxStandardAction} from 'flux-standard-action';
import {LessonsState} from './reducers';
import {LessonSession} from '@shared/app/lms/synchronous/model';


export type LessonsPayload = LessonsState | Array<LessonSession> | LessonSession | any;
export type LessonsAction<T extends LessonsPayload = undefined> = FluxStandardAction<string, T, any>;


@Injectable()
export class LmsLessonsActions {
  static readonly LESSONS_LOAD = 'LESSONS_LOAD';
  static readonly LESSONS_LOAD_STARTED = 'LESSONS_LOAD_STARTED';
  static readonly LESSONS_LOAD_FAILED = 'LESSONS_LOAD_FAILED';
  static readonly LESSONS_LOAD_SUCCEED = 'LESSONS_LOAD_SUCCEED';

  @dispatch()
  loadPathList = (): LessonsAction => ({
    type: LmsLessonsActions.LESSONS_LOAD,
    meta: {}
  });

  loadStarted = (): LessonsAction => ({
    type: LmsLessonsActions.LESSONS_LOAD_STARTED,
    meta: {},
  });

  loadFailed = (error: any): LessonsAction<any> => ({
    type: LmsLessonsActions.LESSONS_LOAD_FAILED,
    meta: {},
    payload: error
  });

  loaded = (paths: Array<LessonSession>): LessonsAction<Array<LessonSession>> => ({
    type: LmsLessonsActions.LESSONS_LOAD_SUCCEED,
    meta: {},
    payload: paths
  });
}
