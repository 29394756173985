<div *ngIf="courseSync">
  <div class="row">
    <div class="col col-sm-12 d-flex justify-content-end">
      <div class="btn-group" btnRadioGroup [(ngModel)]="filterSessionsLessons">
        <label class="btn btn-xs"
               [ngClass]="{'btn-success': filterSessionsLessons === VisibleSessionsLessons.today ,
               'btn-outline-secondary': filterSessionsLessons !== VisibleSessionsLessons.today}"
               [btnRadio]="VisibleSessionsLessons.today"
               tabindex="0" role="button" translate>{{VisibleSessionsLessons.today}}</label>
        <label class="btn btn-xs"
               [ngClass]="{'btn-success': filterSessionsLessons === VisibleSessionsLessons.available,
               'btn-outline-secondary': filterSessionsLessons !== VisibleSessionsLessons.available }"
               [btnRadio]="VisibleSessionsLessons.available"
               tabindex="0" role="button" translate>{{VisibleSessionsLessons.available}}</label>
        <label class="btn btn-xs"
               [ngClass]="{'btn-success': filterSessionsLessons === VisibleSessionsLessons.all,
               'btn-outline-secondary': filterSessionsLessons !== VisibleSessionsLessons.all}"
               [btnRadio]="VisibleSessionsLessons.all"
               tabindex="0" role="button" translate>{{VisibleSessionsLessons.all}}</label>
      </div>
    </div>
  </div>
  <div *ngIf="courseSync">
    <div class="module-container"
         *ngIf="(courseSync.sessions | filterSession :  {showOnlyTodaySession : filterSessionsLessons}).length === 0 &&
          filterSessionsLessons === VisibleSessionsLessons.today">
      Nessuna lezione odierna da mostrare
    </div>
    <div class="module-container"
         *ngIf="(courseSync.sessions | filterSession :  {showOnlyTodaySession : filterSessionsLessons}).length === 0 &&
          filterSessionsLessons === VisibleSessionsLessons.available">
      Nessuna sessione disponibile
    </div>
    <div class="module-container"
         *ngIf="(courseSync.sessions | filterSession :  {showOnlyTodaySession : filterSessionsLessons}).length === 0 &&
          filterSessionsLessons === VisibleSessionsLessons.all">
      Nessuna sessione presente
    </div>
    <div class="module-container"
         *ngFor="let session of courseSync.sessions | filterSession :  {showOnlyTodaySession : filterSessionsLessons}  let m = index">
      <div class="row">
        <div class="col col-12 module-title">{{m + 1}}. {{session.description}}</div>
      </div>
      <div class="row units-container mb-2"
           *ngFor="let lesson of session.lessons | filterLesson :  {showOnlyTodayLessons : filterSessionsLessons}  ; let u = index">
        <div class="col-12">
          <div class="row">
            <div class="col col-1 text-right course-row-interactive">
              <span [statusIcon]="'NOT_ATTEMPTED'" [onlyIcon]="true"></span>
            </div>
            <div class="col col-11 unit-title-td course-row-interactive">
              <span class="lesson-title">{{u + 1}}.{{lesson.description}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col col-1"></div>
            <div class="col col-9 unit-title-td course-row-interactive">
              <i class="fa fa-calendar button-nav" container="body"
                 tooltip="{{'event start date and time' | translate}}"></i>&nbsp;<span
              class="module-title">{{lesson.dateStart | date:'dd/MM/yyyy'}} {{lesson.dateStart | date: 'HH:mm'}}
              - {{lesson.dateEnd  | date: 'HH:mm'}}</span>
              <br>
              <i class="fa fa-info-circle"></i>&nbsp;<b *ngIf="session.role !== UserSessionStatus.ASSIGNABLE ||
              (session.role === UserSessionStatus.ASSIGNABLE && session.rolesRegistration === RolesRegistration.USER)"
                                                        translate>{{UserSessionStatusLang[session.role]}}</b>
              <b
                *ngIf="session.role === UserSessionStatus.ASSIGNABLE && session.rolesRegistration === RolesRegistration.MANAGER"
                translate>the auto subscription for this session is disabled</b>&nbsp; <span
              *ngIf="session.role === UserSessionStatus.USER  && session.rolesRegistration === RolesRegistration.USER && session.state != SessionState.CLOSED"
              class="label clickable sentence-capitalize bg-danger label-hover-opacity"
              (click)="removeFromSession(session)"><span translate>unsubscribe</span>&nbsp;
                            <i class="fa fa-times"></i></span>
              <br>
              <span *ngIf="lesson.showLocation == LessonLocationType.SHOW">
                <i class="fa fa-map-marker button-nav"></i>&nbsp;{{lesson.location}}</span>
            </div>
            <div *ngIf="session.role === UserSessionStatus.USER || session.role === UserSessionStatus['MAIN TEACHER']" class="col col-2 text-right">
              <div *ngIf="lesson.showLocation == LessonLocationType.VDC" class="unit-title-td course-row-interactive">
                <button class="btn btn-enter"
                        (click)="openLesson(lesson)"><i
                  class="fa text-center"
                  [ngClass]="{'fa-caret-right': lesson.isPlaying, 'fa-info': !lesson.isPlaying}"></i>
                </button>
              </div>
              <div *ngIf="lesson.location | isLink"
                   class="unit-title-td course-row-interactive">
                <a class="btn btn-enter" (click)="openNonVdcLesson(lesson)" target="_blank"><i
                  class="fa text-center"
                  [ngClass]="{'fa-caret-right': lesson.isPlaying, 'fa-info': !lesson.isPlaying}"></i></a>
              </div>
            </div>
            <div
              *ngIf="session.role === UserSessionStatus.ASSIGNABLE && session.rolesRegistration === RolesRegistration.USER"
              class="col col-2 text-right">
              <button class="btn btn-sm btn-enter" (click)="enrollToSession(session)"><span translate>enroll</span>
              </button>
            </div>
          </div>
          <hr>
        </div>
      </div>
    </div>
  </div>
</div>
