import {AfterViewInit, Component, Input, OnChanges, OnInit} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      transition(':enter', [
        style({ opacity: 0.7 }),
        animate('800ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('400ms', style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class BannerComponent implements OnInit, OnChanges, AfterViewInit  {

  visible: boolean;
  @Input()
  display: string;
  @Input()
  colorSchema: string;
  backgroundImageUrl: SafeStyle;

  constructor(private sanitizer: DomSanitizer) {
    this.visible = false;
  }

  _title: string;

  @Input()
  set title(t: string) {
    this._title = t;
  }

  _description: string;

  @Input()
  set description(d: string) {
    this._description = d;
  }

  @Input()
  set backgroundImage(i: string) {
    this.backgroundImageUrl = this.sanitizer.bypassSecurityTrustStyle('url(' + this.escapeQuote(i) + ')');
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.visible = true;
  }

  ngOnChanges(c) {
  }

  private escapeQuote(s: string): string {
    return encodeURI(s).replace('\'', '\\\'');
  }
}
