import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {NotificationsService} from 'angular2-notifications';

@Component({
  selector: 'app-modal-vdc-user-key',
  templateUrl: './modal-vdc-user-key.component.html',
  styleUrls: ['./modal-vdc-user-key.component.scss']
})
export class ModalVdcUserKeyComponent implements OnInit {

  bodyTextRows: string[];
  btnConfirmText: string;
  identityVdcType: string;
  identityVdcUser: any;

  @Output() dimis: EventEmitter<any> = new EventEmitter();
  public onClose: Subject<boolean>;


  @ViewChild('pop') pop: any;

  constructor(private _bsModalRef: BsModalRef, private notificationSrv: NotificationsService) {
  }

  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  public onConfirm(): void {
    this.dimis.emit(true);
    this.onClose.next(true);
    this._bsModalRef.hide();
  }

  public onCancel(): void {
    this.dimis.emit(false);
    this.onClose.next(false);
    this._bsModalRef.hide();
  }

  public copy(e: any): void {
    setTimeout(() => {
      this.pop.toggle();
    }, 1000);
  }
}
