import {ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewRef} from '@angular/core';
import {DynamicPath, FlowChartComponent} from 'ngx-sep-graph-builder';
import {forkJoin, Observable, Subscription, timer} from 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {NotificationsService} from 'angular2-notifications';
import {TranslateService} from '@ngx-translate/core';
import * as fileSaver from 'file-saver';
import * as _ from 'lodash';
import {PathService} from './path.service';
import {LocalStorageService} from '../../storage/local/service';
import {AppEnvService} from '../../config/env/service';
import {Path} from './model';
import {Course} from '../courses/model';
import {ScormUnit, UnitType} from '../scorm/unit/model';
import {Resource, ResourceType} from '../resources/model';
import {UserDetails} from '../../user/model';
import {CourseService} from '../courses/course.service';
import {UserService} from '../../user/service';
import {ResourceService} from '../resources/resource.service';
import {BreadCrumbFactory, BreadcrumbLevel, BreadcrumbService, SepBreadcrumb} from '../../common/_services/breadcrumb.service';
import {SepModalService} from '../../common/_services/modal.service';
import {ModalContent, ModalContentType} from '../../common/generic-modal/modal-content';
import {GenericModalComponent} from '../../common/generic-modal/generic-modal.component';
import {Lesson, VDCTYPE} from '@shared/app/lms/synchronous/course/model';
import {LmsPathsActions} from '@shared/app/lms/paths/store/actions';
import {select} from '@angular-redux/store';
import {LmsPath} from '@shared/app/lms/paths/store/model';
import {ModalVdcUserKeyComponent} from '@shared/app/lms/synchronous/modal-vdc-user-key/modal-vdc-user-key.component';

@Component({
  selector: 'app-lms-path-component',
  templateUrl: 'component.html',
  styleUrls: ['component.scss']
})
export class LmsPathComponent implements OnInit, OnDestroy {
  uuid: string;
  id: number;
  courseId: number;
  path: Path;
  dynamicPath: DynamicPath;
  course: Course;
  pathPerspective = 'PATH';
  subscriptions: Subscription[] = [];
  routeSubscriptions: Subscription[] = [];
  errorMessage: string;
  certErrorMessage: string;
  unitModalRef: BsModalRef;
  lessonModalRef: BsModalRef;
  certModalRef: BsModalRef;
  userVdcRef: BsModalRef;
  warningModalRef: BsModalRef;
  closedModalRef: BsModalRef;
  resourceOpenedModalRef: BsModalRef;
  viewerUrl: string;
  openedUnit: ScormUnit;
  openedLesson: Lesson;
  openedCertificate: Resource;
  unitUrl: SafeResourceUrl | String;
  lessonUrl: SafeResourceUrl | String;
  unitClosed: any;
  loading = true;
  resourceLoading = false;
  groupedCourses: Map<string, Course[]>;
  groupCourseCriteria = 'theme';
  user: UserDetails;
  certificateSrc;
  unitContentWindow;

  @select(['lms', 'paths', 'opened'])
  readonly $pathOpened: Observable<LmsPath>;

  @select(['lms', 'paths', 'opening'])
  readonly $pathOpening: Observable<boolean>;

  @ViewChild('resourceUsedWarningTemplate')
  warningTmpl: TemplateRef<any>;

  @ViewChild('resourceClosedWarningTemplate')
  closedTmpl: TemplateRef<any>;

  @ViewChild('unitTemplate')
  unitTmpl: TemplateRef<any>;

  @ViewChild('lessonTemplate')
  lessonTmpl: TemplateRef<any>;

  @ViewChild('certificateTemplate')
  certificateTmpl: TemplateRef<any>;

  @ViewChild('alterVdcCredentialTemplate')
  alterVdcCredentialTemplate: TemplateRef<any>;

  @ViewChild('resourceOpenedWarningTemplate')
  resourceOpenedTmpl: TemplateRef<any>;

  @ViewChild('flowChart') fc: FlowChartComponent;

  private rescourcesUsedTimer: Observable<any>;
  private rescourcesUsedTimerSubscription: Subscription;

  private QUIZ_VIEWER_URL_REGEX = /assets\/quiz-viewer/i;

  constructor(
    private courseService: CourseService,
    private resourceService: ResourceService,
    private pathService: PathService,
    private route: ActivatedRoute,
    private router: Router,
    private bcService: BreadcrumbService,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer,
    private sepModalService: SepModalService,
    private notificationService: NotificationsService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private domSanitizer: DomSanitizer,
    private localStorageService: LocalStorageService,
    private envService: AppEnvService,
    private pathAction: LmsPathsActions
  ) {
    this.viewerUrl = envService.config().scorm.viewerUrl;
  }

  ngOnInit() {
    this.uuid = this.localStorageService.getSessionUuid();

    this.userService.details().subscribe(u => this.user = u);

    this.courseService.updatePath$.subscribe(
      () => {
        this.getPathData();
      }
    );

    this.routeSubscriptions.push(this.route.params.subscribe(params => {
      const isSamePathId = this.id === +params['id'];
      this.id = +params['id'];
      if (this.route.firstChild !== undefined && this.route.firstChild !== null) {
        this.route.firstChild.params.subscribe(childParams => {
          if (childParams['courseId'] !== undefined && childParams['courseId'] !== null) {
            this.courseId = +childParams['courseId'];
            this.pathPerspective = 'COURSE';
          }
        });
      }
      if (!isSamePathId) {
        this.getPathData();
      }
    }));

    this.routeSubscriptions.push(this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.route.firstChild !== undefined && this.route.firstChild !== null) {
          this.route.firstChild.params.subscribe(childParams => {
            if (childParams['courseId'] !== undefined && childParams['courseId'] !== null) {
              this.courseId = +childParams['courseId'];
              this.pathPerspective = 'COURSE';
            }
          });
        } else {
          this.pathPerspective = 'PATH';
          // tslint:disable-next-line:max-line-length
          const b: SepBreadcrumb = (new BreadCrumbFactory).createBreadCrumb(this.path.title, {id: this.path.id}, '/path', BreadcrumbLevel.second);
          this.bcService.setBreadcrumb(b);
        }
      }
    }));

    window.onbeforeunload = (ev) => this.closeOpenedResource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.routeSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
    if (this.rescourcesUsedTimerSubscription !== undefined) {
      this.rescourcesUsedTimerSubscription.unsubscribe();
    }
    this.rescourcesUsedTimer = null;
    if (this.unitContentWindow) {
      this.unitContentWindow.close();
    }
  }

  getPathData() {
    this.loading = true;
    this.$pathOpening.subscribe(p => {
      this.loading = p;
    });

    this.$pathOpened.subscribe(p => {
        if (p) {
          this.path = this.pathService.getPathView(p);
          this.loading = false;
          this.translateService.get('My courses').subscribe(t => {
            if (this.path.totalCourses === 1) {
              this.course = this.path.courses[0];
              this.pathPerspective = 'SINGLECOURSE';
              const bP: SepBreadcrumb = (new BreadCrumbFactory).createBreadCrumb(t, null, '/courses', BreadcrumbLevel.first);
              this.bcService.setBreadcrumb(bP);
            } else {
              // tslint:disable-next-line:max-line-length
              const bP: SepBreadcrumb = (new BreadCrumbFactory).createBreadCrumb(t, null, '/courses', BreadcrumbLevel.first);
              this.bcService.setBreadcrumb(bP);
              // tslint:disable-next-line:max-line-length
              const b: SepBreadcrumb = (new BreadCrumbFactory).createBreadCrumb(this.path.title, {id: this.path.id}, '/path', BreadcrumbLevel.second);
              this.bcService.setBreadcrumb(b);
              this.setPathCoursesVisibility();
            }
          });


          if (this.path.dynamic === true) {
            if (!this.path.dynamicState) {
              this.translateService.get('Impossible to display this path').subscribe(t => {
                this.errorMessage = t;
              });
            }
            this.pathPerspective = 'DYNAMIC_PATH';
            this.dynamicPath = this.pathService.getStatefulPath(this.path);
          } else {

            if (this.course) {
              this.openCourse(this.course);
            } else if (this.courseId) {
              for (const c of this.path.courses) {
                if (c.id === this.courseId) {
                  this.course = c;
                  this.openCourse(this.course);
                }
              }
            }
            const _gc = new Map<string, Course[]>();
            for (const c of this.path.courses) {
              const courseCriteriaValue = c[this.groupCourseCriteria] ? c[this.groupCourseCriteria] : 'Other';
              if (_gc.get(courseCriteriaValue) === undefined) {
                _gc.set(courseCriteriaValue, new Array<Course>());
              }
              _gc.get(courseCriteriaValue).push(c);
            }
            this.groupedCourses = _gc;
          }
        } else {
          this.loading = true;
        }
      },
      err => {
        this.loading = false;
        console.log('course err', err);
        this.translateService.get('Impossible to display this course').subscribe(t => {
          this.errorMessage = t;
        });
      }
    );

    this.pathAction.openPath(this.id);
  }

  openCourse(c: Course) {
    if (c.allowView === false) {
      this.translateService.get('To view this element you must complete the previous ordered mandatory items').subscribe(
        t => this.notificationService.warn(t, null, {timeOut: 5000})
      );
    } else {
      this.course = Object.assign({}, c);
      if (this.changeDetectorRef && !(this.changeDetectorRef as ViewRef).destroyed) {
        this.changeDetectorRef.detectChanges();
      }
//      if (this.activeRoute$.firstChild !== undefined && this.activeRoute$.firstChild !== null) {
//          this.router.navigate(['course', c.id]);
//      } else {
      if (this.pathPerspective !== 'SINGLECOURSE') {
        this.router.navigate(['course', c.id], {relativeTo: this.route});
      }
      let b: SepBreadcrumb;
      if (this.path.totalCourses === 1) {
        b = (new BreadCrumbFactory).createBreadCrumb(c.title, {id: c.id}, 'course', BreadcrumbLevel.second);
      } else {
        b = (new BreadCrumbFactory).createBreadCrumb(c.title, {id: c.id}, 'course', BreadcrumbLevel.third);
      }
      this.bcService.setBreadcrumb(b);
//      }
    }
  }

  openScormUnit(unit: ScormUnit) {
    this.openedUnit = unit;
    if (!unit.locked) {
      this.setUnitUrl(this.openedUnit.openInNewWindow !== true);
      const checkSubscription = this.resourceService.checkResourceUsed().subscribe(
        data => {
          if (data === null || data === undefined || data === '' || data === this.uuid) {
            this.doOpenScormUnit();
          } else {
            this.warningModalRef = this.modalService.show(this.warningTmpl);
          }
          checkSubscription.unsubscribe();
        },
        err => {
          if (err['status'] === 404) {
            this.doOpenScormUnit();
          } else {
            forkJoin(
              this.translateService.get('Warning'),
              this.translateService.get('There was an error in the open contents control')
            ).subscribe(t => {
              this.notificationService.error(t[0], t[1], {timeOut: 5000});
            });
          }
          checkSubscription.unsubscribe();
        }
      );
    } else {
      forkJoin(
        this.translateService.get('Warning'),
        this.translateService.get('This unit is locked')
      ).subscribe(t => this.notificationService.warn(t[0], t[1], {timeOut: 3000}));
    }
  }

  openLesson(lesson: Lesson) {
    this.openedLesson = lesson;
    this.doOpenLesson();
  }

  confirmWarningOpenedResource() {
    this.warningModalRef.hide();
    this.doOpenScormUnit();
  }

  cancelWarningOpenedResource() {
    this.openedUnit = undefined;
    this.warningModalRef.hide();
  }

  closeOpenedResource() {
    this.openedUnit = undefined;
    if (this.unitContentWindow) {
      this.unitContentWindow.close();
    }
    if (this.resourceOpenedModalRef) {
      this.resourceOpenedModalRef.hide();
    }
  }

  closeScormUnit() {
    this.getPathData();
    this.openedUnit = null;
    this.resourceService.unsetResourceUsed().subscribe();
    this.stopWatchingRescourcesOpened();
    if (this.unitModalRef !== undefined) {
      this.unitModalRef.hide();
    }
    this.pathService.unitHasBeenClosed.next(new Date());
  }

  closeLesson() {
    this.getPathData();
    this.openedLesson = null;
    if (this.lessonModalRef !== undefined) {
      this.lessonModalRef.hide();
    }
  }

  closeCertificate() {
    this.getPathData();
    this.openedCertificate = null;
    this.resourceService.unsetResourceUsed().subscribe();
    this.stopWatchingRescourcesOpened();
    if (this.certModalRef !== undefined) {
      this.certModalRef.hide();
    }
    this.unitClosed = new Date();
  }

  openResource(resource: Resource) {
    if (resource.type === ResourceType.CERTIFICATE) {
      this.resourceLoading = true;
      this.openedCertificate = resource;
      this.resourceService.setResourceOpened(resource.id, this.course.id, this.id, resource.catalogId).subscribe(
        response => {
          this.doOpenCertificate();
          this.resourceService.getCertificate(this.course.id).subscribe(
            r => {
              this.resourceLoading = false;
              const objectUrl = window.URL.createObjectURL(r);
              this.certificateSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(objectUrl);
            },
            err => {
              this.resourceLoading = false;
              this.translateService.stream(['Error loading the element', 'Course not completed']).subscribe(
                t => {
                  this.certErrorMessage = t['Error loading the element'];
                  if (err.error && err.error.message) {
                    if (err.error.message === 'course_not_completed') {
                      this.certErrorMessage += '. ' + t['Course not completed'];
                    } else {
                      this.certErrorMessage += '. ' + err.error.message;
                    }
                  }
                });
              console.log('getCertificate errror', err);
            }
          );
        }, error => {
          this.resourceLoading = false;
          this.translateService.get('Error generating the element').subscribe(m => {
            this.notificationService.error(m);
            console.error(error);
          });
        }
      );
    } else {
      if (resource.url !== undefined) {
        window.open(resource.url);
        this.resourceService.setResourceOpened(resource.id, this.course.id, this.id, resource.catalogId).subscribe(
          res => this.getPathData(),
          err => console.log('setResourceOpened error')
        );
      }
    }
  }

  downloadCertificate() {
    this.resourceService.downloadCertificate(this.course.id).subscribe((response) => {
        const blob = new Blob([response], {type: 'application/pdf;'});
        fileSaver.saveAs(blob, 'certificate.pdf', false);
      }
    );
  }

  setUnitUrl(safe: boolean = true) {
    if (this.openedUnit !== undefined && this.openedUnit !== null) {
      let params = '?userId=' + this.user.id;
      if (this.openedUnit.courseId) {
        params += '&courseId=' + this.openedUnit.courseId;
      }
      if (this.openedUnit.pathId) {
        params += '&pathId=' + this.openedUnit.pathId;
      }
      if (this.openedUnit.catalogId) {
        params += '&catalogId=' + this.openedUnit.catalogId;
      }
      if (this.openedUnit.duration) {
        params += '&credits=' + this.openedUnit.duration;
      }
      if (this.openedUnit.type === UnitType.LESSONORQUIZ
        && this.openedUnit.quizId !== undefined && this.openedUnit.quizId !== 0
        && this.QUIZ_VIEWER_URL_REGEX.test(this.openedUnit.url as string)) {
        /** quiz new version*/
        params += '&unitId=' + this.openedUnit.id + '&quizId=' + this.openedUnit.quizId;
        if (safe) {
          this.unitUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.envService.config().scorm.quizViewerUrl + params);
        } else {
          this.unitUrl = this.envService.config().scorm.quizViewerUrl + params;
        }
      } else {
        const token = JSON.parse(this.localStorageService.get(LocalStorageService.OAuthTokenKeyName))['access_token'];
        /** lezione o quiz old version*/
        params += '&unit=' + this.openedUnit.id + '&class=' + this.path.id + '&token=' + token + '&debug=1'
          + '&resourceId=' + this.openedUnit.courseResourceId;
        if (safe) {
          this.unitUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.viewerUrl + params);
        } else {
          this.unitUrl = this.viewerUrl + params;
        }
      }
    } else {
      this.unitUrl = undefined;
    }
  }

  closAlterVdcCredential() {
    this.userVdcRef.hide();
    this.openModalWindowOpen();
  }

  openModalWindowOpen() {
    this.openedLesson.vdcUrl = this.openedLesson.vdcUrl;
    switch (this.openedLesson.vdcType) {
      case VDCTYPE.CONNECT:
        this.openedLesson.vdcUrl += this.openedLesson.vdcUrl.indexOf('?') >= 0 ? ('&guestName=' + this.user.username) : ('?guestName=' + this.user.username);
        break;
    }
    const params = `directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no'`;
    this.unitContentWindow = window.open(this.openedLesson.vdcUrl, 'Lesson', params);
    if (!this.unitContentWindow) {
      this.translateService.stream([
        'Warning',
        'A popup block has been detected. Check your browser settings to allow popup window opening.'
      ]).subscribe(t => {
        const modalContent = new ModalContent(
          t['Warning'],
          ModalContentType.ALERT,
          t['A popup block has been detected. Check your browser settings to allow popup window opening.'],
          null
        );
        const _modal = this.modalService.show(GenericModalComponent, {});
        _modal.content.configuration = modalContent;
      });
    } else {
      this.resourceOpenedModalRef = this.modalService.show(this.resourceOpenedTmpl, {
        backdrop: 'static',
        keyboard: false,
        class: 'second'
      });
    }
  }

  private doOpenScormUnit() {
    if (this.openedUnit.openInNewWindow) {
      this.unitContentWindow = window.open(this.unitUrl.toString());

      // controllo blocco popup del browser (es. safari iOS)
      if (!this.unitContentWindow) {
        this.translateService.stream([
          'Warning',
          'A popup block has been detected. Check your browser settings to allow popup window opening.'
        ]).subscribe(t => {
          const modalContent = new ModalContent(
            t['Warning'],
            ModalContentType.ALERT,
            t['A popup block has been detected. Check your browser settings to allow popup window opening.'],
            null
          );
          const _modal = this.modalService.show(GenericModalComponent, {});
          _modal.content.configuration = modalContent;
        });
      } else {
        this.resourceOpenedModalRef = this.modalService.show(this.resourceOpenedTmpl, {
          backdrop: 'static',
          keyboard: false
        });

        this.resourceService.setResourceUsed(this.uuid).subscribe(
          d => console.log('resourceService.setResourceUsed', this.uuid, d),
          err => console.log('resourceService.setResourceUsed err', err)
        );
        this.unitContentWindow.onbeforeunload = () => {
          this.closeScormUnit();
          this.closeOpenedResource();
        };
      }
    } else {
      this.unitModalRef = this.modalService.show(this.unitTmpl, {
        backdrop: 'static',
        keyboard: false,
        class: 'modal-lg modal-unit'
      });
      this.resourceService.setResourceUsed(this.uuid).subscribe(
        d => console.log('resourceService.setResourceUsed', this.uuid, d),
        err => console.log('resourceService.setResourceUsed err', err)
      );
    }
    this.startWatchingRescourcesOpened();
  }

  private doOpenLesson() {

    this.lessonUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.openedLesson.vdcUrl);
    switch (this.openedLesson.vdcType) {
      case VDCTYPE.ZOOM:
        this.openModalWindowOpen();
        break;
      case VDCTYPE.TEAMS:
        this.doOpenAlterCredential();
        break;
      case VDCTYPE.CONNECT:
        this.openModalWindowOpen();
        break;
    }
  }

  private doOpenAlterCredential() {
    this.userVdcRef = this.modalService.show(ModalVdcUserKeyComponent, {
      backdrop: 'static',
      class: 'modal-lg',
      keyboard: false
    });
    this.userVdcRef.content.btnConfirmText = 'Confirm';
    this.userVdcRef.content.identityVdcUser = this.user.username;
    this.userVdcRef.content.identityVdcType = this.openedLesson.vdcType;
    this.userVdcRef.content.dimis.subscribe((receivedEntry) => {
      this.userVdcRef.content.dimis.unsubscribe();
      if (receivedEntry) {
        this.openModalWindowOpen();
      }
    });

  }

  private doOpenCertificate() {
    this.certModalRef = this.modalService.show(this.certificateTmpl, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-lg modal-unit'
    });
    this.startWatchingRescourcesOpened();
  }

  private startWatchingRescourcesOpened() {
    this.rescourcesUsedTimer = timer(30000, 30000);
    this.rescourcesUsedTimerSubscription = this.rescourcesUsedTimer.subscribe(t => {
      this.subscriptions.push(this.resourceService.checkResourceUsed().subscribe(
          data => {
            if (data !== undefined && data !== null && this.uuid !== data) {
              this.getPathData();
              this.openedUnit = null;
              this.stopWatchingRescourcesOpened();
              this.unitModalRef.hide();
              this.closedModalRef = this.sepModalService.showGenericModalWithTemplate(this.closedTmpl);
            }
          },
          err => {
            console.log('error checking resourceUsed');
          }
        )
      );
    });
    this.subscriptions.push(this.rescourcesUsedTimerSubscription);
  }

  private stopWatchingRescourcesOpened() {
    if (this.rescourcesUsedTimerSubscription) {
      this.rescourcesUsedTimerSubscription.unsubscribe();
    }
  }

  private setPathCoursesVisibility() {
    if (this.path && this.path.courses && this.path.courses.length > 1) {
      let previousOrderMandatoryCourse = null;
      const orderMandatoryCourses = _.filter(this.path.courses, {orderMandatory: true});
      for (const c of orderMandatoryCourses) {
        if (previousOrderMandatoryCourse == null) {
          c.allowView = true;
        } else {
          c.allowView = previousOrderMandatoryCourse.state === 'COMPLETED';
        }
        previousOrderMandatoryCourse = c;
      }
      const notOrderMandatoryCourses = _.filter(this.path.courses, {orderMandatory: false});
      for (const c of notOrderMandatoryCourses) {
        c.allowView = true;
      }
    }
  }
}
