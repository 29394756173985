<app-banner
  [display]="'thin'"
  [title]="bannerTitle"
  [description]="bannerDescription"
  [backgroundImage]="'assets/img/report-banner.jpg'"
  [colorSchema]="'light'"
></app-banner>


<ng-template #widgetToBeCompletedHoursByTypeChartTemplate let-stats="stats">
  <div class="home-widget-cell cell-1">
    <canvas baseChart *ngIf="stats.data"
            [data]="stats.data"

            [labels]="stats.labels"
            [chartType]="stats.type"
            [legend]="false"
            [colors]="colors">
    </canvas>
  </div>
</ng-template>

<div class="report-page">
  <div class="section panel panel-default table-dynamic">
    <div class="row justify-content-center mt-2 mb-2 ">
      <div class="col-9">

        <div class="row">
          <div class="col-6" *ngIf="loadingStats">
            <div [spinner]="loadingStats"></div>
          </div>
          <div class="col-6" *ngIf="!loadingStats">
            <div class="row">
              <div class="col-12">
                <h4 class="text-center mb-5" placement="bottom" [tooltip]="'Si riferisce ai cosi non completati e disponibili per l’utente. Un corso è disponibile all’utente quando è associate tramite un catalogo facoltativo o obbligatorio.\n'+
'Un corso associato e obbligatorio è sempre raggiungibile dalla sezione “I miei Corsi”.\n'+
'\n'+
'Attenzione: la somma dei corsi completati può essere maggiore di quelli disponibili perchè potrebbero essere stati completati corsi non più associate all’utente.\n'+
'La dutata indica la durata accreditabile e non il tempo effettivo impiegato nello svolgimento del corso.\n' | translate">
                  <span translate>Available hours not completed</span><i
                  class="info fa fa-info-circle"></i></h4>
              </div>
            </div>
            <div class="row">
              <div class="col-7">
                <div *ngTemplateOutlet="widgetToBeCompletedHoursByTypeChartTemplate;context:{stats: char}"></div>
              </div>
              <div class="col-5">
                <div class="row">
                  <div class="col-12">
                    <span
                      class="big-number"
                      [appDuration]="userStats.countersCourse.userCourseAssociationNotCompleted.totalCoursesHours"
                      [format]="'minutes'" [showNumberIfNullOrZero]="true">
                    </span><br>
<!--                    <span class="desc-report" translate>mandatory</span>-->
                  </div>
                </div>
                <div class="row mt-4">
<!--                  <div class="col-12">-->
<!--                    <span-->
<!--                      class="small-number"-->
<!--                      [appDuration]="userStats.countersCourse.userCourseAssociationNotCompleted.optionalTotalCoursesHours"-->
<!--                      [format]="'minutes'" [showNumberIfNullOrZero]="true">-->
<!--                    </span><br>-->
<!--                    <span class="desc-report" translate>optional</span>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
          <div class="col-6" *ngIf="loadingStatsYear">
            <div [spinner]="loadingStatsYear"></div>
          </div>
          <div class="col-6" *ngIf="!loadingStatsYear">
            <div class="row justify-content-center">
              <div class="col-sm-12 text-center form-group">
                <h4 class="text-center" placement="bottom" [tooltip]="'Si riferisce ai cosi completati nell’anno selezionato. I corsi possono essere completati e disponibili oppure completati e non più disponibili. Completati e appartenenti a cataloghi facoltativi o obbligatori.\n'+
'Attenzione: la somma dei corsi completati può essere maggiore di quelli disponibili perchè potrebbero essere stati completati corsi non più associate all’utente.\n'+
'La dutata indica la durata accreditabile e non il tempo effettivo impiegato nello svolgimento del corso\n' | translate">
                  <span translate>Ore completate</span>&nbsp;<i class="info fa fa-info-circle"></i></h4>
                <div class="row justify-content-center">
                  <div class="col-4">
                    <select class="form-control form-control-sm" #searchYearStats [(ngModel)]="yearStats"
                            (change)="onStatusChangeStats($event)">
                      <option value="0"><span translate>Select a year</span></option>
                      <option [value]="year" *ngFor="let year of years;">{{ year }}</option>
                    </select>
                  </div>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-7">
                <div *ngTemplateOutlet="widgetToBeCompletedHoursByTypeChartTemplate;context:{stats: charByYear}"></div>
              </div>
              <div class="col-5">
                <div class="row">
                  <div class="col-12">
                  <span
                    class="big-number"
                    [appDuration]="userStatsByYear.countersCourse.userCourseCompleted.totalCoursesHours"
                    [format]="'minutes'" [showNumberIfNullOrZero]="true">
                    </span><br>
<!--                    <span class="desc-report" translate>mandatory</span>-->
                  </div>
                </div>
                <div class="row mt-3">
<!--                  <div class="col-12">-->
<!--                    <span class="small-number"-->
<!--                          [appDuration]="userStatsByYear.countersCourse.userCourseCompleted.optionalTotalCoursesHours"-->
<!--                          [format]="'minutes'" [showNumberIfNullOrZero]="true">-->
<!--                    </span><br>-->
<!--                    <span class="desc-report" translate>optional</span>-->
<!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </div>
    <div class="table-filters mt-2 pl-4 pr-4">
      <div class="row" *ngIf="!loadingReport && loadingReport != null">
        <div class="col-sm-5 form-group">
          <input type="text" #searchInput (input)="onStatusChangeReport(searchInput.value)"
                 placeholder="{{'Search for title' | translate}}" [(ngModel)]="title"
                 class="form-control search-input "
                 data-ng-model="searchKeywords"
                 data-ng-keyup="search()">
        </div>
        <div class="col-sm-3 form-group">
          <select class="form-control" #searchSelect [(ngModel)]="status"
                  (change)="onStatusChangeReport($event)">
            <option value="Select"><span translate>Select a status</span></option>
            <option value="COMPLETED" translate>completed</option>
            <option value="INCOMPLETE" translate>started</option>
            <option value="NOT ATTEMPTED" translate>not started</option>
          </select>
        </div>
        <div class="col-sm-3 form-group">
          <select class="form-control" #searchYear [(ngModel)]="yearReport"
                  (change)="onStatusChangeReport($event)">
            <option value="0"><span translate>Select a year</span></option>
            <option [value]="year" *ngFor="let year of years;">{{ year }}</option>
          </select>
        </div>
        <div class="col-sm-12 filter-result-info pull-right text-right">
          <app-elements-count
            *ngIf="courses"
            [elementsCount]="courses"
            [totalCount]="allCourses">
          </app-elements-count>
        </div>
      </div>
    </div>

    <div [spinner]="loadingReport"></div>
    <div *ngIf="courses" class="pl-4 pr-4">
      <table class="table table-bordered cf" id="user-report-table">
        <thead class="cf">
        <tr>
          <th>
            <div>
              <span translate>Course Id</span>
              <div (click)="sortBy('id_course')">
                <i [ngClass]="{'disable' : descending === true}" class="fa fa-caret-down"></i>
                <i [ngClass]="{'disable' : descending === false}" class="fa fa-caret-up"></i>
              </div>
            </div>
          </th>
          <th>
            <div>
              <span translate>Course Title</span>
              <div (click)="sortBy('course_title')">
                <i [ngClass]="{'disable' : descending === true}" class="fa fa-caret-down"></i>
                <i [ngClass]="{'disable' : descending === false}" class="fa fa-caret-up"></i>
              </div>
            </div>
          </th>
          <th>
            <div>
              <span translate>Path Title</span>
              <div (click)="sortBy('path_title')">
                <i [ngClass]="{'disable' : descending === true}" class="fa fa-caret-down"></i>
                <i [ngClass]="{'disable' : descending === false}" class="fa fa-caret-up"></i>
              </div>
            </div>
          </th>
          <th *ngIf="(theme | async).config.userReportColumns.indexOf('status')>-1">
            <div>
              <span translate>Status</span>
              <div (click)="sortBy('state')">
                <i [ngClass]="{'disable' : descending === true}" class="fa fa-caret-down"></i>
                <i [ngClass]="{'disable' : descending === false}" class="fa fa-caret-up"></i>
              </div>
            </div>
          </th>
          <th class="hidden-md" *ngIf="(theme | async).config.userReportColumns.indexOf('completedOn')>-1">
            <div>
              <span translate>Completed on</span>
              <div (click)="sortBy('date_completed')">
                <i [ngClass]="{'disable' : descending === true}" class="fa fa-caret-down"></i>
                <i [ngClass]="{'disable' : descending === false}" class="fa fa-caret-up"></i>
              </div>
            </div>
          </th>
          <th class="hidden-sm" *ngIf="(theme | async).config.userReportColumns.indexOf('length')>-1">
            <div>
              <span translate>Creditable duration</span>
              <div (click)="sortBy('totalDuration')">
                <i [ngClass]="{'disable' : descending === true}" class="fa fa-caret-down"></i>
                <i [ngClass]="{'disable' : descending === false}" class="fa fa-caret-up"></i>
              </div>
            </div>
          </th>
          <th>
            <div>
              <span translate>Action</span>
            </div>
          </th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let course of courses">
          <td class="text-width">{{ course.id_course }}
          </td>
          <td class="text-width">{{ course.course_title }}
          </td>
          <td class="text-width">{{ course.path_title }}
          </td>
          <td *ngIf="(theme | async).config.userReportColumns.indexOf('status')>-1"><span
            [statusIcon]="course.state"></span></td>
          <td *ngIf="(theme | async).config.userReportColumns.indexOf('completedOn')>-1" class="tcenter hidden-md">
            <span *ngIf="course.date_completed">{{ course.date_completed| date:'dd/MM/yyyy' }}</span>
            <span *ngIf="!course.date_completed">-</span>
          </td>
          <td *ngIf="(theme | async).config.userReportColumns.indexOf('length')>-1"
              class="tright hidden-md"><span [appDuration]="course.totalDuration" [format]="'minutes'"></span></td>
          <td>
            <button class="btn  btn-enter" *ngIf="course.userCatalogAssociation" translate
                    [tooltip]="'go to course' | translate"
                    [routerLink]="['/path', course.id_path, 'course', course.id_course]"><i class="fa fa-play"></i>
            </button>&nbsp;
            <button class="btn btn-primary  btn-enter"
                    *ngIf="course.hasCertificateEntity && course.state === 'COMPLETED' && course?.date_completed?.getFullYear() == currentYear"
                    translate
                    [tooltip]="'view certificate' | translate"
                    (click)="viewCertificate(course)"><i class="fa fa-file-signature"></i></button>
          </td>
        </tr>

        </tbody>
      </table>
    </div>
    <div *ngIf="allCourses" class="pl-4 pr-4">
      <pagination
        [totalItems]="filteredCourses?.length"
        [itemsPerPage]="20"
        (pageChanged)="pageChanged($event)"
        [boundaryLinks]="true"
        [firstText]="labels['first']"
        [previousText]="labels['previous']"
        [nextText]="labels['next']"
        [lastText]="labels['last']"
      ></pagination>
    </div>

  </div>

</div>
