import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  constructor(private translateService: TranslateService) {
  }

  language: string;

  ngOnInit() {
    this.language = this.translateService.currentLang;
  }

}
