export enum UIMessageType {
  INFO,
  SUCCESS,
  WARNING,
  DANGER
}

export class UIMessage {

  type: UIMessageType;
  text: string;

  constructor (type: UIMessageType = UIMessageType.INFO, text: string) {
    this.type = type;
    this.text = text;
  }
}
