<div class="carousel slide"  [style.background-color]="backgroundColor">
  <ol class="carousel-indicators">
    <li *ngFor="let slide of templateRefs; let i = index;"  [ngClass]="{'active': +activeSlide === +i}" (click)="goToSlide(i)"></li>
  </ol>
  <div class="carousel-inner">
    <div class="item carousel-item" [ngStyle]="{'min-height.px': minHeight}" *ngFor="let slide of templateRefs; let i = index;" [ngClass]="{
                'active': +activeSlide === +i,
                'carousel-item-prev': prevSlide === i && slideWayS === 'right' ,
                'carousel-item-next': prevSlide === i && slideWayS === 'left' ,
                'carousel-item-right': (prevSlide === i || activeSlideClass === i) && slideWay == 'right',
                'carousel-item-left': (prevSlide === i || activeSlideClass === i) && slideWay == 'left'}">
      <div class="item active">
        <ng-container *ngTemplateOutlet="slide.templateRef"></ng-container>
      </div>
    </div>
  </div>
  <a class="left carousel-control carousel-control-prev" (click)="prevSlideAction()">
    <span class="icon-next carousel-control-prev-icon"></span>
    <span class="sr-only"></span>
  </a>
  <a class="left carousel-control carousel-control-next" (click)="nextSlideAction()">
    <span class="icon-next carousel-control-next-icon"></span>
    <span class="sr-only"></span>
  </a>
</div>
