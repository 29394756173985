import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CustomField} from './custom-field';
import {filter, map} from 'rxjs/operators';
import {select} from "@angular-redux/store";
import {Field} from "../fields/model";

@Injectable()
export class CustomFieldService {


  @select(['user', 'fields'])
  readonly customFields: Observable<Map<String, Field>>;

  constructor() {

  }

  getField(name: string): Observable<CustomField> {
    return this.customFields.pipe(
      filter(fields => !!fields),
      map(d => d[name])
    );
  }


}
