import {Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NotificationsService} from 'angular2-notifications';
import 'rxjs';
import {ExternalCourse} from './model';
import {ExternalCourseService} from './external-course.service';
import {AppEnvService} from '../../../config/env/service';


@Component({
  selector: 'app-external-courses',
  templateUrl: './external-courses.component.html',
  styleUrls: ['./external-courses.component.scss']
})
export class ExternalCoursesComponent implements OnInit {
  externalCourses: ExternalCourse[];
  modalRef: BsModalRef;
  bannerTitle: string;
  bannerDescription: string;
  bsValue1 = new Date();
  bsValue2 = new Date();
  bsRangeValue: Date[];
  maxDate = new Date();
  model: ExternalCourse;

  constructor(
    private http: HttpClient,
    private service: ExternalCourseService,
    private modalService: BsModalService,
    private router: Router,
    private translateService: TranslateService,
    private notificationSrv: NotificationsService,
    private environmentService: AppEnvService
  ) {
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsRangeValue = [this.bsValue1, this.maxDate];
  }

  ngOnInit() {
    let subDescription = 'Use this area to save the training courses you carried out outside the platform and with different providers';
    // TODO fix this!!
    // if (this.environmentService.folderResourceName === 'nobis') {
    //   subDescription = 'Use this area to save the training courses you carried out with outside Nobis Filo Diretto';
    // }
    this.translateService.stream(['External training', subDescription]).subscribe(
      t => {
        this.bannerTitle = t['External training'];
        this.bannerDescription = t[subDescription];
      }
    );
    this.service.externalCourses().subscribe(items => this.externalCourses = items);
  }

  openModal = (template: TemplateRef<any>, sender: Object) => {
    this.model = new ExternalCourse();
    this.model.durationDate = new Date();
    this.model.durationDate.setHours(1);
    this.model.durationDate.setMinutes(0);
    this.modalRef = this.modalService.show(template, {class: 'modal-lg modal-new'});
  }

  onModalCancel = event => {
    event.preventDefault();
    this.modalRef.hide();
  }

  onFileChange = sender => {
    if (sender.target.files && sender.target.files.length) {
      this.model.file = sender.target.files[0];
      this.model.fileName = this.model.file.name;
    }
  }

  save() {
    const formData = new FormData();
    formData.set(
      'request',
      JSON.stringify({
        title: this.model.title,
        description: this.model.description,
        duration: this.model.durationDate.getHours() * 60 + this.model.durationDate.getMinutes(),
        trainingStartDate: this.model.trainingDateFrom,
        trainingEndDate: this.model.trainingDateTo
      })
    )
    formData.append('file', this.model.file);

    const req = new HttpRequest('POST', `${this.environmentService.config().api.external.courses}/me`, formData, {reportProgress: true});

    this.http.request(req).subscribe(event => {
      if (event instanceof HttpResponse || (event.type === HttpEventType.ResponseHeader && event.status === 200)) {
        this.modalRef.hide();
        this.translateService.get('Element saved successfully').subscribe(
          t => this.notificationSrv.success(t)
        );
        const listSub = this.service.externalCourses().subscribe(items => {
          this.externalCourses = items;
          listSub.unsubscribe();
        });
      }
    });
  }

  onCourseDetails = course => {
    this.router.navigate(['externalcourse', course.id]);
  }
}
