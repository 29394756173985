<div>
  <div class="modal-header">
    <h5 class="modal-title pull-left"></h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <iframe id="iframeId" *ngIf="contentUrl" [src]="contentUrl" (load)="onLoad()">
    </iframe>
    <div id="overlay" (click)="nextCourse()"><i class="fa fa-caret-right"></i></div>
  </div>
</div>
