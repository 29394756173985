export class UrlUtils {
  split = (url: string, separator = '.') => {
    const parts = url.split(separator);
    return parts.length > 1 ? parts : []
  };
  hostname = (url: string, defaultValue = 'default', furtherSplittingSeparator = null) => {
    const [h = defaultValue] = this.split(url);
    if (furtherSplittingSeparator && h !== defaultValue) {
      const f = h.split(furtherSplittingSeparator);
      return f.length > 1 ? f[0] : h
    }
    return h
  }
}
