import {CourseResourceReport} from '../_models/course-resource-report';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppEnvService} from '../../config/env/service';
import {Stats} from '../../user/stats/model';
import {map} from 'rxjs/operators';
import {CourseReport} from '@shared/app/report/_models/course-report';

@Injectable()
export class ReportService {

  constructor(
    private http: HttpClient,
    private appEnvService: AppEnvService
  ) {
  }

  getUserReport(): Observable<CourseReport[]> {
    return this.http.get<CourseReport[]>(this.appEnvService.config().api.user.report).pipe(map(it => {
      it.map(r => {
        r.date_completed = r.date_completed ? new Date(r.date_completed) : null;
        return r;
      });
      return it;
    }));
  }

  getStats(year?: string): Observable<Stats> {
    return this.http.get<Stats>(this.appEnvService.config().api.user.stats  + (year ? `?year=${year}` : ''));
  }

}
