import {Injectable} from "@angular/core";
import {dispatch} from "@angular-redux/store";
import {FluxStandardAction} from "flux-standard-action";
import {PathsState} from "./reducers";
import {LmsPath} from "./model";


export type PathsPayload = PathsState | Array<LmsPath> | LmsPath | any;
export type PathsAction<T extends PathsPayload = undefined> = FluxStandardAction<string, T, any>;


@Injectable()
export class LmsPathsActions {
  static readonly PATHS_LOAD = 'PATHS_LOAD';
  static readonly PATHS_LOAD_STARTED = 'PATHS_LOAD_STARTED';
  static readonly PATHS_LOAD_FAILED = 'PATHS_LOAD_FAILED';
  static readonly PATHS_LOAD_SUCCEED = 'PATHS_LOAD_SUCCEED';
  static readonly PATH_OPEN = 'PATH_OPEN';
  static readonly PATH_OPENING = 'PATH_OPENING';
  static readonly PATH_OPEN_FAILED = 'PATH_OPEN_FAILED';
  static readonly PATH_OPENED = 'PATH_OPENED';

  static readonly PATH_LOAD_COURSES = 'PATH_LOAD_COURSES';
  static readonly PATH_COURSES_LOADING = 'PATH_COURSES_LOADING';

  @dispatch()
  loadPathList = (): PathsAction => ({
    type: LmsPathsActions.PATHS_LOAD,
    meta: {}
  });

  loadStarted = (): PathsAction => ({
    type: LmsPathsActions.PATHS_LOAD_STARTED,
    meta: {},
  });

  loadFailed = (error: any): PathsAction<any> => ({
    type: LmsPathsActions.PATHS_LOAD_FAILED,
    meta: {},
    payload: error
  });

  loaded = (paths: Array<LmsPath>): PathsAction<Array<LmsPath>> => ({
    type: LmsPathsActions.PATHS_LOAD_SUCCEED,
    meta: {},
    payload: paths
  });

  @dispatch()
  openPath = (id: number): PathsAction => ({
    type: LmsPathsActions.PATH_OPEN,
    meta: id
  });

  pathOpening = (): PathsAction => ({
    type: LmsPathsActions.PATH_OPENING,
    meta: {}
  });

  pathOpenError = (error: any): PathsAction<any> => ({
    type: LmsPathsActions.PATH_OPEN_FAILED,
    meta: {},
    payload: error
  });

  pathOpened = (path: LmsPath): PathsAction<LmsPath> => ({
    type: LmsPathsActions.PATH_OPENED,
    meta: {},
    payload: path
  });

  @dispatch()
  loadPathCourses = (id: number): PathsAction => ({
    type: LmsPathsActions.PATH_LOAD_COURSES,
    meta: id
  });



}
