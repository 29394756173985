import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';
import {DomSanitizer} from '@angular/platform-browser';
import {Resource} from "../../lms/resources/model";
import {CourseService} from "../../lms/courses/course.service";
import {ResourceService} from "../../lms/resources/resource.service";
import {AuthenticationService} from "../../authentication/service";
import {CourseXapi} from "../model";

@Component({
  selector: 'app-course-xapi',
  templateUrl: './course-xapi.component.html',
  styleUrls: ['./course-xapi.component.scss']
})
export class CourseXapiComponent implements OnInit, OnDestroy {

    _resource: Resource;
    course: CourseXapi;
    subscriptions: Subscription[] = [];

    @Input()
    set resource (r: Resource) {
        this._resource = r;
        this.course = Object.assign(new CourseXapi(), this._resource.metadata);
        this.course.resourceId = this._resource.id;
        this.course.courseId = this._resource.courseId;
        this.course.pathId = this._resource.pathId;
        this.course.catalogId = this._resource.catalogId;
        this.course.duration = this._resource.duration;
        this.resourceLoaded.emit(this.course);
    }

    @Output() resourceLoaded = new EventEmitter<CourseXapi>();

    constructor(
        private route: ActivatedRoute,
        private courseService: CourseService,
        private resourceService: ResourceService,
        private modalService: BsModalService,
        private sanitizer: DomSanitizer,
        private authService: AuthenticationService) {
    }

    ngOnInit() {
        // const test = new StartPaperComponent();
    }

    ngOnDestroy() {
        this.unsubscribe();
    }

    unsubscribe() {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    }


}
