import {Injectable} from '@angular/core';

import {combineEpics} from 'redux-observable';

import {AuthenticationEpics} from "../authentication/store/epics";
import {UserEpics} from "../user/store/epics";
import {BootEpics} from "../boot/store/epics";
import {ThemeEpics} from "../theme/store/epics";
import {LmsEpics} from "../lms/store/epics";

@Injectable()
export class RootEpics {
  constructor(
    private bootEpics: BootEpics,
    private authEpics: AuthenticationEpics,
    private themeEpics: ThemeEpics,
    private userEpics: UserEpics,
    private lmsEpics: LmsEpics
  ) {
  }

  createEpics = (): any => combineEpics(
    this.bootEpics.create(),
    this.authEpics.create(),
    this.themeEpics.create(),
    this.userEpics.create(),
    this.lmsEpics.create()
  )

}
