
<div class="courses-container" *ngIf="groupedCourses">
	<div *ngFor="let theme of coursesKeys" class="courses-theme-group">
		<div class="course-theme-banner" [innerHtml]="theme | translate"></div>
	   	<div class="course-item clickable" *ngFor="let course of groupedCourses.get(theme); let i = index" (click)="openCourse(course)">
			<div class="course-box">
				<div class="course-box-status" [ngClass]="course.statusView"></div>
				
				<div class="course-box-text">
					<div class="course-box-index">
						{{course.order}}.
					</div>
					<div class="course-box-title">
						{{course.title}}
            <div class="course-icons">
              <i *ngIf="course.allowView==false" class="fa fa-exclamation-circle" tooltip="{{'To view this element you must complete the previous ordered mandatory items' | translate}}"></i>
              <i *ngIf="course.orderMandatory && course.allowView" class="fa fa-eye" tooltip="{{'This item is mandatory and must be seen in the specified order' | translate}}"></i>
            </div>
					</div>
				</div>
				
				<div class="course-box-icon">
					<i class="fa fa-plus"></i>
				</div>
			</div>
		</div>
		
	</div>
	
</div>

<span class="hidden" translate>Other</span>
