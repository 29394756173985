import {NgModule} from '@angular/core';
import {SharedRoutingModule} from './routing';
import {AppModule} from '../../../../src/app/lms.module';
import {AppComponent} from '../../../../src/app/component';
import {
  AdministrativeUserImpersonationComponent
} from '../../../../src/app/authentication/impersonation/administrative-user-impersonation.component';
import {RefreshTokenLoginComponent} from '@shared/app/authentication/refresh-token/refresh-token-login.component';


@NgModule({
  declarations: [ AdministrativeUserImpersonationComponent, RefreshTokenLoginComponent ],
  imports: [
    AppModule,
    SharedRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class SharedAppModule {

}
