import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Course} from "../../courses/model";

@Component({
  selector: 'app-path-courses',
  templateUrl: './path-courses.component.html',
  styleUrls: ['./path-courses.component.scss']
})
export class PathCoursesComponent implements OnInit {

  groupedCourses: Map<string, Course[]>;
  coursesKeys: string[];

  @Input()
  set courses(crs: Map<string, Course[]>) {
    this.groupedCourses = crs;
    if (crs != null) {
      this.coursesKeys = Array.from(crs.keys());
    }
  }

  @Output()
  courseSelected = new EventEmitter<Course>();

  constructor() {
  }

  ngOnInit() {
  }

  openCourse(c: Course) {
    this.courseSelected.emit(c);
  }

}
