import {Injectable} from '@angular/core';
import {dispatch} from '@angular-redux/store';
import {FluxStandardAction} from 'flux-standard-action';
import {AuthError, LoginError, RefreshToken, UserAuth, UserCredentials, UserImpersonate} from '../model';
import {CasProvider} from '../cas/model';

// Flux-standard-action gives us stronger typing of our actions.
export type AuthPayload = UserCredentials | UserAuth | UserImpersonate | AuthError | LoginError | any;
export type AuthAction<T extends AuthPayload = undefined> = FluxStandardAction<string, T, any>;

@Injectable()
export class AuthActions {
  static readonly AUTHENTICATE_USER = 'AUTHENTICATE_USER';
  static readonly AUTH_CHECK_TOKEN = 'AUTH_CHECK_TOKEN';
  static readonly AUTH_FOUND_TOKEN = 'AUTH_FOUND_TOKEN';
  static readonly USER_LOGIN = 'USER_LOGIN';
  static readonly USER_LOGIN_STARTED = 'USER_LOGIN_STARTED';
  static readonly USER_LOGIN_SUCCEEDED = 'USER_LOGIN_SUCCEEDED';
  static readonly USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
  static readonly USER_LOGOUT = 'USER_LOGOUT';
  static readonly USER_LOGGED_OUT = 'USER_LOGGED_OUT';
  static readonly USER_AUTHENTICATION_STARTED = 'USER_AUTHENTICATION_STARTED';
  static readonly USER_AUTHENTICATION_SUCCEEDED = 'USER_AUTHENTICATION_SUCCEEDED';
  static readonly USER_AUTHENTICATION_FAILED = 'USER_AUTHENTICATION_FAILED';
  static readonly LOAD_CAS_PROVIDERS = 'LOAD_CAS_PROVIDERS';
  static readonly CAS_PROVIDERS_LOADED = 'CAS_PROVIDERS_LOADED';
  static readonly CAS_PROVIDERS_FAILED = 'CAS_PROVIDERS_FAILED';
  static readonly USER_IMPERSONATE_LOGIN = 'USER_IMPERSONATE_LOGIN';
  static readonly USER_IMPERSONATE_LOGIN_STARTED = 'USER_IMPERSONATE_LOGIN_STARTED';
  static readonly USER_IMPERSONATE_LOGOUT = 'USER_IMPERSONATE_LOGOUT';
  static readonly USER_IMPERSONATE_LOGIN_SUCCEEDED = 'USER_IMPERSONATE_LOGIN_SUCCEEDED';
  static readonly USER_IMPERSONATE_LOGIN_FAILED = 'USER_IMPERSONATE_LOGIN_FAILED';
  static readonly USER_IMPERSONATE_LOGOUT_STARTED = 'USER_IMPERSONATE_LOGOUT_STARTED';
  static readonly USER_IMPERSONATE_LOGOUT_SUCCEEDED = 'USER_IMPERSONATE_LOGOUT_SUCCEEDED';
  static readonly USER_IMPERSONATE_LOGOUT_FAILED = 'USER_IMPERSONATE_LOGOUT_FAILED';
  static readonly USER_REFRESH_TOKEN_LOGIN = 'USER_REFRESH_TOKEN_LOGIN';
  static readonly USER_REFRESH_TOKEN_LOGIN_STARTED = 'USER_REFRESH_TOKEN_LOGIN_STARTED';


  @dispatch()
  authCheckToken = (): AuthAction => ({
    type: AuthActions.AUTH_CHECK_TOKEN,
    meta: {}
  })

  authFoundToken = (token): AuthAction => ({
    type: AuthActions.AUTH_CHECK_TOKEN,
    meta: {},
    payload: token
  })

  @dispatch()
  authenticateUser = (): AuthAction => ({
    type: AuthActions.AUTHENTICATE_USER,
    meta: {},
  })

  @dispatch()
  loginUser = (credentials: UserCredentials): AuthAction<UserCredentials> => ({
    type: AuthActions.USER_LOGIN,
    meta: {},
    payload: {...credentials}
  })

  @dispatch()
  logoutUser = (): AuthAction => ({
    type: AuthActions.USER_LOGOUT,
  })

  @dispatch()
  loadCasProviders = (): AuthAction => ({
    type: AuthActions.LOAD_CAS_PROVIDERS,
  })

  @dispatch()
  impersonateLogin = (userId: number, sender: string = 'ui'): AuthAction<UserImpersonate> => ({
    type: AuthActions.USER_IMPERSONATE_LOGIN,
    meta: {},
    payload: {userId, sender}
  })

  @dispatch()
  impersonateLogout = (): AuthAction => ({
    type: AuthActions.USER_IMPERSONATE_LOGOUT,
    meta: {},
  })

  loginStarted = (): AuthAction => ({
    type: AuthActions.USER_LOGIN_STARTED,
    meta: {},
  })

  loginFailed = (loginError: LoginError): AuthAction<LoginError> => ({
    type: AuthActions.USER_LOGIN_FAILED,
    meta: {},
    payload: {...loginError}
  })

  loginSucceeded = (auth: UserAuth): AuthAction<UserAuth> => ({
    type: AuthActions.USER_LOGIN_SUCCEEDED,
    meta: {},
    payload: {...auth}
  })

  authenticationStarted = (): AuthAction => ({
    type: AuthActions.USER_AUTHENTICATION_STARTED,
    meta: {},
  })

  authenticationSucceeded = (auth: UserAuth): AuthAction<UserAuth> => ({
    type: AuthActions.USER_AUTHENTICATION_SUCCEEDED,
    meta: {},
    payload: {...auth}
  })

  authenticationFailed = (authError: AuthError): AuthAction<AuthError> => ({
    type: AuthActions.USER_AUTHENTICATION_FAILED,
    meta: {},
    payload: {...authError}
  })

  userLoggedOut = () => ({
    type: AuthActions.USER_LOGGED_OUT,
  })

  casProvidersLoaded = (casProviders: CasProvider[]): AuthAction<any> => ({
    type: AuthActions.CAS_PROVIDERS_LOADED,
    meta: {},
    payload: casProviders
  })

  casProvidersFailed = (authError: AuthError): AuthAction<AuthError> => ({
    type: AuthActions.CAS_PROVIDERS_FAILED,
    meta: {},
    payload: {...authError}
  })

  impersonateLoginStarted = (): AuthAction<AuthError> => ({
    type: AuthActions.USER_IMPERSONATE_LOGIN_STARTED,
    meta: {},
  })

  impersonateLoginSucceeded = (auth: UserAuth): AuthAction<UserAuth> => ({
    type: AuthActions.USER_IMPERSONATE_LOGIN_SUCCEEDED,
    meta: {},
    payload: {...auth}
  })

  impersonateLoginFailed = (authError: AuthError): AuthAction<AuthError> => ({
    type: AuthActions.USER_IMPERSONATE_LOGIN_FAILED,
    meta: {},
    payload: {...authError}
  })

  impersonateLogoutSucceeded = (auth: UserAuth): AuthAction<UserAuth> => ({
    type: AuthActions.USER_IMPERSONATE_LOGOUT_SUCCEEDED,
    meta: {},
    payload: {...auth}
  })

  impersonateLogoutStarted = (): AuthAction => ({
    type: AuthActions.USER_IMPERSONATE_LOGOUT_STARTED,
    meta: {},
  })
  impersonateLogoutFailed = (authError: AuthError): AuthAction<AuthError> => ({
    type: AuthActions.USER_IMPERSONATE_LOGOUT_FAILED,
    meta: {},
    payload: {...authError}
  })

  @dispatch()
  refreshTokenLogin = (temToken: string): AuthAction<RefreshToken> => ({
    type: AuthActions.USER_REFRESH_TOKEN_LOGIN,
    meta: {},
    payload: {tempToken: temToken}
  })
  refreshLoginLoginStarted = (): AuthAction<AuthError> => ({
    type: AuthActions.USER_REFRESH_TOKEN_LOGIN_STARTED,
    meta: {},
  })


}
