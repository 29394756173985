import {Directive, ElementRef, Input, OnInit, TemplateRef} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

@Directive({
  selector: '[spinner]',
})
export class SpinnerDirective implements OnInit {

  private _loading = false;

  private _loadingString =  'loading';

  constructor(
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private translateService: TranslateService) {

    this.translateService.stream([this._loadingString]).subscribe(
      t => this._loadingString = t[this._loadingString]
    );
  }

  @Input()
  set spinner(val: boolean) {
    this._loading = val;
    this.refresh();
  }

  ngOnInit() {
  }

  private refresh() {
    if (this._loading) {
      this.elementRef.nativeElement.innerHTML = `<i class="fa fa-spinner fa-spin"></i>&nbsp;<span translate>${this._loadingString}</span>`;
    } else {
      this.elementRef.nativeElement.innerHTML = '';
    }
  }

}
