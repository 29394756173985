import {Action} from "redux";
import {BootAction, BootActions} from "./actions";
import {BootError} from "../model";


export interface BootState {
  bootStarted: boolean,
  bootFailed: boolean,
  bootCompleted: boolean,
  bootReturnUrl: string,
  error: BootError
}

const INITIAL_STATE: BootState = {
  bootStarted: false,
  bootFailed: false,
  bootCompleted: false,
  bootReturnUrl: undefined,
  error: undefined
};

export const bootReducer = () => (state: BootState = INITIAL_STATE, action: Action): BootState => {
  switch (action.type) {
    case BootActions.START_BOOT:
      return {
        ...state,
        bootReturnUrl: (action as BootAction<string>).payload
      };
    case BootActions.BOOT_STARTED:
      return {
        ...state,
        bootStarted: true,
      };
    case BootActions.BOOT_FAILED:
      return {
        ...state,
        bootStarted: false,
        bootFailed: true,
        error: {...(action as BootAction<BootError>).payload}
      };
    case BootActions.BOOT_COMPLETED:
      return {
        ...state,
        bootCompleted: true,
        bootStarted: false,
        bootFailed: false,
      };
  }
  return state;
};

