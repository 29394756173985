import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ThemeArea} from "../../themeAreas/model";
import {CourseService} from "../../courses/course.service";
import {Path} from "../../paths/model";

@Component({
  selector: 'app-catalog-by-theme-area',
  templateUrl: './catalog-by-theme-area.component.html',
  styleUrls: ['./catalog-by-theme-area.component.scss']
})
export class CatalogByThemeAreaComponent implements OnInit, OnDestroy {

  areas: ThemeArea[];
  areaSelected: ThemeArea;
  bannerTitle: string;
  bannerDescription: string;
  view: string;
  paths: Path[];
  subscriptions: Subscription[] = new Array<Subscription>();

  constructor(
    private courseService: CourseService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService) {
  }

  ngOnInit() {
    this.translateService.stream([
      'Catalog',
      'Browse and choose additional courses to enroll in.'
    ]).subscribe(t => {
      this.bannerTitle = t['Catalog'];
      // tslint:disable-next-line:max-line-length
      this.bannerDescription = t['Browse and choose additional courses to enroll in.'];
    });
    this.subscriptions.push(this.route.queryParams.subscribe(params => {
      const viewingAreaId = params['a'];
      if (viewingAreaId) {
        let vieweingArea: ThemeArea;
        if (this.areas) {
          for (const _area of this.areas) {
            if (viewingAreaId == _area.id) {
              vieweingArea = _area;
            }
          }
          if (vieweingArea) {
            this.view = 'AREADETAILS';
            this.areaSelected = vieweingArea;
            this.subscriptions.push(
              this.courseService.getAreaPaths(vieweingArea).subscribe(areaPaths => this.paths = areaPaths)
            );
          }
        } else {
          this.subscriptions.push(this.courseService.getAllAreas().subscribe(a => {
            this.areas = a;
            for (const _area of this.areas) {
              if (viewingAreaId == _area.id) {
                vieweingArea = _area;
              }
            }
            if (vieweingArea) {
              this.view = 'AREADETAILS';
              this.areaSelected = vieweingArea;
              this.subscriptions.push(
                this.courseService.getAreaPaths(vieweingArea).subscribe(areaPaths => this.paths = areaPaths)
              );
            }
          }));
        }
      } else {
        this.view = 'AREASLIST';
        this.subscriptions.push(
          this.courseService.getAllAreas().subscribe(a => this.areas = a)
        );
      }
    }));
  }

  ngOnDestroy() {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  selectArea(a: ThemeArea) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: {
        a: a.id
      }
    });
  }

  openPath(path) {
    this.router.navigate(['catalogcourse', path.id]);
  }

  enrollToPath(path: Path) {
    this.courseService.enrollPath(path).subscribe(
      data => {
        if (data === true) {
          path.enrolled = true;
        }
      },
      err => {
      }
    );
  }

}
