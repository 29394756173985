import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {ExternalCourse} from "../courses/model";
import {ExternalCourseService} from "../courses/external-course.service";

@Component({
  selector: 'app-external-course',
  templateUrl: './external-course.component.html',
  styleUrls: ['./external-course.component.scss']
})
export class ExternalCourseComponent implements OnInit {
  subscriptions: Subscription[] = [];
  id: number;
  course: ExternalCourse;
  loading = false;
  errorMessage: string;


  constructor(private route: ActivatedRoute, private router: Router, private service: ExternalCourseService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.route.params.subscribe(params => {
        this.id = +params['id'];
        this.loading = true;
        this.service.externalCourse(this.id).subscribe(course => {
          this.course = course;
          this.course.trainingDateFrom = course['trainingStartDate'];
          this.course.trainingDateTo = course['trainingEndDate'];
          this.loading = false;
        });
      })
    );
  }
  onBackToExternalCourses = (sender: Object) => this.router.navigate(['externalcourses']);
}
