import {Injectable} from '@angular/core';
import {AppEnvService} from '../config/env/service';
import {ActivatedRoute} from '@angular/router';
import {ThemeActions} from './store/actions';
import {HttpClient} from '@angular/common/http';
import {ICustomThemeConfig} from './model';
import {UrlUtils} from '../utils/urlUtils';

@Injectable()
export class ThemeService {

  private readonly configUrl: string;

  constructor(
    private env: AppEnvService,
    private route: ActivatedRoute,
    private actions: ThemeActions,
    private http: HttpClient
  ) {
    this.configUrl = `${this.env.config().api.theme.custom}`;
  }

  getThemeConfig = (themeName: string) => this.http.get<ICustomThemeConfig>(`${this.configUrl}/${themeName}`);

  getResourceFolderName(): string {
    // return this.env.config().env.resourcesPath;
    return '/data/sep-smw';
  }
}
