import {Component, OnDestroy, OnInit} from "@angular/core";
import {DomSanitizer, SafeHtml, SafeStyle} from "@angular/platform-browser";
import {select} from "@angular-redux/store";
import {Observable, Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {Theme} from "../../theme/model";
import {StatsCounters} from "../../user/stats/model";
import {ThemeState} from "../../theme/store/reducers";
import {AppEnvService} from "../../config/env/service";
import {
  BreadCrumbFactory,
  BreadcrumbLevel,
  BreadcrumbService,
  SepBreadcrumb
} from "../../common/_services/breadcrumb.service";
import {UserState} from "../../user/model";
import {filter, map} from "rxjs/operators";

@Component({
  moduleId: module.id,
  templateUrl: 'component.html',
  styleUrls: ['./component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  bgImage: SafeStyle;
  theme: Theme;
  title: SafeHtml;
  subtitle: SafeHtml;
  hoursCounter: number;
  coursesCounter: number;
  _counto: number;
  colors = [
    {
      backgroundColor: ['rgb(23, 162, 184)', 'rgb(32, 201, 151)', 'rgb(11, 66, 193)', 'rgb(255, 193, 7)'],
      borderColor: ['rgb(23, 162, 184)', 'rgb(32, 201, 151)', 'rgb(11, 66, 193)', 'rgb(255, 193, 7)']
    }
  ];
  counters: StatsCounters;

  @select('theme')
  readonly themeState: Observable<ThemeState>;

  @select(['user', 'details'])
  readonly userState: Observable<UserState>;

  subscriptions: Subscription [] = [];

  constructor(
    private bcService: BreadcrumbService,
    private sanitizer: DomSanitizer,
    private envService: AppEnvService,
    private translateService: TranslateService
  ) {
    const b: SepBreadcrumb = (new BreadCrumbFactory).createBreadCrumb('Home', null, '/', BreadcrumbLevel.root, true);
    this.bcService.setBreadcrumb(b);
  }

  ngOnInit() {
    this.hoursCounter = 0;
    this.coursesCounter = 0;

    this.bgImage = this.sanitizer.bypassSecurityTrustStyle('url(assets/img/home-bg.jpg)');

    this.subscriptions.push(
    this.themeState.pipe(
      filter(state => !!state.config),
      map(state => {
        if (state.config.homeBackgroundImageUrl) {
          this.bgImage = this.sanitizer.bypassSecurityTrustStyle('url(' + state.config.homeBackgroundImageUrl + ')');
        }
        if (state.config && state.config.homeTitle) {
          this.title = this.sanitizer.bypassSecurityTrustHtml(state.config.homeTitle);
        }
        if (state.config && state.config.homeSubtitle) {
          this.subtitle = this.sanitizer.bypassSecurityTrustHtml(state.config.homeSubtitle);
        }
      })).subscribe()
  )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
