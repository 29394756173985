import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Injector,
  Input, OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {CalendarOptions, FullCalendarComponent} from '@fullcalendar/angular';
import {LocaleInput} from '@fullcalendar/common';
import {Subscription} from 'rxjs';
import {PopoverWrapperComponent} from '@shared/app/layout/calendar/calendar/popover-calendar';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {

  @Input('calendarOptions') public calendarOptionsPass: CalendarOptions;
  @Input('locale') locale: LocaleInput;
  @Input('popoverTmpl') popoverTmpl: TemplateRef<any>;

  @ViewChild('calendar', {static: false}) calendarComponent: FullCalendarComponent;

  calendarOptionsInit: CalendarOptions;
  calendarOptions: CalendarOptions;
  subscriptions: Subscription[] = [];
  popoversMap = new Map<any, ComponentRef<PopoverWrapperComponent>>();

  popoverFactory = this.resolver.resolveComponentFactory(PopoverWrapperComponent);

  colorsPalette = [
    {background: '#5F6D82'}, {background: '#8BB8B8'}, {background: '#9B8BAE'}, {background: '#D16944'}, {background: '#B05C48'},
    {background: '#BE6263'}, {background: '#EF9273'},
    {background: '#829F78'},
    {background: '#AEBD73'},
    {background: '#802a48', color: '#333333'},
    {background: '#EFD200', color: '#333333'}];

  constructor(
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {
  }

  ngOnInit(): void {
    this.calendarOptionsInit = {
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listYear'
      },
      initialView: 'dayGridMonth',
      navLinks: true,
      weekends: true,
      allDaySlot: false,
      locale: this.locale,
      height: 'auto',
      eventSources: [{
        color: 'red'
      }],
      slotMinTime: '07:00:00',
      slotMaxTime: '21:00:00',
      slotEventOverlap: false,
      eventDidMount: this.renderTooltip.bind(this),
      eventWillUnmount: this.destroyTooltip.bind(this),
      eventClick: this.showPopover.bind(this),
    };
    this.mergeConfig();
  }

  mergeConfig() {
    let index = 0;
    for (let event of this.calendarOptionsPass.eventSources) {
      const colorPalette = this.colorsPalette[index];
      index = this.colorsPalette[index + 1] ? index + 1 : 0;
      event['color'] = event['color'] ? event['color'] : colorPalette.background;
      event['backgroundColor'] = event['backgroundColor'] ? event['backgroundColor'] : colorPalette.background;
      event['textColor'] = event['textColor'] ? event['textColor'] : colorPalette['textColor'] ? colorPalette['color'] : '#FFFFFF';
      event['display'] = 'block';
    }

    this.calendarOptions = {...this.calendarOptionsInit, ...this.calendarOptionsPass};
  }

  updateEvent() {

  }


  renderTooltip(event) {
    this.destroyTooltip(event);
    const projectableNodes = Array.from(event.el.childNodes);
    const compRef = this.popoverFactory.create(this.injector, [projectableNodes], event.el);
    compRef.instance.title = event.event.title;
    compRef.instance.view = event.view.type;
    compRef.instance.values = event.event;
    compRef.instance.templatePass = this.popoverTmpl;
    this.appRef.attachView(compRef.hostView);
    this.popoversMap.set(event.el, compRef);
  }

  destroyTooltip(event) {
    const popover = this.popoversMap.get(event.el);
    if (popover) {
      this.appRef.detachView(popover.hostView);
      popover.destroy();
      this.popoversMap.delete(event.el);
    }
  }

  showPopover(event) {
    const popover = this.popoversMap.get(event.el);
    popover.instance.popover.show();
  }

  ngOnDestroy() {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }

  }

}
