import {ResourcePathAware} from "../../resources/model";
import {SafeResourceUrl} from "@angular/platform-browser";

export class ScormUnit extends ResourcePathAware {
  id: number;
  title: string;
  description: string;
  lessonStatus: string;
  type: UnitType;
  order: number;
  mandatory: boolean;
  locked: boolean;
  quizId: number;
  url: string | SafeResourceUrl;
  courseResourceId: number;
  duration: number;
  openInNewWindow: boolean;
  lockReason: string;
  imageUrl: string;
}

export enum UnitType {
  LESSONORQUIZ = 0,
  PRINT = 1,
  CERTIFICATE = 2,
  ATTACHMENT = 3,
  LIVE = 4
}
