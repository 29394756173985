import {Component, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Subscription} from 'rxjs';
import * as _ from 'lodash';
import {Path} from '../model';
import {ResourceService} from '../../resources/resource.service';
import {PathService} from '../path.service';
import {CourseService} from '../../courses/course.service';
import {BreadCrumbFactory, BreadcrumbLevel, BreadcrumbService, SepBreadcrumb} from '../../../common/_services/breadcrumb.service';
import {Session} from '@shared/app/lms/synchronous/course/model';
import {RolesRegistration, SessionState, UserSessionStatus, UserSessionStatusLang} from '@shared/app/lms/synchronous/model';
import {NotificationsService} from 'angular2-notifications';
import {ResourceType} from '@shared/app/lms/resources/model';

@Component({
  selector: 'app-catalog-course',
  templateUrl: './catalog-course.component.html',
  styleUrls: ['./catalog-course.component.scss']
})
export class CatalogCourseComponent implements OnInit, OnDestroy {

  id: number;
  path: Path;
  subscriptions: Subscription[] = [];
  errorMessage: string;
  loading = true;
  loadingEnroll: boolean;
  loadingAsyncSession: boolean;
  userSessionStatusLang = UserSessionStatusLang;
  UserSessionStatus = UserSessionStatus;
  RolesRegistration = RolesRegistration;
  resourceType = ResourceType;
  SessionState = SessionState;
  sessionIdToHeightLight: number;

  constructor(
    private resourceService: ResourceService,
    private pathService: PathService,
    private courseService: CourseService,
    private route: ActivatedRoute,
    private router: Router,
    private bcService: BreadcrumbService,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private notificationService: NotificationsService
  ) {
    this.loadingAsyncSession = false;
    this.loadingEnroll = false;
  }

  ngOnInit() {
    this.subscriptions.push(this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.sessionIdToHeightLight = params['sessionId'];
      this.getPathData();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  enrollToPath(path: Path) {
    this.loadingEnroll = true;
    this.courseService.enrollPath(path).subscribe(
      data => {
        this.loadingEnroll = false;
        if (data === true) {
          path.enrolled = true;
        } else {
          forkJoin(
            this.translateService.get('Warning'),
            this.translateService.get('An error occurred')
          ).subscribe(t => {
            this.notificationService.error(t[0], t[1], {timeOut: 5000});
          });
        }
      },
      err => {
        this.loadingEnroll = false;
        forkJoin(
          this.translateService.get('Warning'),
          this.translateService.get('An error occurred')
        ).subscribe(t => {
          this.notificationService.error(t[0], t[1], {timeOut: 5000});
        });
      }
    );
  }

  enrollToSession(session: Session) {
    if (this.loadingEnroll) {
      return;
    }
    this.loadingAsyncSession = true;
    session.isLoading = true;
    this.courseService.enrollSession(session).subscribe(
      data => {
        this.getPathData();
      },
      err => {
        console.log(err);
        session.isLoading = false;
        this.loadingAsyncSession = false;
        forkJoin(
          this.translateService.get('Warning'),
          this.translateService.get(err.error.message)
        ).subscribe(t => {
          this.notificationService.error(t[0], `${t[1]}`, {timeOut: 5000});
        });
      }
    );
  }

  removeFromSession(session: Session) {
    if (this.loadingEnroll) {
      return;
    }
    session.isLoading = true;
    this.loadingAsyncSession = true;
    this.courseService.removeFromSession(session).subscribe(
      data => {
        this.getPathData();
      },
      err => {
        console.log(err);
        session.isLoading = false;
        this.loadingAsyncSession = false;
        forkJoin(
          this.translateService.get('Warning'),
          this.translateService.get(err.error.message)
        ).subscribe(t => {
          this.notificationService.error(t[0], `${t[1]}`, {timeOut: 5000});
        });
      }
    );
  }

  goToPath(path: Path) {
    this.router.navigate(['path', path.id]);
  }

  orderedCourses = () => _.sortBy(this.path.courses, (c) => c.order);

  private getPathData() {
    this.loading = true;
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
    this.subscriptions.push(
      this.pathService.getPath(this.id).subscribe(
        p => {
          this.path = p;
          this.loading = false;
          this.loadingAsyncSession = false;
          this.translateService.get('Catalog')
            .subscribe(t => {
              const factory: BreadCrumbFactory = new BreadCrumbFactory();
              const bP: SepBreadcrumb = factory.createBreadCrumb(t[0], null, '/catalog', BreadcrumbLevel.first, true);
              this.bcService.setBreadcrumb(bP);
              const b: SepBreadcrumb = factory.createBreadCrumb(this.path.title, {id: this.path.id}, '/catalogcourse', BreadcrumbLevel.second, true);
              this.bcService.setBreadcrumb(b);
            });
          for (const course of this.path.courses) {
            course.isLoading = true;
            this.subscriptions.push(this.courseService.getCourse(course.id).subscribe(c => {
              course.isLoading = false;
              Object.assign(course, c);
              if (this.sessionIdToHeightLight) {
                const el = document.getElementById('session_' + this.sessionIdToHeightLight.toString());
                el?.scrollIntoView();
              }
            }));
          }
        },
        err => {
          this.loading = false;
          this.translateService.get('Impossible to display this course').subscribe(t => {
            this.errorMessage = t;
          });
        }
      )
    );
  }
}
