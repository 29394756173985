<app-banner *ngIf="path"
            [display]="'thin'"
            [title]="path!.title"
            [backgroundImage]="path.image"
            [colorSchema]="'light'"
></app-banner>

<div class="path-container page-template-thin">
  <div [spinner]="loading"></div>
  <div *ngIf="errorMessage" class="alert alert-danger">{{errorMessage}}</div>

  <div class="row" *ngIf="!loading && path">
    <div class="col-md-4 col-sm-12">
      <div class="path-info-box box-1">
        <div class="path-status" [ngClass]="path.statusView">
          <span class="path-status-text" translate>{{path.statusLabel}}</span>
        </div>
        <div class="path-details">
          <div class="path-details-row">
            <strong><i class="fa fa-book"></i>&nbsp;<span translate>Content</span>:</strong>&nbsp;<span
            *ngIf="path.courses">{{path.courses.length}} <span translate>courses</span></span>
          </div>
          <div class="path-details-row">
            <strong>
              <i class="fa fa-book"></i>&nbsp;<span class="text-capitalize" translate>duration</span>:
            </strong>
            <span>
								<span [appDuration]="path.totalLength" [format]="'minutes'"></span>
							</span>
            <span></span>
          </div>
          <div class="path-details-row" *ngIf="path.mandatory">
            <strong translate>mandatory</strong>
          </div>
        </div>
      </div>
      <div class="path-info-box box-2">
        <div class="path-description">
          <strong translate>Description</strong>
          <p [innerHTML]="path.description"></p>
        </div>
      </div>
    </div>

    <div [ngSwitch]="pathPerspective" class="col-md-8 col-sm-12" [ngClass]="pathPerspective">
      <div *ngSwitchCase="'PATH'" class="row">
        <app-path-courses class="col-12" [courses]="groupedCourses" (courseSelected)="openCourse($event)"></app-path-courses>
      </div>
      <div *ngSwitchCase="'COURSE'" class="row">
        <app-path-course class="col-12" [course]="course" (unitSelected)="openScormUnit($event)" (lessonSelected)="openLesson($event)"
                         (resourceSelected)="openResource($event)">
        </app-path-course>
      </div>
      <div *ngSwitchCase="'SINGLECOURSE'" class="row">
        <app-path-course class="col-12" [course]="path.courses[0]" (unitSelected)="openScormUnit($event)"
                         (lessonSelected)="openLesson($event)" (resourceSelected)="openResource($event)">
        </app-path-course>
      </div>
      <div *ngSwitchCase="'DYNAMIC_PATH'" class="row">
        <ngx-sl-flow-chart class="col-12"
          *ngIf="dynamicPath && dynamicPath?.config?.cells.length > 0 && pathPerspective == 'DYNAMIC_PATH'"
          #flowChart [config]="dynamicPath.config" [viewType]="'user'"></ngx-sl-flow-chart>
      </div>
    </div>
  </div>
</div>

<ng-template #unitTemplate>
  <div class="modal-header">
    <h5 class="modal-title pull-left">{{openedUnit && openedUnit.title}}</h5>
    <a class="btn close pull-right" (click)="closeScormUnit()">
      <span aria-hidden="true">&times;</span>
    </a>
  </div>
  <div class="modal-body">
    <iframe *ngIf="openedUnit" [src]="unitUrl"></iframe>
  </div>
</ng-template>

<ng-template #lessonTemplate>
  <div class="modal-header">
    <h5 class="modal-title pull-left">{{openedLesson && openedLesson.description}}</h5>
    <a class="btn close pull-right" (click)="closeLesson()">
      <span aria-hidden="true">&times;</span>
    </a>
  </div>
  <div class="modal-body">
    <iframe *ngIf="openedLesson" [src]="lessonUrl"></iframe>
  </div>
</ng-template>

<ng-template #certificateTemplate>
  <div class="modal-header">
    <h5 class="modal-title pull-left">{{openedCertificate && openedCertificate.title}}</h5>

    <a class="btn btn-sm btn-primary btn-download" (click)="downloadCertificate()"><i
      class="fa fa-download">&nbsp;</i><span translate>download</span></a>

    <a class="btn close pull-right" (click)="closeCertificate()">
      <span aria-hidden="true">&times;</span>
    </a>
  </div>
  <div class="modal-body">
    <div [spinner]="resourceLoading" class="resource-spinner"></div>
    <div *ngIf="certErrorMessage" class="alert alert-danger">{{certErrorMessage}}</div>
    <iframe [attr.src]="certificateSrc" type="application/pdf"></iframe>
  </div>
</ng-template>

<ng-template #resourceUsedWarningTemplate>
  <div class="modal-header">
    <h5 class="modal-title pull-left" translate>Warning</h5>
    <button type="button" class="close pull-right" (click)="warningModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="padding: 15px">
      <p translate>A content is currently opened in another session or browser</p>
      <p translate>You can open one resource at a time. Countinuing, the active content will be forcibly closed and the progress will not be saved</p>
      <p translate>What would you like to do?</p>
    </div>
  </div>
  <div class="modal-footer">
    <span class="btn btn-danger clickable" (click)="confirmWarningOpenedResource()" translate>Continue</span>
    <span class="btn btn-default clickable" (click)="cancelWarningOpenedResource()" translate>Cancel</span>
  </div>
</ng-template>

<ng-template #resourceClosedWarningTemplate>
  <div class="modal-header">
    <h5 class="modal-title pull-left" translate>Warning</h5>
    <button type="button" class="close pull-right" (click)="closedModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="padding: 15px">
      <p class="alert alert-danger" translate>Another content is currently opened and the one you were on has now been closed</p>
    </div>
  </div>
</ng-template>

<ng-template #resourceOpenedWarningTemplate>
  <div class="modal-header">
    <h5 class="modal-title pull-left" translate>Warning</h5>
  </div>
  <div class="modal-body">
    <div style="padding: 15px">
      <p translate>A content is currently opened in another tab or window of the browser.</p>
      <p translate>You can open one resource at a time, so continue watching the content or close it now without saving your progress.</p>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn" (click)="closeOpenedResource()" translate>Close</button>
  </div>
</ng-template>


