import {Injectable} from "@angular/core";
import {combineEpics, ofType} from "redux-observable";
import {ThemeService} from "../service";
import {catchError, flatMap, map, startWith, switchMap} from "rxjs/operators";
import {ThemeAction, ThemeActions} from "./actions";
import {of} from "rxjs";

@Injectable()
export class ThemeEpics {

  constructor(
    private service: ThemeService,
    private actions: ThemeActions
  ) {

  }

  create = () => combineEpics(
    this.createThemeConfigEpic(),
  );

  private createThemeConfigEpic = () =>
    (action$, state$) =>
      action$.pipe(
        ofType(ThemeActions.LOAD_CUSTOM_THEME),
        map((action: ThemeAction) => ({...action.meta})),
        switchMap((themeName: string) => this.loadThemeConfig(themeName)),
      );

  private loadThemeConfig = (themeName: string) => {
    return this.service.getThemeConfig(themeName)
      .pipe(
        flatMap(cfg => of(this.actions.loadCustomThemeSucceeded(cfg))),
        catchError(e => of(this.actions.loadCustomThemeFailed(e))),
        startWith(this.actions.loadCustomThemeStarted()),
      )
  };

}
