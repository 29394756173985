import {Action} from "redux";
import {FieldsState} from "../model";
import {FieldsAction, FieldsActions} from "./actions";

const INITIAL_STATE: FieldsState = {
  fields: undefined
};

export const fieldsReducer = () => (state: FieldsState = INITIAL_STATE, action: Action): FieldsState => {

  switch (action.type) {
    case FieldsActions.LOAD_CUSTOM_FIELDS_SUCCEEDED:
      return {
        ...state,
        fields: {...(action as FieldsAction<any>).payload}
      };
  }
  return state;
};