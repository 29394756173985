import {composeReducers, defaultFormReducer} from '@angular-redux/form';
import {routerReducer} from '@angular-redux/router';
import {AnyAction, combineReducers, Reducer} from 'redux';
import {authReducer, AuthState} from "../authentication/store/reducers";
import {userReducer} from "../user/store/reducers";
import {UserState} from "../user/model";
import {AuthActions} from "../authentication/store/actions";
import {bootReducer, BootState} from "../boot/store/reducers";
import {themeReducer, ThemeState} from "../theme/store/reducers";
import {FieldsState} from "../config/fields/model";
import {fieldsReducer} from "../config/fields/store/reducers";
import {lmsReducer, LmsState} from "../lms/store/reducers";


export type AppState = {
  boot: BootState,
  auth: AuthState,
  theme: ThemeState,
  user: UserState,
  fields: FieldsState,
  lms: LmsState,
  router: any
}
export const initialAppState = () => ({
  boot: undefined,
  auth: undefined,
  theme: undefined,
  user: undefined,
  fields: undefined,
  lms: undefined,
  router: undefined
});

// Define the global store shape by combining our application's
// reducers together into a given structure.
const allReducer: Reducer<AppState, AnyAction> = composeReducers(
  defaultFormReducer(),
  combineReducers({
    boot: bootReducer(),
    auth: authReducer(),
    theme: themeReducer(),
    user: userReducer(),
    fields: fieldsReducer(),
    lms: lmsReducer(),
    router: routerReducer,
  }),
);
export const resetAppStateAction = ({type: 'RESET_APP'});

export const rootReducer = (state, action) => {
  if ((action.type === resetAppStateAction.type) || (action.type === AuthActions.USER_LOGOUT)) {
    state = initialAppState()
  }
  return allReducer(state, action)
};
