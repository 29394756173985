import {NgModule} from '@angular/core';
// Angular-redux ecosystem stuff.
// @angular-redux/form and @angular-redux/router are optional
// extensions that sync form and activeRoute$ location state between
// our store and Angular.
import {provideReduxForms} from '@angular-redux/form';
import {NgReduxRouter, NgReduxRouterModule} from '@angular-redux/router';
import {DevToolsExtension, NgRedux, NgReduxModule,} from '@angular-redux/store';
// Redux ecosystem stuff.
import {FluxStandardAction} from 'flux-standard-action';
import {createLogger} from 'redux-logger';
import {createEpicMiddleware} from 'redux-observable-es6-compat';
// The top-level reducers and epics that make up our app's logic.
import {RootEpics} from './epics';
import {AppState, initialAppState, rootReducer} from './reducers';
import {AuthenticationEpics} from "../authentication/store/epics";
import {AuthenticationService} from "../authentication/service";
import {LocalStorageService} from "../storage/local/service";
import {UserService} from "../user/service";
import {UserEpics} from "../user/store/epics";
import {HierarchyService} from "../user/hierarchy/api/service";
import {BootEpics} from "../boot/store/epics";
import {AppBootModule} from "../boot/module";

@NgModule({
  imports: [
    AppBootModule,
    NgReduxModule,
    NgReduxRouterModule.forRoot(),
  ],
  providers: [
    RootEpics,
    AuthenticationEpics,
    AuthenticationService,
    LocalStorageService,
    UserEpics,
    UserService,
    HierarchyService],
})
export class StoreModule {
  constructor(
    public store: NgRedux<AppState>,
    devTools: DevToolsExtension,
    ngReduxRouter: NgReduxRouter,
    rootEpics: RootEpics,
  ) {
    // Tell Redux about our reducers and epics. If the Redux DevTools
    // chrome extension is available in the browser, tell Redux about
    // it too.
    const epicMiddleware = createEpicMiddleware<FluxStandardAction<any, any, any>,
      FluxStandardAction<any, any, any>,
      AppState>();

    store.configureStore(
      rootReducer,
      initialAppState(),
      [createLogger(), epicMiddleware],
      // configure store typings conflict with devTools typings
      (devTools.isEnabled() ? [devTools.enhancer({trace: true})] : []) as any,
    );

    epicMiddleware.run(rootEpics.createEpics());


    // Enable syncing of Angular router state with our Redux store.
    if (ngReduxRouter) {
      ngReduxRouter.initialize();
    }

    // Enable syncing of Angular form state with our Redux store.
    provideReduxForms(store);
  }
}
