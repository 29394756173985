import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {select} from "@angular-redux/store";
import {Observable} from "rxjs";
import {LocalStorageService} from "../storage/local/service";
import {AuthState} from "../authentication/store/reducers";
import {UserState} from "../user/model";

@Directive({
  selector: '[appUserAuths]'
})
export class UserAuthsDirective {

  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private localStorageService: LocalStorageService) {
  }

  @select('auth')
  readonly authState: Observable<AuthState>;

  @select('user')
  readonly userState: Observable<UserState>;

  @Input() set appUserAuths(requiredAuths: string[]) {

    const userAuths = this.localStorageService.get(LocalStorageService.UserAuthoritiesKeyName).split(',');
    this.control(requiredAuths, userAuths);
  }

  control(requiredAuths: string[], userAuths: string[]) {

    this.viewContainer.clear();
    this.hasView = userAuths.some(auth => requiredAuths.includes(auth));
    if (this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}
