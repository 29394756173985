import {Injectable} from '@angular/core';
import {LocalStorageService} from '../../storage/local/service';
import {combineEpics, ofType} from 'redux-observable';
import {AuthAction, AuthActions} from './actions';
import {RefreshToken, UserAuth, UserCredentials, UserImpersonate} from '../model';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {of, throwError} from 'rxjs';
import {AuthenticationService} from '../service';
import {Router} from '@angular/router';
import {CasService} from '../cas/service';
import {BootActions} from '../../boot/store/actions';
import {resetAppStateAction} from '../../store/reducers';


@Injectable()
export class AuthenticationEpics {

  constructor(
    private service: AuthenticationService,
    private casService: CasService,
    private actions: AuthActions,
    private bootActions: BootActions,
    private localStorage: LocalStorageService
  ) {

  }

  create = () => combineEpics(
    this.createCheckTokenEpic(),
    this.createAuthenticationEpic(),
    this.createLoginEpic(),
    this.createLogoutEpic(),
    this.createLoadCASProvidersEpic(),
    this.impersonateLoginEpic(),
    this.impersonateLogoutEpic(),
    this.createRefreshTokenLoginEpic()
  );

  private authSucceeded = (data) => this.actions.authenticationSucceeded(data);

  private authFailed = (response) => of(
    this.actions.authenticationFailed({
      code: response.code,
      status: '' + response.status,
      ...response.error
    }),
  );

  private loginFailed = (response) => of(
    this.actions.loginFailed({
      code: response.code,
      status: '' + response.status,
      ...response.error
    }),
  );

  private checkToken = () => of(this.service.checkToken()).pipe(
    map(token => this.actions.authFoundToken(token))
  );

  private authenticate = () => {
    const currToken: UserAuth = JSON.parse(this.localStorage.get(LocalStorageService.OAuthTokenKeyName));
    return (!!currToken ? of({...currToken}) : throwError({status: 'Not authenticated'}))
      .pipe(
        map(this.authSucceeded),
        catchError(this.authFailed),
        startWith(this.actions.authenticationStarted()),
      )
  };

  private loginSucceeded = (data: UserAuth) => {
    this.localStorage.set(LocalStorageService.OAuthTokenKeyName, JSON.stringify(data));
    this.localStorage.set(LocalStorageService.UserAuthoritiesKeyName, data["sep-authorities"].join(','));
    return this.actions.loginSucceeded(data)
  };

  private login = (credentials: UserCredentials) => {
    this.localStorage.del(LocalStorageService.OAuthTokenKeyName);
    return this.service
      .authenticate(credentials.username, credentials.password, credentials.hostname)
      .pipe(
        map(this.loginSucceeded),
        catchError(this.loginFailed),
        startWith(this.actions.loginStarted()),
      )
  };

  private logout = () => {
    this.localStorage.del(LocalStorageService.OAuthTokenKeyName);
    this.localStorage.del(LocalStorageService.UserAuthoritiesKeyName);
    return this.actions.userLoggedOut();
  };


  private createCheckTokenEpic = () =>
    (action$, state$) =>
      action$.pipe(ofType(AuthActions.AUTH_CHECK_TOKEN),
        switchMap(this.checkToken),
      );

  private createAuthenticationEpic = () =>
    (action$, state$) =>
      action$.pipe(ofType(AuthActions.AUTHENTICATE_USER),
        switchMap(this.authenticate),
      );

  private createLoginEpic = () =>
    (action$, state$) =>
      action$.pipe(ofType(AuthActions.USER_LOGIN),
        map((action: AuthAction<UserCredentials>) => ({...action.payload})),
        switchMap((credentials: UserCredentials) => this.login(credentials)),
      );

  private createLogoutEpic = () =>
    (action$, state$) =>
      action$.pipe(ofType(AuthActions.USER_LOGOUT),
        map(() => this.logout())
      );


  private impersonateLoginSuccess = (data: UserAuth) => {
    this.localStorage.set(LocalStorageService.UserImpersonateKeyName, "1");
    this.localStorage.set(LocalStorageService.OAuthTokenKeyName, JSON.stringify(data));
    this.localStorage.set(LocalStorageService.UserAuthoritiesKeyName, data["sep-authorities"].join(','));
    return this.actions.impersonateLoginSucceeded(data)
  };

  private impersonateLoginFailed = (response) => of(
    this.actions.impersonateLoginFailed({
      code: response.code,
      status: '' + response.status,
      ...response.error
    }),
  );

  private impersonateLogin = (userImpersonate: UserImpersonate) =>
    this.service.impersonate(userImpersonate.userId, userImpersonate.sender).pipe(
      map(this.impersonateLoginSuccess),
      catchError(this.impersonateLoginFailed),
      startWith(this.actions.impersonateLoginStarted())
    );

  private impersonateLoginEpic = () => (action$, state$) =>
    action$.pipe(ofType(AuthActions.USER_IMPERSONATE_LOGIN),
      map((action: AuthAction<UserImpersonate>) => ({...action.payload})),
      switchMap(this.impersonateLogin)
    );

  private impersonateLogoutSuccess = (data: UserAuth) => {
    this.localStorage.del(LocalStorageService.UserImpersonateKeyName);
    this.localStorage.set(LocalStorageService.OAuthTokenKeyName, JSON.stringify(data));
    this.localStorage.set(LocalStorageService.UserAuthoritiesKeyName, data["sep-authorities"].join(','));
    return this.actions.impersonateLogoutSucceeded(data);
  };

  private impersonateLogoutFailed = (error) => {
    this.localStorage.del(LocalStorageService.UserImpersonateKeyName);
    this.localStorage.del(LocalStorageService.OAuthTokenKeyName);
    this.localStorage.del(LocalStorageService.UserAuthoritiesKeyName);
    return of(this.actions.impersonateLogoutFailed(({
      code: error.code,
      status: '' + error.status,
      ...error.error
    })))
  };

  private impersonateLogout = () => {
    this.localStorage.del(LocalStorageService.UserImpersonateKeyName);
    return this.logout()
  }
  /*
    this.service.impersonateLogout().pipe(
      map(this.impersonateLogoutSuccess),
      catchError(this.impersonateLogoutFailed),
      startWith(this.actions.impersonateLogoutStarted())
    );

   */

  private impersonateLogoutEpic = () => (action$, state$) =>
    action$.pipe(ofType(AuthActions.USER_IMPERSONATE_LOGOUT),
      map(this.impersonateLogout)
    );

  private loadCasProviders = () => {
    return this.casService.providers().pipe(
      map(providers => this.actions.casProvidersLoaded(providers)),
      catchError(err => of(this.actions.casProvidersFailed(err)))
    )
  };

  private createLoadCASProvidersEpic = () =>
    (action$, state$) =>
      action$.pipe(ofType(AuthActions.LOAD_CAS_PROVIDERS),
        switchMap(() => this.loadCasProviders()),
      )


  private createRefreshTokenLoginEpic = () =>
    (action$, state$) =>
      action$.pipe(ofType(AuthActions.USER_REFRESH_TOKEN_LOGIN),
        map((action: AuthAction<RefreshToken>) => ({...action.payload})),
        switchMap((credentials: RefreshToken) => this.refreshToken(credentials)),
      )


  private refreshToken = (token: RefreshToken) => {
    this.localStorage.del(LocalStorageService.OAuthTokenKeyName);
    return this.service
      .getToken(token.tempToken)
      .pipe(
        map(this.loginSucceeded),
        catchError(this.loginFailed),
        startWith(this.actions.refreshLoginLoginStarted()),
      );
  }
}
