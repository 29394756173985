import {Component, ContentChild, ContentChildren, Directive, Input, OnDestroy, OnInit, QueryList, TemplateRef} from '@angular/core';
import {Subscription, timer} from 'rxjs';

@Directive({
  selector: '[appSlideItem]'
})
export class AppSlideItemDirective  {

  constructor(public templateRef: TemplateRef<string>) {}
}


@Component({
  selector: 'app-slide-show',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class SlideshowComponent implements OnInit, OnDestroy {

  @Input()
  slides: any[];
  @Input()
  minHeight: number;
  @Input()
  backgroundColor: string;
  @Input()
  arrowColor: string;
  @ContentChildren(AppSlideItemDirective) templateRefs: QueryList<AppSlideItemDirective>;
  prevSlide = -1;
  activeSlideClass = -1;
  activeSlide = 0;
  slideWay: string;
  slideWayS: string;
  activeControl = false;

  subscribers: Subscription[] = [];


  ngOnInit() {
    this.activeControl = true;
  }

  activeSlideChange(index) {
    this.prevSlide = this.activeSlide;
    this.activeSlide = index;
    this.activeSlideClass = index;

    const source = timer(600);
    const timerObj = source.subscribe(val => {
      this.prevSlide = null;
      this.activeControl = true;
      timerObj.unsubscribe();
    });
    this.subscribers.push(timerObj);
    const timerObj1 = timer(0).subscribe(val => {
      this.slideWay = null;
      this.activeSlideClass = null;
      timerObj1.unsubscribe();
    });
    this.subscribers.push(timerObj1);
  }

  prevSlideAction() {
    if (this.activeControl === true) {
      this.slideWay = this.slideWayS = 'left';
      this.activeControl = false;
      if (this.activeSlide === 0) {
        this.activeSlideChange(this.slides.length - 1);
      } else {
        this.activeSlideChange(this.activeSlide - 1);
      }
    }
  }

  nextSlideAction() {
    if (this.activeControl === true) {
      this.slideWay = this.slideWayS = 'right';
      this.activeControl = false;
      if (this.activeSlide === this.slides.length - 1) {
        this.activeSlideChange(0);
      } else {
        this.activeSlideChange(this.activeSlide + 1);
      }
    }
  }

  goToSlide(index) {
    this.activeSlideChange(index);
  }

  ngOnDestroy(): void {
    for (const sub of this.subscribers) {
      sub.unsubscribe();
    }
  }
}
export class Slide {
  back: string;
  front: string;
  title: string;
  body: string;
  url?: string;
}
