import {Injectable} from '@angular/core';
import {AppEnvService} from '../config/env/service';
import {HttpClient} from '@angular/common/http';
import {Observable, zip} from 'rxjs';
import {environment} from '../../environments/environment';
import {User, UserDetails, UserPasswordChangeRequest, UserPreferences} from './model';
import {Stats} from './stats/model';
import {map} from 'rxjs/operators';
import {LessonsSessions} from '../lms/synchronous/model';

@Injectable()
export class UserService {

  static readonly defaultProfileImageUrl = 'assets/img/avatar-default.png';


  detailsUrl: string;
  profileImageUrl: string;
  statsUrl: string;
  preferencesUrl: string;
  changePasswordUrl: string;
  searchUserUrl: string;
  acceptPrivacyUrl: string;
  recoverPasswordUrl: string;

  constructor(
    private appEnv: AppEnvService,
    private httpClient: HttpClient) {

    this.detailsUrl = appEnv.config().api.user.details;
    this.profileImageUrl = appEnv.config().api.user.profileImage;
    this.statsUrl = appEnv.config().api.user.stats;
    this.preferencesUrl = appEnv.config().api.user.preferences;
    this.changePasswordUrl = appEnv.config().api.user.changePassword;
    this.searchUserUrl = appEnv.config().api.user.search;
    this.acceptPrivacyUrl = appEnv.config().api.user.acceptPrivacy;
    this.recoverPasswordUrl = appEnv.config().api.user.recoverPassword;
  }

  profileImage = () => this.httpClient.get(this.profileImageUrl, {responseType: 'blob'});
  details = () => this.httpClient.get<UserDetails>(this.detailsUrl);
  stats = (year?: number) => this.httpClient.get<Stats>(`${this.statsUrl}${year ? '?year=' + year.toString() : ''}`);
  updatePreferences = (preferences: UserPreferences) => this.httpClient.post(this.preferencesUrl, preferences);
  deleteUserProfileImage = () => this.httpClient.delete(this.profileImageUrl);
  changePassword = (request: UserPasswordChangeRequest) => this.httpClient.post(this.changePasswordUrl, request);
  search = (searchString: string): Observable<User[]> => this.httpClient.get<User[]>(`${this.searchUserUrl} ? filter =${searchString}`);
  acceptPrivacy = () => this.httpClient.post(this.acceptPrivacyUrl, {});
  recoverPassword = (eMailAddress) => this.httpClient.post(this.recoverPasswordUrl, {
    email: eMailAddress,
    clientId: this.appEnv.config().authentication.oauth.clientId,
    lang: this.appEnv.config().i18n.defaultLanguageCode,
    pageUrl: window.location.origin + '/iam'
  })


}
