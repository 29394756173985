import {Injectable} from '@angular/core';
import {LmsPathsEpics} from '../paths/store/epics';
import {combineEpics} from 'redux-observable';
import {LmsCoursesEpics} from '../courses/store/epics';
import {ScormUnitEpics} from '../scorm/unit/store/epics';
import {ScormCourseEpics} from '../scorm/course/store/epics';
import {LmsResourcesEpics} from '@shared/app/lms/resources/store/epics';
import {LessonsEpics} from '@shared/app/lms/synchronous/store/epics';

@Injectable()
export class LmsEpics {
  constructor(
    private pathsEpics: LmsPathsEpics,
    private coursesEpics: LmsCoursesEpics,
    private resourcesEpics: LmsResourcesEpics,
    private scormUnitEpics: ScormUnitEpics,
    private scormCourseEpics: ScormCourseEpics,
    private lessonsEpics: LessonsEpics
  ) {

  }

  create = (): any => combineEpics(
    this.pathsEpics.create(),
    this.coursesEpics.create(),
    this.resourcesEpics.create(),
    this.scormUnitEpics.create(),
    this.scormCourseEpics.create(),
    this.lessonsEpics.create()
  );

}
