import {ResourcePathAware} from "../lms/resources/model";

export class CourseXapi extends ResourcePathAware {
  id: number;
  title: string;
  description: string;
  state: string;
  cover: any;
  duration: number;
}
