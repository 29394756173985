import {NgModule} from "@angular/core";
import {TranslationsService} from "./translations/service";
import {
  TranslateDirective,
  TranslateModule,
  TranslatePipe
} from "@ngx-translate/core";
import {I18NService} from "./service";

@NgModule({
  imports: [
    TranslateModule
  ],
  exports: [TranslateModule, TranslatePipe, TranslateDirective],
  providers: [TranslationsService, I18NService]

})
export class I18NModule {

}
