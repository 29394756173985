import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {select} from '@angular-redux/store';
import {UserStatsHome} from '../../user/model';
import {AppEnvService} from '../../config/env/service';
import {StatsCounters} from '../../user/stats/model';
import {BreadCrumbFactory, BreadcrumbLevel, BreadcrumbService, SepBreadcrumb} from '../../common/_services/breadcrumb.service';
import {LessonSession} from '@shared/app/lms/synchronous/model';
import {UserActions} from '@shared/app/user/store/actions';
import {SynchronousService} from '@shared/app/lms/synchronous/synchronous.service';
import {filter, map} from 'rxjs/operators';


@Component({
  moduleId: module.id,
  selector: 'app-home-stats',
  templateUrl: 'component.html',
  styleUrls: ['./component.scss']
})

export class HomeStatsComponent implements OnInit {

  _counto1: number;
  _counto2: number;
  ivassEnable: boolean;
  colors = [
    {
      backgroundColor: ['rgb(23, 162, 184)', 'rgb(32, 201, 151)', 'rgb(11, 66, 193)', 'rgb(255, 193, 7)'],
      borderColor: ['rgb(23, 162, 184)', 'rgb(32, 201, 151)', 'rgb(11, 66, 193)', 'rgb(255, 193, 7)']
    }
  ];
  counters: StatsCounters;
  isLoadingStats: boolean;

  @ViewChild('widgetTotalHoursAvailableTemplate')
  widgetTotalAvailableHours: TemplateRef<any>;
  @ViewChild('widgetToBeCompletedHoursByTypeChartTemplate')
  widgetToBeCompletedHoursByTypeChart: TemplateRef<any>;
  @ViewChild('widgetCompletedHoursByTypeChartTemplate')
  widgetCompletedHoursByTypeChart: TemplateRef<any>;
  @ViewChild('widgetHoursByTypeChartTemplate')
  widgetHoursByTypeChart: TemplateRef<any>;
  @ViewChild('widgetCoursesByStatusChartTemplate')
  widgetCoursesByStatusChart: TemplateRef<any>;

  widgetSlot1: TemplateRef<any>;
  widgetSlot2: TemplateRef<any>;
  widgetSlot3: TemplateRef<any>;

  @select(['user', 'stats']) userStats$: Observable<UserStatsHome>;
  @select(['user', 'stats']) userStatsLoading$: Observable<UserStatsHome>;
  userSessions: LessonSession[];


  constructor(
    private bcService: BreadcrumbService,
    private sanitizer: DomSanitizer,
    private envService: AppEnvService,
    private translateService: TranslateService,
    private userActions: UserActions,
    private synchronousService: SynchronousService
  ) {
    const b: SepBreadcrumb = (new BreadCrumbFactory).createBreadCrumb('Home', null, '/', BreadcrumbLevel.root, true);
    this.bcService.setBreadcrumb(b);
  }


  ngOnInit() {
    this.ivassEnable = this.envService.config().env.ivassEnable;

    this.loadUserStats();
    this.synchronousService.getActiveSessionsLessonsUser().subscribe(res => {
      this.userSessions = res;
    });

    this.userStats$.pipe(
      map((e) => {
        if (e !== undefined) {
          this.isLoadingStats = false;
        }
      }));
  }

  loadUserStats() {
    this.isLoadingStats = true;
    this.userActions.loadUserStats();
  }
}
