import {NgModule} from "@angular/core";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {ApiInterceptor} from "./interceptor/interceptor";
import {LocalStorageService} from "../storage/local/service";

@NgModule({
  declarations: [],
  exports: [],
  imports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    }],
})
export class HttpModule {
}
