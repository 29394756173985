import {select} from "@angular-redux/store";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {map} from "rxjs/operators";
import {BootState} from "./store/reducers";

@Injectable()
export class BootGuard implements CanActivate {

  @select(['boot'])
  readonly bootState!: Observable<BootState>;

  constructor() {
  }

  activationStrategy = (bootState: BootState) => {
    return bootState.bootCompleted;
  };

  canActivate = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree =>
    this.bootState
      .pipe(
        map(this.activationStrategy)
      )

}


