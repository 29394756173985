import {Component, Input, OnInit} from '@angular/core';
import {ResourceType} from '@shared/app/lms/resources/model';


@Component({
  selector: 'app-resources-icon',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class ResourcesIconComponent implements OnInit {

  @Input()
  type: ResourceType.SCORM | ResourceType.XAPI | ResourceType.CERTIFICATE | ResourceType.DOCUMENT | ResourceType.SYNCHRONOUS;
  @Input()
  showTooltip: boolean;
  @Input()
  resize: number;
  @Input()
  lineHeight: number;

  classIcon: string;

  ngOnInit() {
    this.resize = this.resize ? this.resize : 100;
    this.lineHeight = this.lineHeight ? this.lineHeight : 100;

    switch (this.type) {
      case ResourceType.SYNCHRONOUS:
        this.classIcon = 'fas fa-chalkboard-teacher';
        break;
      case ResourceType.XAPI:
        this.classIcon = 'fas fa-cloud';
        break;
      case ResourceType.SCORM:
        this.classIcon = 'fas fa-tv';
        break;
      case ResourceType.DOCUMENT:
        this.classIcon = 'fas fa-file';
        break;
      case ResourceType.CERTIFICATE:
        this.classIcon = 'fas fa-file-signature';
        break;
    }
  }
}
