import {Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges} from '@angular/core';
import {Course} from '../../courses/model';
import {ScormUnit} from '../../scorm/unit/model';
import {Resource} from '../../resources/model';
import {PathService} from '../path.service';
import {Lesson} from '@shared/app/lms/synchronous/course/model';

@Component({
  selector: 'app-path-course',
  templateUrl: './path-course.component.html',
  styleUrls: ['./path-course.component.scss']
})
export class PathCourseComponent implements OnInit, OnChanges {

  _course: Course;

  @Input()
  set course(c: Course) {
    this._course = c;
  }

  @Output()
  unitSelected = new EventEmitter<ScormUnit>();

  @Output()
  lessonSelected = new EventEmitter<Lesson>();

  @Output()
  resourceSelected = new EventEmitter<Resource>();

  constructor(private pathService: PathService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['unitClosed'] !== undefined && !changes['unitClosed'].firstChange) {
      this.pathService.unitHasBeenClosed.next(new Date());
    }
    if (changes['course']) {
      this._course = changes['course'].currentValue;
    }
  }

  uSelected(u: ScormUnit) {
    this.unitSelected.emit(u);
  }

  rSelected(r: Resource) {
    this.resourceSelected.emit(r);
  }

  lSelected(l: Lesson) {
    this.lessonSelected.emit(l);
  }
}
