<div class="modal-header">
  <h5 class="modal-title pull-left" translate>Impersonate user</h5>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group">
    <div class="input-group">
      <input class="col-10 form-control" type="text" [(ngModel)]="selectedUsername" (keyup)="searchClient(selectedUsername)" placeholder="{{searchPlaceholder | async}}"
      />
      <div class="col-2">
        <button class="btn btn-primary" (click)="onImpersonateLogin()" translate [disabled]="!selectedUser">Login</button>
      </div>
    </div>
  </div>
  <div *ngIf="resultList">
    <ul class="list-group list-group-flush">
      <li *ngFor="let client of clients | async" class="list-group-item" (click)="onselectClient(client)">
        <span>{{client.username}} - {{client.firstName}} {{client.lastName}}</span>
      </li>
    </ul>
  </div>
</div>
