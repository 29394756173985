import {Injectable} from '@angular/core';
import {ScormUnitAction, ScormUnitActions} from './actions';
import {of} from 'rxjs';
import {filter, map, startWith, switchMap} from 'rxjs/operators';
import {DomSanitizer} from '@angular/platform-browser';
import {AppState} from '../../../../store/reducers';
import {combineEpics, ofType} from 'redux-observable';
import {ScormUnit, UnitType} from '../model';
import {ScormCourse} from '../../course/model';
import {AppEnvService} from '../../../../config/env/service';

@Injectable()
export class ScormUnitEpics {

  constructor(
    private actions: ScormUnitActions,
    private sanitizer: DomSanitizer,
    private envService: AppEnvService
  ) {

  }

  create = () => combineEpics(
    this.createOpenUnitEpic(),
    this.createNextUnitEpic(),
    this.createPrevUnitEpic(),
  );

  private hasNext = (scormCourse: ScormCourse, scormUnit: ScormUnit) => {
    const unitsCount = scormCourse.flatUnits.length;
    const currentIndex = scormCourse.flatUnits.findIndex(unit => unit.id === scormUnit.id);
    return (1 + currentIndex) < unitsCount;
  };

  private hasPrev = (scormCourse: ScormCourse, scormUnit: ScormUnit) => {
    const currentIndex = scormCourse.flatUnits.findIndex(unit => unit.id === scormUnit.id);
    return (currentIndex - 1) >= 0;
  };


  private openUnit = (unit: ScormUnit, state: AppState) => {
    const hasNext = this.hasNext(state.lms.scormCourse.current, unit);
    const hasPrev = this.hasPrev(state.lms.scormCourse.current, unit);
    return of(unit)
      .pipe(
        switchMap((unit) => [
          this.actions.scormHasNext(hasNext),
          this.actions.scormHasPrev(hasPrev),
          this.actions.scormUnitOpened(unit)
        ]),
        startWith(this.actions.scormUnitOpening(unit))
      );
  };


  private nextUnit = (course: ScormCourse, current: ScormUnit) => {
    const unitsCount = course.flatUnits.length;
    const currentIndex = course.flatUnits.findIndex(unit => unit.id === current.id);
    return ((1 + currentIndex) < unitsCount) && course.flatUnits[(currentIndex + 1)].locked === false
      ? course.flatUnits[(currentIndex + 1)]
      : undefined;
  };

  private prevUnit = (course: ScormCourse, current: ScormUnit) => {
    const currentIndex = course.flatUnits.findIndex(unit => unit.id === current.id);
    return ((currentIndex - 1) >= 0) && course.flatUnits[(currentIndex - 1)].locked === false
      ? course.flatUnits[(currentIndex - 1)]
      : undefined;
  };


  private prepareUnitOpen = (unit: ScormUnit, state: AppState): ScormUnit => {
    const userId = state.user.details.id;
    const courseId = state.lms.scormCourse.current.id;
    const catalogId = state.lms.scormCourse.current.catalogId;
    const resourceId = state.lms.scormCourse.current.resourceId;
    const pathId = state.lms.scormCourse.current.pathId;
    const token = state.auth.access_token;
    const QUIZ_VIEWER_URL_REGEX = /assets\/quiz-viewer/i;

    if (unit.type === UnitType.LESSONORQUIZ) {
      const url = (unit.quizId && QUIZ_VIEWER_URL_REGEX.test(unit.url.toString()))
        ? `${this.envService.config().scorm.quizViewerUrl}?userId=${userId}&unitId=${unit.id}&quizId=${unit.quizId}&courseId=${unit.courseId}&pathId=${pathId}&catalogId=${unit.catalogId}&credits=${unit.duration}`
        : `${this.envService.config().scorm.viewerUrl}?userId=${userId}&unit=${unit.id}&courseId=${courseId}&class=${pathId}&debug=1&catalogId=${catalogId}&token=${token}&resourceId=${resourceId}`;
      return ({...unit, url: this.sanitizer.bypassSecurityTrustResourceUrl(url)});
    }
    return ({
      ...unit,
      url: this.sanitizer.bypassSecurityTrustResourceUrl(`/data/sep-smw/lms/courses/course_${courseId}/${unit.url}`)
    });
  };

  private createOpenUnitEpic = () =>
    (action$, state$) => action$
      .pipe(
        ofType(ScormUnitActions.OPEN_SCORM_UNIT),
        map((action: ScormUnitAction<ScormUnit>) => this.prepareUnitOpen((action as ScormUnitAction<ScormUnit>).payload, state$.value)),
        switchMap((unit: ScormUnit) => this.openUnit(unit, state$.value))
      );

  private createNextUnitEpic = () =>
    (action$, state$) => action$
      .pipe(
        ofType(ScormUnitActions.NEXT_SCORM_UNIT),
        map(() => this.nextUnit(state$.value.lms.scormCourse.current, state$.value.lms.scormUnit.current)),
        filter(unit => !!unit),
        map((unit: ScormUnit) => this.prepareUnitOpen(unit, state$.value)),
        switchMap((unit: ScormUnit) => this.openUnit(unit, state$.value))
      );
  private createPrevUnitEpic = () =>
    (action$, state$) => action$
      .pipe(
        ofType(ScormUnitActions.PREV_SCORM_UNIT),
        map(() => this.prevUnit(state$.value.lms.scormCourse.current, state$.value.lms.scormUnit.current)),
        filter(unit => !!unit),
        map((unit: ScormUnit) => this.prepareUnitOpen(unit, state$.value)),
        switchMap((unit: ScormUnit) => this.openUnit(unit, state$.value))
      );

}
