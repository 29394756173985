import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {SafeUrl} from '@angular/platform-browser';
import {FlowChartStore, PathElement} from 'ngx-sep-graph-builder';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {dia} from 'jointjs';
import {User} from "../../user/model";
import {PathService} from "../paths/path.service";
import {UserService} from "../../user/service";

@Component({
  selector: 'app-unit-modal',
  templateUrl: './dynamic-path-modal.component.html',
  styleUrls: ['./dynamic-path-modal.component.scss']
})
export class DynamicPathModalComponent implements OnInit {

  public pathElement: PathElement;
  public contentUrl: SafeUrl;
  public pathId: number;
  public type: string;
  user: User;
  @select('user')
  user$: Observable<any>;
  currentUser: User;

  constructor(public bsModalRef: BsModalRef, public modalService: BsModalService, public pathService: PathService, private store: FlowChartStore, private userService: UserService) {
  }

  ngOnInit() {
    this.modalService.onHide.subscribe((reason: any) => this.contentUrl = null);
    this.userService.details().subscribe(u => this.user = u);
  }

  onLoad() {

    const iframe = document.getElementById('iframeId') as HTMLIFrameElement;
    const iWindow = iframe.contentWindow;

    if (this.type === 'scorm') {
      // @ts-ignore
      const LMSSetValue = iWindow.API.LMSSetValue;
      // @ts-ignore

      iWindow.API.LMSSetValue = function (ele, val) {
        if (val === 'completed') {
          document.getElementById('overlay').style.display = 'block';
        }
        return LMSSetValue(ele, val);
      };
    }
  }

  nextCourse() {
    this.pathService.getPath(this.pathService.path.id).subscribe(p => {
      document.getElementById('overlay').style.display = 'none';
      for (const [key, value] of Object.entries(p.dynamicState.blocks)) {
        if ((value as PathElement).enabled && (value as PathElement).status === 'NOT_COMPLETED' && ((value as PathElement).type === 'scorm' || (value as PathElement).type === 'xapi')) {
          const e = this.store.getElementById(key);
          const idCourse = e.attributes.sep.idCourse;
          this.pathService.getCourse(idCourse).subscribe(c => {
            const element = value as PathElement;
            switch (element.type) {
              case 'xApi':
              case 'xapi':
                this.contentUrl = this.pathService.xApiUrl(c.resources[0].metadata.url, this.user);
                break;
              case 'scorm':
                this.contentUrl = this.pathService.scormUrl(c, this.user);
                break;
              default :
                this.bsModalRef.hide();
                break;
            }
          });
        }
      }
    });
  }
}
