import {Injectable} from "@angular/core";
import {PathsAction, LmsPathsActions} from "./actions";
import {combineEpics, ofType} from "redux-observable";
import {catchError, mergeMap, startWith, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {LmsPathsService} from "./service";

@Injectable()
export class LmsPathsEpics {
  constructor(
    private actions: LmsPathsActions,
    private service: LmsPathsService) {

  }

  create = () => combineEpics(
    this.createLoadPathListEpic(),
    this.createOpenPathEpic()
  )

  private listLoaded = (paths) => of(this.actions.loaded(paths))
  private loadFailed = (err) => of(this.actions.loadFailed(err))

  private loadPathList = () =>
    this.service.loadPathList().pipe(
      mergeMap(this.listLoaded),
      catchError(this.loadFailed),
      startWith(this.actions.loadStarted())
    );

  private openPath = (id: number) =>
    this.service.getPath(id).pipe(
      mergeMap((path) => of(this.actions.pathOpened(path))),
      catchError((error) => of(this.actions.pathOpenError(error))),
      startWith(this.actions.pathOpening())
    );

  private createLoadPathListEpic = () =>
    (action$, state$) =>
      action$.pipe(
        ofType(LmsPathsActions.PATHS_LOAD),
        switchMap(this.loadPathList),
      );

  private createOpenPathEpic = () =>
    (action$, state$) =>
      action$.pipe(
        ofType(LmsPathsActions.PATH_OPEN),
        switchMap((action: PathsAction) => this.openPath(+action.meta)),
      );

}
