import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';
import {DomSanitizer} from '@angular/platform-browser';
import {Resource} from '../../resources/model';
import {Subscription} from 'rxjs';
import {ScormUnit} from '../unit/model';
import {CourseService} from '../../courses/course.service';
import {ResourceService} from '../../resources/resource.service';
import {AuthenticationService} from '../../../authentication/service';
import {ScormCourse} from './model';


@Component({
  selector: 'app-course-scorm',
  templateUrl: './course-scorm.component.html',
  styleUrls: ['./course-scorm.component.scss']
})
export class CourseScormComponent implements OnInit, OnDestroy {

  _resource: Resource;
  courseScorm: ScormCourse;
  subscriptions: Subscription[] = [];

  @Input()
  set resource(r: Resource) {
    this._resource = r;
    this.courseScorm = new ScormCourse();
    Object.assign(this.courseScorm, this._resource.metadata);
    this.courseScorm.resourceId = this._resource.id;
    this.courseScorm.courseId = this._resource.courseId;
    this.courseScorm.pathId = this._resource.pathId;
    this.courseScorm.catalogId = this._resource.catalogId;
    this.courseScorm.duration = this._resource.duration;
    this.courseScorm.locked = this._resource.locked;
    this.resourceLoaded.emit(this.courseScorm);
  }

  @Output() unitSelected = new EventEmitter<ScormUnit>();
  @Output() unitClosed = new EventEmitter<any>();

  @Output() resourceLoaded = new EventEmitter<ScormCourse>();

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  openUnit(resourceLocked: boolean, unit: ScormUnit) {
    unit.locked = resourceLocked ? true : unit.locked;
    unit.courseResourceId = this._resource.id;
    unit.courseId = this._resource.courseId;
    unit.pathId = this._resource.pathId;
    unit.catalogId = this._resource.catalogId;
    unit.duration = this._resource.duration;
    unit.openInNewWindow = this._resource.openInNewWindow;
    this.unitSelected.emit(unit);
  }

}

declare var test: any;
