import { Directive, ElementRef, Input, OnInit,  Renderer2, ViewContainerRef } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CustomFieldService} from './custom-field.service';

@Directive({
    selector: '[appCustomField]'
})
export class CustomFieldDirective implements OnInit {

    fieldName: string;
    @Input() remove: boolean;
  @Input() set appCustomField(n: string) {
    this.fieldName = n;
    this.updateLabel();
  }


  constructor(
        private elementRef: ElementRef,
        private customFieldService: CustomFieldService,
        private viewContainer: ViewContainerRef,
        private renderer: Renderer2,
        private translateService: TranslateService) { }

    ngOnInit() { }

    updateLabel() {
      if (this.fieldName) {
        this.customFieldService.getField(this.fieldName).subscribe(
          f => {
            if (f && f.hidden) {
              if (this.remove) {
                this.viewContainer.clear();
              } else {
                this.elementRef.nativeElement.style.display = 'none';
              }
            } else {
              if (f && f.customLabel) {
                const labelSpan = this.elementRef.nativeElement.querySelector('label > span');
                if (labelSpan) {
                  if (f.customLabel[this.translateService.currentLang]) {
                    this.translateService.set(
                      f.label,
                      f.customLabel[this.translateService.currentLang],
                      this.translateService.currentLang);
                  }
                }
              }
            }
          }, err => {
            console.error(`error getting field [${this.fieldName}]: ${err}` );
          }
        );
      }
    }

}
