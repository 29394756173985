<app-banner
  [display]="'thin'"
  [title]="bannerTitle"
  [description]="bannerDescription"
  [backgroundImage]="'assets/img/report-banner.jpg'"
  [colorSchema]="'light'"
></app-banner>

<div class="report-page">

  <div class="section panel panel-default table-dynamic">
    <div *ngIf="graph1DataReady && graphHasValues">
      <canvas baseChart class="graph-hours-year"
              [datasets]="barChartData"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [legend]="barChartLegend"
              [chartType]="barChartType"
              [colors]="colors"
              height="300"></canvas>
    </div>

<!--    <div *ngIf="data && data.ivass.courseYears">-->
<!--      <table class="table table-bordered cf" id="ivass-report-table">-->
<!--        <thead class="cf">-->
<!--        <tr>-->
<!--          <th>-->
<!--            <div>-->
<!--              <span translate>Year</span>-->
<!--            </div>-->
<!--          </th>-->
<!--          <th>-->
<!--            <div>-->
<!--              <span translate>Year type</span>-->
<!--            </div>-->
<!--          </th>-->
<!--          <th>-->
<!--            <div>-->
<!--              <span translate>Duration</span>-->
<!--            </div>-->
<!--          </th>-->
<!--          <th>-->
<!--            <div>-->
<!--              <span translate>Duration mandatory</span>-->
<!--            </div>-->
<!--          </th>-->
<!--          <th>-->
<!--            <div>-->
<!--              <span translate>Duration optional</span>-->
<!--            </div>-->
<!--          </th>-->
<!--          <th>-->
<!--            <div>-->
<!--              <span translate>Total needed</span>-->
<!--            </div>-->
<!--          </th>-->
<!--          <th>-->
<!--            <div>-->
<!--              <span translate>Missing Duration</span>-->
<!--            </div>-->
<!--          </th>-->
<!--          <th>-->
<!--            <div>-->
<!--              <span translate>Year compliant</span>-->
<!--            </div>-->
<!--          </th>-->
<!--          <th>-->
<!--            <div>-->
<!--              <span translate>Total needed two-year period IVASS</span>-->
<!--            </div>-->
<!--          </th>-->
<!--          <th>-->
<!--            <div>-->
<!--              <span translate>Missing duration two-year period IVASS</span>-->
<!--            </div>-->
<!--          </th>-->
<!--          <th>-->
<!--            <div>-->
<!--              <span translate>Two-year period IVASS compliant</span>-->
<!--            </div>-->
<!--          </th>-->
<!--          <th>-->
<!--            <div>-->
<!--              <span translate>IDD compliant</span>-->
<!--            </div>-->
<!--          </th>-->
<!--        </tr>-->
<!--        </thead>-->

<!--        <tbody *ngFor="let d of data.ivass.courseYears">-->
<!--        <tr>-->
<!--          <td class="tcenter">{{d.courseYear}}</td>-->
<!--          <td class="tcenter">-->
<!--            <span class="small" *ngIf="d.yearType == 'YEAR_0'" translate>Year before RUI registration</span>-->
<!--            <span class="small" *ngIf="d.yearType == 'YEAR_1'" translate>First year of the period</span>-->
<!--            <span class="small" *ngIf="d.yearType == 'YEAR_2'" translate>Second year of the period</span>-->
<!--          </td>-->
<!--          <td class="tcenter"><span [format]="'minutes'" [appDuration]="d.yearDuration"></span></td>-->
<!--          <td class="tcenter"><span [format]="'minutes'" [appDuration]="d.yearDurationMandatory"></span></td>-->
<!--          <td class="tcenter"><span [format]="'minutes'" [appDuration]="d.yearDurationOptional"></span></td>-->
<!--          <td class="tcenter"><span [format]="'minutes'" [appDuration]="d.totalNeeded"></span></td>-->
<!--          <td class="tcenter"><span [format]="'minutes'" [appDuration]="d.missingDuration"></span></td>-->
<!--          <td class="tcenter"><span [yesno]="d.yearCompliant" [icon]="true"></span></td>-->
<!--          <td class="tcenter"><span [format]="'minutes'" [appDuration]="d.totalNeededIVASS"></span></td>-->
<!--          <td class="tcenter"><span [format]="'minutes'" [appDuration]="d.missingDurationIVASS"></span></td>-->
<!--          <td class="tcenter"><span [yesno]="d.ivasscompliant" [icon]="true"></span></td>-->
<!--          <td class="tcenter" *ngFor="let i of data.idd.years">-->
<!--            <span *ngIf="d.courseYear === i.year" [yesno]="i.iddOk" [icon]="true"></span>-->
<!--          </td>-->
<!--        </tr>-->
<!--        </tbody>-->
<!--      </table>-->
<!--    </div>-->
  </div>
</div>
