import {NgModule} from "@angular/core";
import {ComponentHostDirective} from "./directives";
import {SpinnerDirective} from "./spinner.directive";
import {DurationDirective} from "./directives/duration.directive";
import {StatusDirective} from "./status.directive";
import {ImgFallbackDirective} from "./img-fallback.directive";
import {UserAuthsDirective} from "./user-auths.directive";
import {SafeHtmlPipe} from "./_pipes/safeHtml";
import {CustomMessageComponent} from "./custom-message/custom-message.component";
import {CommonModule} from "@angular/common";
import {UserAuthsExcludeDirective} from "./user-auths-exclude.directive";
import {SepModalService} from "./_services/modal.service";
import {GenericModalComponent} from "./generic-modal/generic-modal.component";
import {LocalTimePipe} from "./_pipes/local-time";
import {YesNoDirective} from "./directives/yesno";
import {EncodeURIPipe} from "./_pipes/encode-uri";
import {AlertMessageComponent} from "./alert-message/alert-message.component";
import {LinkifyPipe} from '@shared/app/common/_pipes/Linkify';
import {IsLinkPipe} from '@shared/app/common/_pipes/IsLink';
import {TrimPipe} from '@shared/app/common/_pipes/Trim';
import {SubstringPipe} from '@shared/app/common/_pipes/Substring';

@NgModule({
  declarations: [
    ComponentHostDirective,
    SpinnerDirective,
    DurationDirective,
    StatusDirective,
    ImgFallbackDirective,
    UserAuthsDirective,
    SafeHtmlPipe,
    CustomMessageComponent,
    UserAuthsExcludeDirective,
    GenericModalComponent,
    LocalTimePipe,
    YesNoDirective,
    EncodeURIPipe,
    AlertMessageComponent,
    LinkifyPipe,
    IsLinkPipe,
    TrimPipe,
    SubstringPipe
  ],
  imports: [CommonModule],
  exports: [
    CommonModule,
    ComponentHostDirective,
    SpinnerDirective,
    DurationDirective,
    StatusDirective,
    ImgFallbackDirective,
    UserAuthsDirective,
    SafeHtmlPipe,
    CustomMessageComponent,
    UserAuthsExcludeDirective,
    GenericModalComponent,
    LocalTimePipe,
    YesNoDirective,
    EncodeURIPipe,
    AlertMessageComponent,
    LinkifyPipe,
    IsLinkPipe,
    TrimPipe,
    SubstringPipe
  ],
  providers: [SepModalService]
})
export class AppCommonModule {

}
