import {ICustomThemeConfig} from '../model';
import {Action} from 'redux';
import {ThemeAction, ThemeActions} from './actions';

export interface ThemeState {
  load_started: boolean;
  load_failed: boolean;
  load_succeeded: boolean;
  config: ICustomThemeConfig;
  error: any;
}

const INITIAL_STATE: ThemeState = {
  load_started: false,
  load_failed: false,
  load_succeeded: false,
  config: undefined,
  error: undefined
};


export const themeReducer = () => (state: ThemeState = INITIAL_STATE, action: Action): ThemeState => {

  switch (action.type) {
    case ThemeActions.LOAD_CUSTOM_THEME_STARTED:
      return {
        ...state,
        load_started: true
      };
    case ThemeActions.LOAD_CUSTOM_THEME_FAILED:
      return {
        ...state,
        load_started: false,
        load_failed: true,
        load_succeeded: false,
      };
    case ThemeActions.LOAD_CUSTOM_THEME_SUCCEEDED:
      return {
        ...state,
        load_started: false,
        load_failed: false,
        load_succeeded: true,
        config: {...(action as ThemeAction<ICustomThemeConfig>).payload},
      };
  }
  return state;
};
