import {Injectable} from '@angular/core';
import {LessonsAction, LmsLessonsActions} from './actions';
import {combineEpics, ofType} from 'redux-observable';
import {catchError, mergeMap, startWith, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {SynchronousService} from '../synchronous.service';

@Injectable()
export class LessonsEpics {
  constructor(
    private actions: LmsLessonsActions,
    private service: SynchronousService) {

  }

  create = () => combineEpics(
    this.createLoadLessonListEpic()
  );

  private listLoaded = (lessons) => of(this.actions.loaded(lessons));
  private loadFailed = (err) => of(this.actions.loadFailed(err));

  private loadLessonsList = () =>
    this.service.getAllSessionsSessionsLessonsUser().pipe(
      mergeMap(this.listLoaded),
      catchError(this.loadFailed),
      startWith(this.actions.loadStarted())
    );

  private createLoadLessonListEpic = () =>
    (action$, state$) =>
      action$.pipe(
        ofType(LmsLessonsActions.LESSONS_LOAD),
        switchMap(this.loadLessonsList),
      );

}
