import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {Injectable} from "@angular/core";
import {LocalStorageService} from "../../storage/local/service";
import {Router} from "@angular/router";
import {catchError, map, startWith} from "rxjs/operators";
import {AppState, resetAppStateAction} from "../../store/reducers";
import {NgRedux} from "@angular-redux/store";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  private urlPattern = /(\/api\/|\/sep\/|\/hierarchy\/)/i;
  private excludedPattern = /(\/api\/v(\d+)\/login|\/api\/v(\d+)\/logout|\/sep\/resources\/customTheme\/\w+)/i;
  private impersonatePattern = /(\/impersonate\/)/i;

  constructor(
    private localStorage: LocalStorageService,
    private router: Router,
    private store: NgRedux<AppState>) {
  }

  private httpErrorHandler = (error: HttpErrorResponse) => {
    switch (error.status) {
      case 401:
      case 403:
        return throwError(error).pipe(
          map(() => this.store.dispatch(resetAppStateAction)),
          startWith(this.router.navigate(["/login"]))
        );
      default:
        return throwError(error);
    }
  };

  private cloneRequest = (req: HttpRequest<any>) => {
    const currentToken =  (this.impersonatePattern.test(req.url) && req.body && req.body.origin === 'admin')
      ? JSON.parse(this.localStorage.rawGet('sep.admin.currentToken'))
      : JSON.parse(this.localStorage.get(LocalStorageService.OAuthTokenKeyName));

    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${currentToken?.access_token}`
      }
    })
  } ;

  intercept = (req: HttpRequest<any>, next: HttpHandler): Observable<any> => next.handle(
    [(this.urlPattern.test(req.url) || this.impersonatePattern.test(req.url)) && !this.excludedPattern.test(req.url)]
      .filter((match) => match === true)
      .map(() => this.cloneRequest(req))
      .shift() || req)
    .pipe(catchError(this.httpErrorHandler));

}
