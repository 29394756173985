<div class="login-page">
  <div class="login-form-container col-md-6 col-lg-4 offset-md-3 offset-lg-4">
    <h2 translate class="text-left">Login</h2><br>
    <div class="login-container" [@loginForm]="loginFormState">
      <form name="form" (ngSubmit)="login()" #loginForm="ngForm">
        <div *ngIf="(themeConfig | async).hideLoginForm !== true">
          <div class="form-group">
            <label for="username" translate>Username</label>
            <input type="text" id="username" class="form-control" name="username" [(ngModel)]="credentials.username"
                   #username="ngModel" required/>
            <div *ngIf="loginForm.submitted && !username.valid" class="alert alert-danger" translate>Username is
              required
            </div>
          </div>

          <div class="form-group">
            <label for="password" translate>Password</label>
            <input type="password" id="password" class="form-control" name="password" [(ngModel)]="credentials.password"
                   #password="ngModel" required/>
            <div *ngIf="loginForm.submitted && !password.valid" class="alert alert-danger" translate>Password is
              required
            </div>
          </div>
          <div *ngIf="loginError | async as error" class="alert alert-danger">
            {{ "Errore autenticazione: " + error.error_description }}
          </div>
          <div *ngIf="casProviders | async as providers">
            <div *ngIf="providers.length > 0" class="form-group">
              <label for="cas_provider" translate>Cas Provider</label>
              <select name="cas_provider" id="cas_provider" #provider class="form-control"
                      [(ngModel)]="selectedCasProvider">
                <option [value]="undefined" [selected]="true" hidden disabled translate>Select CAS provider</option>
                <option *ngFor="let provider of providers" [ngValue]="provider">{{ provider.name }}</option>
              </select>
            </div>
          </div>
          <div *ngIf="showLangSelection">
            <div class="form-group">
              <label for="languageSelection" translate>Language</label>
              <select name="languageSelection" id="languageSelection" #languageOption class="form-control"
                      [(ngModel)]="selectedLanguageCode">
                <option *ngFor="let language of languages"
                        [selected]="language.code === selectedLanguageCode"
                        [ngValue]="language.code">{{ language.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group text-left"  *ngIf="(themeConfig | async).hideLoginForm !== true">
          <button [disabled]="(loginStarted | async)===true || (!(loginForm?.form?.valid) && !selectedCasProvider)"
                  class="btn btn-primary" translate>Login
          </button>
          <img *ngIf="loading"
               src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
          &nbsp;<h6 class="d-inline-block"><a class="link-forgot-pass d-inline-block" (click)="toggleRecoverPsw()"
                                              translate>Forgot password?</a></h6>
        </div>
        <div *ngIf="(themeConfig | async).showGoogleButton && (themeConfig | async).hideLoginForm != true"
             class="form-group text-center">
          <div class="row">
            <div class="col-5">
              <hr>
            </div>
            <div class="col-2">
              or
            </div>
            <div class="col-5">
              <hr>
            </div>
          </div>
        </div>
        <div *ngIf="(themeConfig | async).showGoogleButton" class="form-group text-center">
          <a class="btn btn-light" [href]="(googleLinkUrl() | async)"><img width="20" src="assets/img/logo-g-t.png">&nbsp;<span
            translate>Login with Google</span></a><br>
        </div>
      </form>
    </div>

    <div class="recover-container" [@forgotPsw]="forgotPswState">
      <form name="formRecover" (ngSubmit)="fr.form.valid && recover()" #fr="ngForm">
        <label for="email" translate>Email</label>
        <input email type="email" id="email" class="form-control" name="email" [(ngModel)]="recoverPasswordEmail"
               #email="ngModel"
               required autocomplete="off"/>
        <p class="help-block" translate>Enter your email address to roset your password</p>
        <div class="form-group">
          <button
            [disabled]="(recoverButtonDisabled() | async) || (fr.controls.email !== undefined && !fr.controls.email.valid)"
            class="btn btn-primary" translate>Send
          </button>
          <img *ngIf="loadingR"
               src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
          <a class="btn btn-default" (click)="toggleRecoverPsw()" translate>Cancel</a>
        </div>
        <div *ngIf="passwordRecoverFailed() | async" class="alert alert-danger">
          <span translate>There was an error in the password reset</span>
        </div>
        <div *ngIf="passwordRecovered() | async" class="alert alert-success">
          <span translate>If there is an account associated to the entered address, you will receive an email including the instructions on how to set a new password.</span>
        </div>
      </form>
    </div>
  </div>

</div>
