import { ViewContainerRef, Directive, HostBinding } from '@angular/core';


@Directive({
  selector: '[cmp-host]'
})
export class ComponentHostDirective {

  @HostBinding('attr.isProtectedPage') isProtectedPage: boolean;

  constructor(public viewContainerRef: ViewContainerRef) { }
}

