import {ScormError} from '../../model';
import {ScormCourseAction, ScormCourseActions, ScormCoursePayload} from './actions';
import {ScormCourse} from '../model';

export interface ScormCourseState {
  loading: boolean,
  current: ScormCourse,
  error: ScormError,
}

const INITIAL_STATE: ScormCourseState = ({
  current: undefined,
  loading: false,
  error: undefined,
});

export const scormCourseReducer = () => (state: ScormCourseState = INITIAL_STATE, action: ScormCourseAction<ScormCoursePayload>): ScormCourseState => {
  switch (action.type) {
    case ScormCourseActions.LOAD_SCORM_STARTED:
      return {
        ...state,
        loading: true
      };
    case ScormCourseActions.LOAD_SCORM_SUCCEEDED:
      return {
        ...state,
        loading: false,
        current: {...(action as ScormCourseAction<ScormCourse>).payload},
        error: undefined
      };
    case ScormCourseActions.LOAD_SCORM_FAILED:
      return {
        ...state,
        loading: false,
        error: {...(action as ScormCourseAction<ScormError>).payload},
        current: undefined
      };

    case ScormCourseActions.SCORM_COURSE_CLOSED:
      return {
        ...state,
        current: undefined
      }
  }
  return state;
};
