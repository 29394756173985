import {Injectable} from '@angular/core';
import {dispatch} from '@angular-redux/store';
import {PasswordFailError, UserError, UserPasswordChangeRequest, UserPreferences, UserState} from '../model';
import {FluxStandardAction} from 'flux-standard-action';


export type UserPayload = UserState | UserError | UserPasswordChangeRequest | PasswordFailError | any;
export type UserAction<T extends UserPayload = undefined> = FluxStandardAction<string, T, any>;

@Injectable()
export class UserActions {
  static readonly USER_CONTEXT_LOAD = 'USER_CONTEXT_LOAD';
  static readonly USER_CONTEXT_LOAD_STARTED = 'USER_CONTEXT_LOAD_STARTED';
  static readonly USER_CONTEXT_LOAD_SUCCEEDED = 'USER_CONTEXT_LOAD_SUCCEEDED';
  static readonly USER_CONTEXT_LOAD_FAILED = 'USER_CONTEXT_LOAD_FAILED';
  static readonly USER_HIERARCHY_SWITCH = 'USER_HIERARCHY_SWITCH';
  static readonly USER_LOAD_PROFILE_IMAGE = 'USER_LOAD_PROFILE_IMAGE';
  static readonly USER_PROFILE_IMAGE_LOADED = 'USER_PROFILE_IMAGE_LOADED';
  static readonly USER_DELETE_PROFILE_IMAGE = 'USER_DELETE_PROFILE_IMAGE';
  static readonly USER_PROFILE_IMAGE_DELETED = 'USER_PROFILE_IMAGE_DELETED';
  static readonly USER_UPDATE_PREFERENCES = 'USER_UPDATE_PREFERENCES';
  static readonly USER_PREFERENCES_UPDATED = 'USER_PREFERENCES_UPDATED';
  static readonly USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';
  static readonly USER_PASSWORD_RESET = 'USER_PASSWORD_RESET';
  static readonly USER_PASSWORD_CHANGED_SUCCESS = 'USER_PASSWORD_CHANGED_SUCCESS';
  static readonly USER_PASSWORD_CHANGED_FAIL = 'USER_PASSWORD_CHANGED_FAIL';
  static readonly USER_ACCEPT_PRIVACY = 'USER_ACCEPT_PRIVACY';
  static readonly USER_PRIVACY_ACCEPTED = 'USER_PRIVACY_ACCEPTED';
  static readonly USER_RECOVER_PASSWORD = 'USER_RECOVER_PASSWORD';
  static readonly USER_PASSWORD_RECOVERED = 'USER_PASSWORD_RECOVERED';
  static readonly USER_PASSWORD_RECOVERING = 'USER_PASSWORD_RECOVERING';
  static readonly USER_PASSWORD_RECOVER_FAILED = 'USER_PASSWORD_RECOVER_FAILED';
  static readonly USER_STATS_LOAD = 'USER_STATS_LOAD';
  static readonly USER_STATS_LOAD_STARTED = 'USER_STATS_LOAD_STARTED';
  static readonly USER_STATS_LOAD_SUCCEEDED = 'USER_STATS_LOAD_SUCCEEDED';
  static readonly USER_STATS_LOAD_FAILED = 'USER_STATS_LOAD_FAILED';

  @dispatch()
  loadUserContext = (): UserAction => ({
    type: UserActions.USER_CONTEXT_LOAD,
    meta: {}
  });

  @dispatch()
  loadUserStats = (): UserAction => ({
    type: UserActions.USER_STATS_LOAD,
    meta: {}
  });

  @dispatch()
  loadUserProfileImage = (): UserAction => ({
    type: UserActions.USER_LOAD_PROFILE_IMAGE,
    meta: {}
  });

  @dispatch()
  deleteUserProfileImage = (): UserAction => ({
    type: UserActions.USER_DELETE_PROFILE_IMAGE,
    meta: {}
  });

  @dispatch()
  updateUserPreferences = (preferences: UserPreferences): UserAction<UserPreferences> => ({
    type: UserActions.USER_UPDATE_PREFERENCES,
    meta: {},
    payload: {...preferences}
  });

  @dispatch()
  acceptPrivacy = (): UserAction => ({
    type: UserActions.USER_ACCEPT_PRIVACY,
    meta: {},
  });

  @dispatch()
  recoverPassword = (eMailAddress: string): UserAction<string> => ({
    type: UserActions.USER_RECOVER_PASSWORD,
    meta: {},
    payload: eMailAddress
  });

  recoveringPassword = (): UserAction => ({
    type: UserActions.USER_PASSWORD_RECOVERING,
    meta: {}
  });

  passwordRecovered = (): UserAction => ({
    type: UserActions.USER_PASSWORD_RECOVERED,
    meta: {}
  });

  passwordRecoverFailed = (error): UserAction<UserError> => ({
    type: UserActions.USER_PASSWORD_RECOVER_FAILED,
    meta: {},
    payload: {...error}
  });

  privacyAccepted = (): UserAction => ({
    type: UserActions.USER_PRIVACY_ACCEPTED,
    meta: {},
  });


  userProfileImageLoaded = (imageUrl: string | ArrayBuffer): UserAction<string | ArrayBuffer> => ({
    type: UserActions.USER_PROFILE_IMAGE_LOADED,
    meta: {},
    payload: imageUrl
  });

  userProfileImageDeleted = (): UserAction => ({
    type: UserActions.USER_PROFILE_IMAGE_DELETED,
    meta: {},
  });

  userPreferencesUpdated = (preferences: UserPreferences): UserAction<UserPreferences> => ({
    type: UserActions.USER_PREFERENCES_UPDATED,
    meta: {},
    payload: {...preferences}
  });

  loadUserContextSucceeded = (userContext: UserState): UserAction<UserState> => ({
    type: UserActions.USER_CONTEXT_LOAD_SUCCEEDED,
    meta: {},
    payload: {...userContext}
  });

  loadUserContextFailed = (failure: UserError): UserAction<UserError> => ({
    type: UserActions.USER_CONTEXT_LOAD_FAILED,
    meta: {},
    payload: {...failure}
  });

  loadUserContextStarted = (): UserAction => ({
    type: UserActions.USER_CONTEXT_LOAD_STARTED,
    meta: {}
  });

  loadUserStatsSucceeded = (userContext: UserState): UserAction<UserState> => ({
    type: UserActions.USER_STATS_LOAD_SUCCEEDED,
    meta: {},
    payload: {...userContext}
  });

  loadUserStatsFailed = (failure: UserError): UserAction<UserError> => ({
    type: UserActions.USER_STATS_LOAD_FAILED,
    meta: {},
    payload: {...failure}
  });

  loadUserStatsStarted = (): UserAction => ({
    type: UserActions.USER_STATS_LOAD_STARTED,
    meta: {}
  });

  @dispatch()
  changeUserPassword = (request: UserPasswordChangeRequest): UserAction<UserPasswordChangeRequest> => ({
    type: UserActions.USER_CHANGE_PASSWORD,
    meta: {},
    payload: {...request}
  });

  @dispatch()
  changeUserPasswordReset = (): UserAction => ({
    type: UserActions.USER_PASSWORD_RESET,
    meta: {},
  });

  @dispatch()
  userPasswordChangedSuccess = (): UserAction => ({
    type: UserActions.USER_PASSWORD_CHANGED_SUCCESS,
    meta: {}
  });


  @dispatch()
  userPasswordChangedFail = (failure: PasswordFailError): UserAction<PasswordFailError> => ({
    type: UserActions.USER_PASSWORD_CHANGED_FAIL,
    meta: {},
    payload: {...failure}
  });


  @dispatch()
  switchUserHierarchy = (userContext: UserState): UserAction<UserState> => ({
    type: UserActions.USER_HIERARCHY_SWITCH,
    meta: {},
    payload: {...userContext}
  });


}
