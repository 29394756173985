import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {ThemeActions} from "./store/actions";
import {ThemeEpics} from "./store/epics";
import {ThemeService} from "./service";
import {AppConfigModule} from "../config/module";

@NgModule({
  declarations: [],
  exports: [],
  imports: [CommonModule, AppConfigModule, RouterModule],
  providers: [
    ThemeActions,
    ThemeEpics,
    ThemeService
  ],
})
export class ThemeModule {
}
