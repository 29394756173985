import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {forkJoin} from "rxjs";

@Directive({
    selector: '[statusIcon]'
})
export class StatusDirective implements OnInit {

    @Input()
    statusIcon;

    @Input()
    onlyIcon;

    labels: Object;

    constructor(
      el: ElementRef,
      private sanitizer: DomSanitizer,
      private elementRef: ElementRef,
      private translateService: TranslateService) { }

    ngOnInit() {
        const statusValue = this.statusIcon;
        this.labels = {};
        forkJoin(
          this.translateService.get('started'),
          this.translateService.get('completed'),
          this.translateService.get('not started'),
          this.translateService.get('failed')
        ).subscribe(t => {
          this.labels['incomplete'] = t[0];
          this.labels['completed'] = t[1];
          this.labels['notattempted'] = t[2];
          this.labels['failed'] = t[3];
        });

        let elemClass = 'crs-status-notattempted';
        let iconClass = 'far fa-circle-o status-default';
        let elemLabel = statusValue;
        if (statusValue != null) {
            if (statusValue.toLowerCase() === 'incomplete') {
                elemClass = 'crs-status-incomplete';
                iconClass = 'fas fa-adjust status-default';
                elemLabel = this.labels['incomplete'];
            } else if (statusValue.toLowerCase() === 'complete'  || statusValue.toLowerCase() === 'completed' 
              || statusValue.toLowerCase() === 'passed') {
                elemClass = 'crs-status-completed';
                iconClass = 'far fa-check status-success';
                elemLabel = this.labels['completed'];
            } else if (statusValue.toLowerCase() === 'failed') {
                elemClass = 'crs-status-failed';
                iconClass = 'far fa-times-circle status-danger';
                elemLabel = this.labels['failed'];
            } else if (statusValue.toLowerCase() === 'not_attempted' || statusValue.toLowerCase() === 'not attempted') {
                elemLabel = this.labels['notattempted'];
            }
        } else {
            elemLabel = this.labels['notattempted'];
        }

        if(this.onlyIcon !== undefined) {
            this.elementRef.nativeElement.innerHTML = '<span class="crs-status ' + elemClass + '">'
                + '<i class="' + iconClass + '"></i></span>';
        } else {
            this.elementRef.nativeElement.innerHTML = '<span class="crs-status ' + elemClass + '">'
                + '<i class="' + iconClass + '"></i><span class="status-label">' + elemLabel + '</span></span>';
        }
    }

}
