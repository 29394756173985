import {Injectable} from '@angular/core';
import {UserAction, UserActions} from './actions';
import {combineEpics, Epic, ofType} from 'redux-observable';
import {UserError, UserPasswordChangeRequest, UserPreferences, UserState, UserStatsHome} from '../model';
import {catchError, map, mergeMap, startWith, switchMap} from 'rxjs/operators';
import {UserService} from '../service';
import {Observable, of, throwError, zip} from 'rxjs';
import {HierarchyService} from '../hierarchy/api/service';
import {AppState} from '../../store/reducers';
import {TranslateService} from '@ngx-translate/core';
import {CustomFieldsService} from '../../config/fields/service';
import {LocalStorageService} from '../../storage/local/service';
import {I18NService} from '../../i18n/service';
import {SynchronousService} from '../../../app/lms/synchronous/synchronous.service';
import {Stats} from '@shared/app/user/stats/model';

export type UserEpicType = Epic<UserAction<UserState | UserError | any>, UserAction<UserState | UserError | any>, AppState>;

const readImage = (blob: Blob): Observable<string | ArrayBuffer> => {
  if (!(blob instanceof Blob)) {
    return throwError(new Error('`blob` must be an instance of File or Blob.'));
  }

  return new Observable(obs => {
    const reader = new FileReader();

    reader.onerror = err => obs.error(err);
    reader.onabort = err => obs.error(err);
    reader.onload = () => obs.next(reader.result);
    reader.onloadend = () => obs.complete();

    return reader.readAsDataURL(blob);
  });
};

@Injectable()
export class UserEpics {
  constructor(
    private actions: UserActions,
    private service: UserService,
    private hierarchyService: HierarchyService,
    private translateService: TranslateService,
    private localStorage: LocalStorageService,
    private i18nService: I18NService,
    private customFieldsService: CustomFieldsService,
    private synchronousService: SynchronousService
  ) {

  }

  create = () => combineEpics(
    this.createUserEpics(),
    this.createStatsEpics(),
    this.createUserLoadProfileImageEpics(),
    this.createUserUpdatePreferencesEpics(),
    this.createDeleteUserProfileImageEpics(),
    this.createUserChangePasswordEpics(),
    this.createUserAcceptPrivacyEpics(),
    this.createUserRecoverPasswordEpics(),
  )

  private transformData = (data): UserState => {
    return ({
      hierarchies: []/*hierarchies*/,
      currentHierarchyId: 0/*selectedHierarchy.id*/,
      details: {...(data[1]), imageUrl: data[0]},
      fields: {...(data[2])}
    });
  }

  private loadProfileImage = (): Observable<string | ArrayBuffer> => this.service.profileImage().pipe(
    mergeMap((image) => readImage(image)),
    catchError((err) => of('assets/img/avatar-default.png'))
  )

  private deleteProfileImage = () => this.service.deleteUserProfileImage().pipe(
    map(() => this.actions.userProfileImageDeleted())
  )

  private changePassword = (request: UserPasswordChangeRequest) =>
    this.service.changePassword(request).pipe(
      map(() => this.actions.userPasswordChangedSuccess()),
      catchError(error => of(this.actions.userPasswordChangedFail(error.error))),
    )

  private updatePreferences = (preferences: UserPreferences) =>
    this.service.updatePreferences(preferences).pipe(
      map(() => {
          this.i18nService.updateLanguage(preferences.cultureCode);
          return this.actions.userPreferencesUpdated(preferences);
        }
      )
    )


  private loadSucceeded = (data: UserState) => {
    this.i18nService.updateLanguage(data.details.preferences.cultureCode);
    return this.actions.loadUserContextSucceeded(data);
  }

  private loadFailed = (error) => of(this.actions.loadUserContextFailed({status: error}));

  private loadUserData = () => zip(
    this.loadProfileImage(),
    this.service.details(),
    this.customFieldsService.fields()
  ).pipe(
    map(this.transformData),
    map(this.loadSucceeded),
    catchError(this.loadFailed),
    startWith(this.actions.loadUserContextStarted())
  )


  private transformDataStats = (data: Array<Stats>): UserState => {
    return ({
      stats: {
        all: {
          totalCourseAvailable: data[0].countersCourse.userCourseAssociation.mandatoryTotalCourses + data[0].countersCourse.userCourseAssociation.optionalTotalCourses,
          totalCourseHourAvailable: data[0].countersCourse.userCourseAssociation.mandatoryTotalCoursesHours + data[0].countersCourse.userCourseAssociation.optionalTotalCoursesHours,
          totalCourseCompleted: data[0].countersCourse.userCourseCompleted.mandatoryTotalCourses + data[0].countersCourse.userCourseCompleted.optionalTotalCourses,
          totalHoursCourseCompleted: data[0].countersCourse.userCourseCompleted.mandatoryTotalCoursesHours + data[0].countersCourse.userCourseCompleted.optionalTotalCoursesHours,
          totalCourseCanDo: data[0].countersCourse.userCourseAssociationNotCompleted.mandatoryTotalCourses + data[0].countersCourse.userCourseAssociationNotCompleted.optionalTotalCourses,
          totalHoursCanDo: data[0].countersCourse.userCourseAssociationNotCompleted.mandatoryTotalCoursesHours + data[0].countersCourse.userCourseAssociationNotCompleted.optionalTotalCoursesHours
        },
        year: {
          totalCourseAvailable: data[1].countersCourse.userCourseAssociation.mandatoryTotalCourses + data[1].countersCourse.userCourseAssociation.optionalTotalCourses,
          totalCourseHourAvailable: data[1].countersCourse.userCourseAssociation.mandatoryTotalCoursesHours + data[1].countersCourse.userCourseAssociation.optionalTotalCoursesHours,
          totalCourseCompleted: data[1].countersCourse.userCourseCompleted.mandatoryTotalCourses + data[1].countersCourse.userCourseCompleted.optionalTotalCourses,
          totalHoursCourseCompleted: data[1].countersCourse.userCourseCompleted.mandatoryTotalCoursesHours + data[1].countersCourse.userCourseCompleted.optionalTotalCoursesHours,
          totalCourseCanDo: data[1].countersCourse.userCourseAssociationNotCompleted.mandatoryTotalCourses + data[1].countersCourse.userCourseAssociationNotCompleted.optionalTotalCourses,
          totalHoursCanDo: data[1].countersCourse.userCourseAssociationNotCompleted.mandatoryTotalCoursesHours + data[1].countersCourse.userCourseAssociationNotCompleted.optionalTotalCoursesHours
        },
      },
    });
  }


  private loadSucceededStats = (data: UserState) => {
    return this.actions.loadUserStatsSucceeded(data);
  }

  private loadFailedStats = (error) => of(this.actions.loadUserStatsFailed({status: error}));

  private loadStatsData = () => zip(
      this.service.stats(),
      this.service.stats(new Date().getFullYear())
    )
    .pipe(
      map(this.transformDataStats),
      map(this.loadSucceededStats),
      catchError(this.loadFailedStats),
      startWith(this.actions.loadUserStatsStarted())
    )

  private notAlreadyFetched = (state: AppState) => !state.user.details;

  private createUserEpics = () => (action$, state$) =>
    action$.pipe(ofType(UserActions.USER_CONTEXT_LOAD),
      // filter(() => this.notAlreadyFetched(state$.value)),
      switchMap(this.loadUserData),
    )

  private createStatsEpics = () => (action$, state$) =>
    action$.pipe(ofType(UserActions.USER_STATS_LOAD),
      switchMap(this.loadStatsData),
    )

  private createUserLoadProfileImageEpics = () => (action$, state$) =>
    action$.pipe(
      ofType(UserActions.USER_LOAD_PROFILE_IMAGE),
      mergeMap(() => this.loadProfileImage()),
      map((imageUrl: string | ArrayBuffer) => this.actions.userProfileImageLoaded(imageUrl))
    )

  private createDeleteUserProfileImageEpics = () => (action$, state$) =>
    action$.pipe(ofType(UserActions.USER_DELETE_PROFILE_IMAGE),
      mergeMap(this.deleteProfileImage),
    )

  private createUserUpdatePreferencesEpics = () => (action$, state$) =>
    action$.pipe(ofType(UserActions.USER_UPDATE_PREFERENCES),
      mergeMap(action => this.updatePreferences({...state$.value.user.details.preferences, ...(action as UserAction<UserPreferences>).payload})),
    )

  private createUserChangePasswordEpics = () => (action$, state$) =>
    action$.pipe(ofType(UserActions.USER_CHANGE_PASSWORD),
      mergeMap(action => this.changePassword({
        ...(action as UserAction<UserPasswordChangeRequest>).payload,
        username: state$.value.user.details.username
      })),
    )

  private createUserAcceptPrivacyEpics = () => (action$, state$) =>
    action$.pipe(ofType(UserActions.USER_ACCEPT_PRIVACY),
      mergeMap(action => this.service.acceptPrivacy().pipe(map(
        () => this.actions.privacyAccepted()
      )))
    )

  private createUserRecoverPasswordEpics = () => (action$, state$) =>
    action$.pipe(ofType(UserActions.USER_RECOVER_PASSWORD),
      mergeMap(action => this.service
        .recoverPassword((action as UserAction<string>).payload)
        .pipe(
          map(() => this.actions.passwordRecovered()),
          catchError(error => of(this.actions.passwordRecoverFailed(error))),
          startWith(this.actions.recoveringPassword())
        )
      )
    )
}
