import {ReportService} from './_services/report.service';
import {ReportRoutingModule} from './report-routing.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReportUserComponent} from './report-user/report-user.component';
import {TranslateModule} from '@ngx-translate/core';
import {ReportIvassComponent} from './report-ivass/report-ivass.component';
import {BrowserModule} from '@angular/platform-browser';
import {ChartsModule} from 'ng2-charts';
import {CoreModule} from "../core/module";
import {LmsModule} from "../lms/module";
import {LayoutModule} from "../layout/module";
import {AppCommonModule} from "../common/module";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {ReportModalComponent} from '@shared/app/report/report-modal/report-modal.component';
import {FormsModule} from '@angular/forms';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {CourseNamePipe} from '@shared/app/common/_pipes/courseName';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        CoreModule,
        ReportRoutingModule,
        LmsModule,
        TranslateModule,
        ChartsModule,
        TranslateModule.forChild({}),
        LayoutModule,
        AppCommonModule,
        PaginationModule.forRoot(),
        FormsModule,
        TooltipModule
    ],
  declarations: [
    ReportUserComponent,
    CourseNamePipe,
    ReportIvassComponent,
    ReportModalComponent
  ],
  providers: [
    ReportService,
    CourseNamePipe
  ],
  exports: [CourseNamePipe]
})
export class ReportModule {
}
