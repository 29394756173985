import {Injectable} from "@angular/core";
import {UrlUtils} from "../utils/urlUtils";
import {of, zip} from "rxjs";
import {ThemeService} from "../theme/service";
import {CasService} from "../authentication/cas/service";
import {LocalStorageService} from "../storage/local/service";
import {AppEnvService} from "../config/env/service";
import {UserAuth} from "@shared/app/authentication/model";

@Injectable()
export class AppBootService {

  private readonly themeName: string;

  constructor(
    private themeService: ThemeService,
    private casService: CasService,
    private localStorage: LocalStorageService,
    private environmentService: AppEnvService
  ) {

    const mappedThemes = new Map<string, any>(Object.entries(environmentService.config().theme.mappedThemes));
    const themeFromUrl = new UrlUtils().hostname(window.location.hostname, this.environmentService.config().theme.defaultTheme);
    this.themeName = mappedThemes.has(themeFromUrl) ? mappedThemes.get(themeFromUrl) : themeFromUrl;

  }

  boot = () => {
    const currToken: UserAuth = JSON.parse(this.localStorage.get(LocalStorageService.OAuthTokenKeyName));
    return zip(
      of(currToken),
      this.casService.providers(),
      this.themeService.getThemeConfig(this.themeName),
    )
  }

}
