import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CustomFieldsService} from "./fields/service";
import {AppEnvService} from "./env/service";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {DeviceDetectorModule, DeviceDetectorService} from "ngx-device-detector";
import {LocalStorageService} from "../storage/local/service";

@NgModule({
  declarations: [],
  exports: [],
  imports: [CommonModule, TooltipModule, DeviceDetectorModule],
  providers: [AppEnvService, CustomFieldsService, DeviceDetectorService, LocalStorageService],
})
export class AppConfigModule {
}
