import {Component, OnInit} from '@angular/core';
import {CourseReport} from '@shared/app/report/_models/course-report';
import * as fileSaver from 'file-saver';
import {ResourceService} from '@shared/app/lms/resources/resource.service';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styles: ['.modal-body {padding:0!important;position: initial;}', 'iframe {width:100%;height: 100%;border:0!important; position: absolute;}']
})

export class ReportModalComponent implements OnInit {

  course: CourseReport;
  certificateSrc: String;

  constructor(private _bsModalRef: BsModalRef,
              private resourceService: ResourceService) {
  }

  ngOnInit() {

  }

  downloadCertificate() {
    this.resourceService.downloadCertificate(this.course.id_course).subscribe((response) => {
        const blob = new Blob([response], {type: 'application/pdf;'});
        fileSaver.saveAs(blob, 'certificate.pdf', false);
      }
    );
  }

  closeCertificate() {
    this._bsModalRef.hide();
  }

}
