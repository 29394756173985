import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {catchError, mergeMap, startWith, switchMap} from 'rxjs/operators';
import {ScormCourseAction, ScormCourseActions} from './actions';
import {combineEpics, ofType} from 'redux-observable';
import {ScormCourseService} from './service';
import {ScormModule} from '../../module/model';
import {ScormUnit} from '../../unit/model';

@Injectable()
export class ScormCourseEpics {

  constructor(
    private service: ScormCourseService,
    private actions: ScormCourseActions,
  ) {

  }

  create = () => combineEpics(
    this.createLoadLmsScormEpic(),
    this.createCloseScormCourseEpic()
  )


  private loadScorm = (resourceId: number) => {

    const modulesRefact = (modules: ScormModule[]): ScormModule[] => {
      return modules.map((module: ScormModule) => {
        module.statistics = {
          percentCompleted: module.units.filter(unit => (unit.lessonStatus === 'COMPLETED' || unit.lessonStatus === 'PASSED')).length * 100 / module.units.length,
          unitCompleted: module.units.filter(unit => (unit.lessonStatus === 'COMPLETED' || unit.lessonStatus === 'PASSED')).length,
          unitTotal: module.units.length
        };
        return module;
      });
    };

    const unitsFlattener = (modules: ScormModule[]): ScormUnit[] =>
      modules
        .flatMap((mod, index) => mod.units)
        .sort((a, b) => (a.order - b.order) || (a.id - b.id));

    return this.service.loadResource(resourceId).pipe(
      switchMap(res => of(this.actions.loadSucceeded({
        locked: res.locked,
        description: res.metadata.description,
        allowView: true,
        modules: modulesRefact(res.metadata.modules),
        title: res.metadata.title,
        catalogId: res.catalogId,
        class: undefined,
        courseId: res.courseId,
        cover: res.metadata.cover,
        duration: res.duration,
        hasMessage: false,
        id: res.metadata.id,
        pathId: res.pathId,
        resourceId: res.id,
        state: res.state,
        flatUnits: unitsFlattener(res.metadata.modules),
        imageUrl: res.imageUrl
      }))),
      catchError(err => of(this.actions.loadFailed(err))),
      startWith(this.actions.loadStarted())
    );
  };

  private createLoadLmsScormEpic = () =>
    (action$, state$) => action$
      .pipe(
        ofType(ScormCourseActions.LOAD_SCORM_COURSE),
        switchMap((action: ScormCourseAction<number>) => this.loadScorm(action.payload))
      );

  private createCloseScormCourseEpic= () =>
    (action$, state$) => action$
      .pipe(
        ofType(ScormCourseActions.CLOSE_SCORM_COURSE),
        switchMap(() => of(this.actions.scormClosed()))
      );
}
