<div *ngIf="(bootState | async).bootCompleted" class="site-container">
    <div class="header-container table-row-container">
        <div class="table-cell-container">
            <app-header [helpOnLine]="true === showHelpOnLine()" (onLineHelpClick)="openOnlineHelp()"></app-header>
        </div>
    </div>

    <div class="view-container table-row-container">
        <div class="table-cell-container">
            <section class="content">
                <router-outlet></router-outlet>
            </section>
        </div>
    </div>

    <div class="table-row-container">
        <app-footer></app-footer>
    </div>

    <simple-notifications></simple-notifications>
</div>
