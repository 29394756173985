import {Injectable} from "@angular/core";
import {LocalStorageService} from "../storage/local/service";
import {AppEnvService} from "../config/env/service";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class I18NService {
  constructor(
    private localStorage: LocalStorageService,
    private translations: TranslateService,
    private environment: AppEnvService
  ) {
  }

  initialize = () => {
    const defaultLanguageCode = this.environment.config().i18n.defaultLanguageCode;
    const defaultLanguage = defaultLanguageCode.split('-')[0];
    const storedLanguage = this.localStorage.get('lang');
    this.translations.setDefaultLang(defaultLanguage);
    this.translations.use(storedLanguage || defaultLanguage);
    this.localStorage.set('lang', defaultLanguage);
    this.localStorage.set('cultureCode', defaultLanguageCode);
  };

  updateLanguage = (cultureCode: string) => {
    const userCulture = (cultureCode || this.environment.userLanguage)
    const language = userCulture.split('-')[0];
    this.translations.setDefaultLang(language);
    this.translations.use(language);
    this.localStorage.set('lang', language);
    this.localStorage.set('cultureCode', userCulture);
  }


}
